import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input, OnInit } from '@angular/core';
import { KioskDto } from '../../../dto';
import { HttpKioskService } from '../../../http-kiosk.service';
import { AppToastService } from 'src/app/components/toasts/app-toast.service';
import { catchError } from 'rxjs/operators';

@Component({
	selector: 'app-verify-kiosk-modal',
	templateUrl: './verify-kiosk-modal.component.html',
	styleUrls: ['./verify-kiosk-modal.component.scss']
})
export class VerifyKioskModalComponent {
	@Input() kiosk: KioskDto;
	kioskCode: number;

	constructor(public modal: NgbActiveModal, private toastService: AppToastService, private httpService: HttpKioskService) { }

	verify() {

		if (this.kioskCode === undefined) {
			this.toastService.showWarning({ content: 'Please enter the 5 digit code as displayed on the Kiosk screen' });
			return;
		}

		this.httpService.verifyKiosk(this.kiosk.deviceRef, this.kioskCode)
		.pipe(
			catchError(error => {
				console.log('Error verifying kiosk', error);
				this.toastService.showDanger({ content: 'Failed to verify kiosk: ' + (error.error ? error.error.error : error.message) });
				throw error;
			})
		)
		.subscribe(data => {
			if (!data.responseStatus) {
				this.toastService.showDanger({ content: 'Failed to verify kiosk: ' + data.responseStatusMessage });
				return;
			}

			this.toastService.showSuccess({ content: 'Kiosk verified successfully'});
			this.modal.close(true);
		});
	}

	cancel() {
		this.modal.close(false);
	}
}