import { Component, Input } from "@angular/core";
import { AppToastService } from "src/app/components/toasts/app-toast.service";
import { catchError } from "rxjs/operators";
import { faArrowLeft, faCheck, faCancel, faSimCard, faBarcode, faPrint, faCoins, faMoneyBill1Wave, faListCheck, faComputer, faCashRegister } from '@fortawesome/free-solid-svg-icons';
import { HttpComponentsService } from "src/app/modules/kiosk/http-components.service";
import { NgxSpinnerService } from "ngx-spinner";
import { KioskComponentInitDto, KioskDto } from "../../dto";

export class StatusDesc {
	class: string;
	text: string;

	constructor(_class: string, _text: string) {
		this.class = _class;
		this.text = _text;
	}
}

@Component({
	selector: 'app-kiosk-component-init',
	templateUrl: './kiosk-component-init.component.html',
	styleUrls: ['./kiosk-component-init.component.scss']
})
export class KioskComponentInitComponent {
	kiosk: KioskDto;
	componentInit: KioskComponentInitDto;

	dispenser=faSimCard;
	barcode=faBarcode;
	printer=faPrint;
	coinValidator=faListCheck;
	hopperCoins=faCoins;
	hopperNotes=faMoneyBill1Wave;
	kioskIcon=faCashRegister;

	ok=faCheck;
	notOk=faCancel;

	readonly initStatusCount = 7;

	constructor(
		private httpService: HttpComponentsService,
		private toastService: AppToastService,
		private spinner: NgxSpinnerService,) {
	}

	@Input() 
	set kioskData(kiosk: KioskDto) {
		if (!kiosk) {
			return;
		}
		this.kiosk = kiosk;
		this.fetchKioskComponentInit();
	}

	private fetchKioskComponentInit() {
		this.spinner.show();
		this.httpService.getKioskComponentInit(this.kiosk.id)
		.pipe(
			catchError(error => {
				this.spinner.hide();
				console.log('Error fetching data', error);
				this.toastService.showDanger({ content: 'Failed to fetch data: ' + (error.error ? error.error.error : error.message) });
				throw error;
			})
		)
		.subscribe(item => {
			this.spinner.hide();
			this.componentInit = item;
		});
	}

	getIcon(init: boolean) {
		return init === true ? this.ok : this.notOk;
	}

	getIconClass(init: boolean) {
		return init === true ? 'text-success' : 'text-danger';
	}

	getStatusDescription(): StatusDesc {
		var working = this.getCountWorking();
		if (working === this.initStatusCount) {
			return new StatusDesc('fs-5 text-success fw-bold', 'All systems operational');
		}

		if (working === 0) {
			return new StatusDesc('fs-5 text-danger fw-bold', 'Kiosk and all peripherals offline');
		}

		if (!this.componentInit.kioskStatus) {
			return new StatusDesc('fs-5 text-danger fw-bold', 'Kiosk is offline');
		}

		return new StatusDesc('fs-5 text-warning fw-bold', 'Kiosk is online with ' + (this.initStatusCount - working) + ' offline peripherals');
	}
	

	getCountWorking() {
		var count = 0;

		this.componentInit.dispenser ? count++ : count;
		this.componentInit.barcode ? count++ : count;
		this.componentInit.printer ? count++ : count;
		this.componentInit.coinValidator ? count++ : count;
		this.componentInit.hopperCoins ? count++ : count;
		this.componentInit.hopperNotes ? count++ : count;
		this.componentInit.kioskStatus ? count++ : count;

		return count;
	}
}