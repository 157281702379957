import { Component, Input, OnInit } from "@angular/core";
import { KioskVideoDto } from "../../../dto";
import { HttpVideoService } from "../../../http-video.service";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { catchError } from "rxjs/operators";
import { NgxSpinnerService } from "ngx-spinner";
import { AppToastService } from "src/app/components/toasts/app-toast.service";

@Component({
	selector: 'app-video-player',
	templateUrl: './video-player.component.html',
	styleUrls: ['./video-player.component.scss']
})
export class VideoPlayerComponent {

	video: KioskVideoDto;
	videoUrl: SafeUrl;	

	constructor(
		private httpService: HttpVideoService, 
		public modal: NgbActiveModal, 
		private toastService: AppToastService,
		private spinner: NgxSpinnerService,
		private sanitizer: DomSanitizer) {}

	@Input()
	set videoData(video: KioskVideoDto) {
		this.video = video;
		this.spinner.show();
		this.httpService.getVideo(this.video.id)
		.pipe(
			catchError(error => {
				this.spinner.hide();
				console.log('Error fetching video', error);
				this.toastService.showDanger({ content: 'Failed to fetch video' });
				throw error;
			})
		)
		.subscribe(data => {
			this.videoUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(data));
			this.spinner.hide();
		});
	}
	cancel() {
		this.modal.close(false);
	}
}