import { Component, OnInit } from "@angular/core";
import { catchError } from "rxjs/operators";
import { HttpSettingsService } from "../../http-settings.service";
import { NgxSpinnerService } from "ngx-spinner";
import { AppToastService } from "src/app/components/toasts/app-toast.service";
import { KioskMonitorGroupedDto,KioskMonitorBasicDto,  KioskMonitorSettingDto, KioskSettingDto } from "../../dto";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ConfirmModalComponent } from "../../components/confirm-modal/confirm-modal.component";
import { forkJoin } from "rxjs";
import { faCancel, faCheck, faCircleQuestion, faGear, faPlusCircle, faQuestion, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { Location } from "@angular/common";

@Component({
	selector: 'app-settings-list',
	templateUrl: './settings-list.component.html',
	styleUrls: ['./settings-list.component.scss']
})
export class SettingsListComponent implements OnInit {

	settings: KioskSettingDto[];
	edit: KioskSettingDto[];

	monitorSettings: KioskMonitorSettingDto[];
	monitorSettingsEdit: KioskMonitorSettingDto[];

	settingsIcon = faGear;
	tooltip = faQuestionCircle;
	enabled = faCheck;
	disabled = faCancel;

	constructor(
		private locationService: Location, 
		private httpService: HttpSettingsService,
		private toastService: AppToastService,
		private modalService: NgbModal,
		private spinner: NgxSpinnerService
		) {}

	ngOnInit(): void {
		this.loadSettings();
	}

	goBack(): void {
		this.locationService.back()
	}


	private loadSettings() {
		this.spinner.show();
		forkJoin(
			[
				this.httpService.getAll(), 
				this.httpService.getAllMonitorSettings(),
			]
		)
		.pipe(
			catchError(error => {
				this.spinner.hide();
				console.log('Error fetching settings', error);
				this.toastService.showDanger({ content: 'Failed to fetch data: ' + (error.error ? error.error.error : error.message) });
				this.spinner.hide();
				throw error;
			})
		)
		.subscribe(items => {
			this.settings = items[0];
			this.edit = [];
			this.settings.forEach(item => {
				this.edit.push({
					id: item.id,
					updatedAt: item.updatedAt,
					createdAt: item.createdAt,
					description: item.description,
					name: item.name,
					value: item.value,
					inputType: isNaN(+item.value) ? 'text' : 'number'
				})
			});

			this.monitorSettings = items[1];
			this.monitorSettingsEdit = [];
			this.monitorSettings.forEach(item => {
				this.monitorSettingsEdit.push({
					id: item.id,
					monitor: item.monitor,
					severeThreshold: item.severeThreshold,
					severeThresholdDesc: item.severeThresholdDesc,
					problemThreshold: item.problemThreshold,
					problemThresholdDesc: item.problemThresholdDesc,
					severeRestore: item.severeRestore,
					severeRestoreDesc: item.severeRestoreDesc,
					problemRestore: item.problemRestore,
					problemRestoreDesc: item.problemRestoreDesc,
					escalation: item.escalation,
					escalationDesc: item.escalationDesc
				})
			});

			this.spinner.hide();
		});
	}

	getSettingValue(edit: KioskSettingDto): KioskSettingDto {
		return this.settings.filter(item => item.id === edit.id)[0];
	}

	get disableSave() {
		if (!this.edit) {
			return true;
		}
		if ((this.edit.filter(item => !item.value)).length > 0) {
			return true;
		}

		return this.getEditedSettings().length === 0;
	}

	private getEditedSettings() {
		return this.edit
		.map(edited => {
			var currentSetting = this.settings.filter(current => current.id === edited.id)[0];
			if (currentSetting.value !== edited.value) {
				return edited;
			}
		})
		.filter(item => item);
	}

	save() {
		var editedSettings = this.getEditedSettings();
		if (editedSettings.length === 0) {
			return;
		}
		const modalRef = this.modalService.open(ConfirmModalComponent, { windowClass: "modal-confirm" });
		modalRef.componentInstance.title = 'Edit Settings';
		modalRef.componentInstance.text = "Please confirm you wish to edit the settings.";
		modalRef.componentInstance.additionalText = "Note: any changes made will impact the operation of the system. In addition, the actions you make will be recorded.";

		modalRef.result.then((result) => {
			if (result === true) {
				this.doSave(editedSettings);
			}
		});
		
	}

	private doSave(settings: KioskSettingDto[]) {
		this.httpService.save(settings)
		.pipe(
			catchError(error => {
				this.spinner.hide();
				console.log('Error saving settings', error);
				this.toastService.showDanger({ content: 'Failed to save settings: ' + (error.error ? error.error.error : error.message) });
				throw error;
			})
		)
		.subscribe(data => {
			this.spinner.hide();
			if (!data.responseStatus) {
				this.toastService.showDanger({ content: 'Failed to edit setting(s): ' + data.responseStatusMessage });
				return;
			}

			this.toastService.showSuccess({ content: 'Setting(s) saved successfully'});

			this.loadSettings();
		});
	}

	get disableSaveMonitor() {
		if (!this.monitorSettingsEdit) {
			return true;
		}

		var disableSave = false;
		for (let ms of this.monitorSettingsEdit) {
			ms.error = null;
			if (!ms.severeThreshold || ms.severeThreshold < 0 || ms.severeThreshold > 10 ) {
				ms.error = 'Severe Threshold must be between 0 and 10'
				disableSave = true;
			} else if (!ms.problemThreshold || ms.problemThreshold < 0 || ms.problemThreshold > 10 ) {
				ms.error = 'Problem Threshold must be between 0 and 10'
				disableSave = true;
			} else if (!ms.severeRestore || ms.severeRestore < 0 || ms.severeRestore > 10 ) {
				ms.error = 'Severe Restore must be between 0 and 10'
				disableSave = true;
			} else if (!ms.problemRestore || ms.problemRestore < 0 || ms.problemRestore > 10 ) {
				ms.error = 'Problem Restore must be between 0 and 10'
				disableSave = true;
			} else if (!ms.escalation || ms.escalation < 0 || ms.escalation > 1000 ) {
				ms.error = 'Escalation must be between 0 and 1000 minutes'
				disableSave = true;
			}
		}

		if (disableSave){
			return disableSave;
		}

		return this.getEditedMonitorSettings().length === 0;
	}

	private getEditedMonitorSettings() {
		return this.monitorSettingsEdit
		.map(edited => {
			var currentSetting = this.monitorSettings.filter(current => current.id === edited.id)[0];
			if (currentSetting.severeThreshold !== edited.severeThreshold) {
				return edited;
			} else if (currentSetting.severeThreshold !== edited.severeThreshold) {
				return edited;
			} else if (currentSetting.severeRestore !== edited.severeRestore) {
				return edited;
			} else if (currentSetting.problemRestore !== edited.problemRestore) {
				return edited;
			} else if (currentSetting.escalation !== edited.escalation) {
				return edited;
			}
		})
		.filter(item => item);
	}

	saveMonitor() {
		var editedSettings = this.getEditedMonitorSettings();
		if (editedSettings.length === 0) {
			return;
		}
		const modalRef = this.modalService.open(ConfirmModalComponent, { windowClass: "modal-confirm" });
		modalRef.componentInstance.title = 'Edit Monitor Settings';
		modalRef.componentInstance.text = "Please confirm you wish to edit the kiosk monitor settings.";
		modalRef.componentInstance.additionalText = "Note: any changes made will impact the operation of the system. In addition, the actions you make will be recorded.";

		modalRef.result.then((result) => {
			if (result === true) {
				this.doSaveMonitor(editedSettings);
			}
		});
	}

	private doSaveMonitor(settings: KioskMonitorSettingDto[]) {
		this.httpService.saveMonitor(settings)
		.pipe(
			catchError(error => {
				this.spinner.hide();
				console.log('Error saving settings', error);
				this.toastService.showDanger({ content: 'Failed to save settings: ' + (error.error ? error.error.error : error.message) });
				throw error;
			})
		)
		.subscribe(data => {
			this.spinner.hide();
			if (!data.responseStatus) {
				this.toastService.showDanger({ content: 'Failed to edit setting(s): ' + data.responseStatusMessage });
				return;
			}

			this.toastService.showSuccess({ content: 'Setting(s) saved successfully'});

			this.loadSettings();
		});
	}
}