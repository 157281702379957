import { Component, Input } from "@angular/core";
import { CustomerDto } from "../../../dto";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
	selector: 'app-customer-id-modal',
	templateUrl: './customer-id-modal.component.html',
	styleUrls: ['./customer-id-modal.component.scss']
})
export class CustomerIdCardModalComponent {
	@Input() customer: CustomerDto;

	constructor(
		public modal: NgbActiveModal,
	) {}

	close() {
		this.modal.close(false);
	}
}