<div class="card m-3 shadow-lg bg-white rounded">
	<div class="card-header p-3">
		<fa-icon [icon]="dispenser" class="text-secondary"></fa-icon>&nbsp;Card Dispenser Problems
	</div>
	<div class="card-body">
		<div class="row">
			<div class="col table-responsive">
				<div *ngIf="!kioskCardDispenserStatus">No card dispenser problems</div>
				<table *ngIf="kioskCardDispenserStatus" class="table table-hover ">
					<thead>
						<tr>
							<th scope="col">ID</th>
							<th scope="col">Device Ref</th>
							<th scope="col">Name</th>
							<th scope="col">Location</th>
							<th scope="col">Severity</th>
							<th scope="col">Dispenser Update</th>
							<th scope="col">Sensor 1</th>
							<th scope="col">Sensor 2</th>
							<th scope="col">Sensor 3</th>
							<th scope="col">Card Bin Full</th>
							<th scope="col">Card Box Status</th>
							<th scope="col">Card Error</th>
							<th scope="col">Process Error</th>
							<th scope="col">Stuck Position</th>
							<th scope="col">Barcode Update</th>
							<th scope="col">Barcode Status</th>
							<th>&nbsp;</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let cardStatus of kioskCardDispenserStatus" (click)="viewKiosk(cardStatus.kiosk)">
							<td>{{cardStatus.kiosk.id}}</td>
							<td>{{cardStatus.kiosk.deviceRef}}</td>
							<td>{{cardStatus.kiosk.name}}</td>
							<td>{{util.getLocation(cardStatus.kiosk)}}</td>
							<td [ngClass]="{'text-danger':cardStatus.status === 'Severe'}">
								{{cardStatus.status}}
							</td>
							<td>{{cardStatus.dispenserLastUpdated | date:'yyyy-MM-dd HH:mm:ss'}}</td>
							<td><ng-container [ngTemplateOutlet]="sensorStatus" 
                					[ngTemplateOutletContext]="{sensorStatus:cardStatus.sensor1}"></ng-container></td>
							<td><ng-container [ngTemplateOutlet]="sensorStatus" 
                					[ngTemplateOutletContext]="{sensorStatus:cardStatus.sensor2}"></ng-container></td>
							<td><ng-container [ngTemplateOutlet]="sensorStatus" 
								[ngTemplateOutletContext]="{sensorStatus:cardStatus.sensor3}"></ng-container></td>
							<td><ng-container [ngTemplateOutlet]="standardStatus" 
								[ngTemplateOutletContext]="{standardStatus:cardStatus.cardBinFull}"></ng-container></td>
							<td>{{cardStatus.cardBoxStatus}}</td>
							<td>{{cardStatus.cardError}}</td>
							<td>{{cardStatus.processError}}</td>
							<td>{{cardStatus.stuckPosition}}</td>
							<td>{{cardStatus.barcodeLastUpdated | date:'yyyy-MM-dd HH:mm:ss'}}</td>
							<td><ng-container [ngTemplateOutlet]="barcodeStatus" 
								[ngTemplateOutletContext]="{barcodeStatus:cardStatus.barcodeStatus}"></ng-container></td>							
							<td>
								<button class="btn btn-outline-secondary" 
									[routerLink]="['/kiosk','view', cardStatus.kiosk.id]"
									(click)="$event.stopPropagation()" 
									type="button">
									View <fa-icon [icon]="view" size="lg"></fa-icon>
								</button>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</div>

<div class="card m-3 shadow-lg bg-white rounded">
	<div class="card-header p-3">
		<fa-icon [icon]="printer" class="text-secondary"></fa-icon>&nbsp;Printer Problems
	</div>
	<div class="card-body">
		<div class="row">
			<div class="col table-responsive">
				<div *ngIf="!printerStatus">No printer problems</div>
				<table *ngIf="printerStatus" class="table table-hover ">
					<thead>
						<tr>
							<th scope="col">ID</th>
							<th scope="col">Device Ref</th>
							<th scope="col">Name</th>
							<th scope="col">Location</th>
							<th scope="col">Severity</th>
							<th scope="col">Printer Status</th>
							<th scope="col">Printer Update</th>
							<th>&nbsp;</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let status of printerStatus" (click)="viewKiosk(status.kiosk)">
							<td>{{status.kiosk.id}}</td>
							<td>{{status.kiosk.deviceRef}}</td>
							<td>{{status.kiosk.name}}</td>
							<td>{{util.getLocation(status.kiosk)}}</td>
							<td [ngClass]="{'text-danger':status.status === 'Severe'}">
								{{status.status}}
							</td>
							<td>{{status.printerStatus}}</td>
							<td>{{status.printerLastUpdated | date:'yyyy-MM-dd HH:mm:ss'}}</td>
							<td>
								<button class="btn btn-outline-secondary" 
									[routerLink]="['/kiosk','view', status.kiosk.id]"
									(click)="$event.stopPropagation()" 
									type="button">
									View <fa-icon [icon]="view" size="lg"></fa-icon>
								</button>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</div>

<ng-template #sensorStatus let-sensorStatus='sensorStatus'>
    <fa-icon *ngIf="sensorStatus === true" [icon]="sensorOk"  class="text-success"></fa-icon>
	<fa-icon *ngIf="sensorStatus === false" [icon]="sensorNotOk" class="text-danger"></fa-icon>
</ng-template>
<ng-template #standardStatus let-standardStatus='standardStatus'>
    <fa-icon *ngIf="standardStatus === true" [icon]="statusOk"  class="text-success"></fa-icon>
	<fa-icon *ngIf="standardStatus === false" [icon]="statusNotOk" class="text-danger"></fa-icon>
</ng-template>
<ng-template #barcodeStatus let-barcodeStatus='barcodeStatus'>
	<fa-icon *ngIf="barcodeStatus.alive == null" [icon]="statusUnknown"  class="text-secondary"></fa-icon>
    <fa-icon *ngIf="barcodeStatus?.alive === true" [icon]="statusOk"  class="text-success"></fa-icon>
	<fa-icon *ngIf="barcodeStatus?.alive === false" [icon]="statusNotOk" class="text-danger"></fa-icon>
</ng-template>
