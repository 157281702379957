import { Injectable } from "@angular/core";
import { AuthService } from "src/app/service/auth.service";
import { KioskCardDispenserStatusDto, KioskDto, KioskPrinterStatusDto, KioskUserDto } from "./dto";

@Injectable({ providedIn: 'root' })
export class UtilService {

	static getStatusClass(kiosk: KioskDto): string {
		switch (kiosk.kioskStatus) {
			case 'Verified':
				return 'alert alert-success';
			case 'New':
				return 'alert alert-info';
			case 'Blocked':
				return 'alert alert-warning';
			case 'Disabled':
				return 'alert alert-danger';
		}
	}

	static getPeripheralStatusCss(input: KioskCardDispenserStatusDto | KioskPrinterStatusDto) {
		return this.getHighLevelStatusCss(input.status.toString());
	}

	static getHighLevelStatusCss(input: string) {
		switch (input) {
			case 'Severe':
				return 'text-danger';
			case 'Problem':
				return 'text-warning';
			case 'Ok':
				return 'text-success';
		}
	}

	static getLocation(kiosk: KioskDto): string {
		if (!kiosk.location) {
			return '';
		}
		return kiosk.location.name + ' - ' + kiosk.location.city + ', ' + kiosk.location.region;
	}

	static getKioskDetails(kiosk: KioskDto): string {
		if (!kiosk) {
			return '';
		}
		if (kiosk.name === null) {
			return kiosk.deviceRef;
		}
		if (!kiosk.location) {
			return kiosk.name	
		}
		return kiosk.name + ' - ' + kiosk.location.name;
	}

	static generateUsername(user: KioskUserDto): string {
		if (user.name && user.surname) {
			return (user.name.substring(0,1) + user.surname).toLowerCase();
		}
	}

	static getVideoSize(videoSize: number): string {
		const k = 1024
		const dm = 2;
		const sizes = ['Bytes', 'KB', 'MB', 'GB']

		const i = Math.floor(Math.log(videoSize) / Math.log(k))

		return `${parseFloat((videoSize / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
	}

	static getVideoDuration(duration: number): string {
		const minutes = Math.floor(duration / 60);
		const seconds = Math.floor(duration % 60);
		return (minutes.toString().padStart(2, '0')) + ':' + (seconds.toString().padStart(2, '0'));
	}
}