import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractHttpService } from '../../service/http-abstract-service';
import { AdminKioskResponseDto, KioskVideoDto, KioskVideoLinkDto } from './dto';


@Injectable({
	providedIn: 'root'
})
export class HttpVideoService extends AbstractHttpService {

	constructor(http: HttpClient) {
		super(http, '/video');
	}

	getKioskVideo(videoId: number): Observable<KioskVideoDto> {
		return super.get(`/${videoId}/get`);
	}

	getAll(): Observable<KioskVideoDto[]> {
		return super.get(`/get`);
	}

	getVideo(videoId: number): Observable<Blob> {
		return super.doDownloadFileSimple(`/${videoId}/video`);
	}

	getThumbnail(videoId: number): Observable<Blob> {
		return super.doDownloadFileSimple(`/${videoId}/thumbnail`);
	}

	getAllLinks(): Observable<KioskVideoLinkDto[]> {
		return super.get(`/get-link`);
	}

	getLinks(videoId: number): Observable<KioskVideoLinkDto[]> {
		return super.get(`/${videoId}/get-link-by-video`);
	}

	saveLinks(videoId: number, links: KioskVideoLinkDto[]): Observable<AdminKioskResponseDto> {
		return super.post(`/${videoId}/save-video-link`, links);
	}

	newVideo(video: File, thumbnail: File, payload: KioskVideoDto): Observable<AdminKioskResponseDto> {
		var formData = new FormData();
		formData.append("video", video);
		formData.append("thumbnail", thumbnail);
		formData.append("payload", JSON.stringify(payload));
		return super.post(`/new`, formData);
	}
	editVideo(video: KioskVideoDto): Observable<AdminKioskResponseDto> {
		return super.post(`/edit`, video);
	}
	deleteVideo(videoId: number): Observable<AdminKioskResponseDto> {
		return super.get(`/${videoId}/delete`);
	}
}
