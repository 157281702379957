<ng-container *ngIf="edit">
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Edit Kiosk</h4>
		<button type="button" class="btn-close" aria-describedby="modal-title" (click)="cancel()">
		</button>
	</div>
	<div class="modal-body">
		<div class="row">
			<div class="col-lg-6 mb-3 mx-auto input-group">
				<span class="input-group-text">Name</span>
				<input type="text" class="form-control" id="name" aria-describedby="name" name="name" [(ngModel)]="edit.name" [maxlength]="30">
			</div>
		</div>
		<div class="row">
			<div class="col-lg-6 mb-3 mx-auto input-group">
				<span class="input-group-text">Location</span>
				<select name="location" class="form-control" id="location" [(ngModel)]="edit.location">
					<option *ngFor="let location of locations" [ngValue]="location">{{location.name}} - {{location.city}}, {{location.region}}</option>
				</select>
			</div>
		</div>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-outline-secondary" (click)="cancel()">Cancel</button>
		<button type="button" class="btn btn-success" (click)="save()">Save</button>
	</div>
</ng-container>