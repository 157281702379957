import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { KioskUserDto } from "../../dto";
import { HttpAdminService } from "../../http-admin.service";
import { AdminActionService } from "../../kiosk-admin.service";
import { faCheck, faPerson, faHourglassStart, faUnlock, faBan, faEdit, faSearch, faLock, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from "rxjs";
import { catchError } from "rxjs/operators";
import { AppToastService } from "src/app/components/toasts/app-toast.service";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
	selector: 'app-admin-list',
	templateUrl: './admin-list.component.html',
	styleUrls: ['./admin-list.component.scss']
})
export class AdminListComponent implements OnInit, OnDestroy {

	admins: KioskUserDto[];

	add = faPlusCircle;
	verified = faCheck;
	new = faHourglassStart;
	blocked = faLock;
	disabled = faBan;
	unlock = faUnlock;
	edit = faEdit;
	view = faSearch;
	editRoles = faPerson;

	subscription = new Subscription();

	constructor(
		private httpService: HttpAdminService, 
		private adminActionService: AdminActionService,
		private router: Router,
		private spinner: NgxSpinnerService,
		private toastService: AppToastService,) {
			this.subscription = this.adminActionService.onActionSuccess.subscribe (item => {
				switch (item.action) {
					case "Edit":
					case "Disable":
					case "Unblock":
					case "ReEnable":
						this.fetchAllAdmins();
						break;
				}
			});
	}

	ngOnInit(): void {
		this.fetchAllAdmins();
	}

	private fetchAllAdmins() {
		this.spinner.show();
		this.httpService.getAll()
		.pipe(
			catchError(error => {
				this.spinner.hide();
				console.log('Error fetching admins', error);
				this.toastService.showDanger({ content: 'Failed to fetch data: ' + (error.error ? error.error.error : error.message) });
				throw error;
			})
		)
		.subscribe(items => {
			this.admins = items;
			this.spinner.hide();
		});
	}

	addAdmin() {
		this.router.navigate(['/kiosk','add-admin']);
	}

	editAdmin(admin: KioskUserDto) {
		this.adminActionService.editAdmin(admin);
	}

	editAdminRoles(admin: KioskUserDto) {
		this.adminActionService.editAdminRoles(admin);
	}

	disableAdmin(admin: KioskUserDto) {
		this.adminActionService.disableAdmin(admin);
	}

	unblockAdmin(admin: KioskUserDto) {
		this.adminActionService.unblockAdmin(admin);
	}

	reEnableAdmin(admin: KioskUserDto) {
		this.adminActionService.reEnableAdmin(admin);
	}

	viewAdmin(admin: KioskUserDto) {
		this.router.navigate(['/kiosk','view-admin', admin.id]);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}
}