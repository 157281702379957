<div class="row d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
	<div class="col">	
		<div class="btn-group" role="group">
			<button class="btn btn-outline-secondary" 
				(click)="addLocation()" 
				type="button">
				Add <fa-icon [icon]="add" size="lg"></fa-icon>
			</button>
		</div>			
	</div>
</div>
<div class="pt-3 pb-2 mb-3">
	<div class="shadow-lg p-3 mb-5 bg-white rounded table-responsive">
		<table *ngIf="locations" class="table table-hover">
			<thead>
				<tr>
					<th>ID</th>
					<th>Created At</th>
					<th>Name</th>
					<th>Address</th>
					<th>City</th>
					<th>Region</th>
					<th>Latitude</th>
					<th>Longitude</th>
					<th>Active</th>
					<th>Action</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let location of locations">
					<th scope="row">{{location.id}}</th>
					<td>{{location.createdAt | date:'yyyy-MM-dd HH:mm:ss'}}</td>
					<td>{{location.name}}</td>
					<td>{{location.address}}</td>
					<td>{{location.city}}</td>
					<td>{{location.region}}</td>
					<td>{{location.latitude}}</td>
					<td>{{location.longitude}}</td>
					<td>
						<div [ngSwitch]="location.active">
							<fa-icon [icon]="verified" *ngSwitchCase="1" class="text-primary" title="Verified"></fa-icon>
							<fa-icon [icon]="disabled" *ngSwitchCase="0" class="text-danger" title="Disabled"></fa-icon>	
						</div>
					</td>
					<td>
						<div class="btn-group" role="group">
							<!-- <button class="btn btn-outline-secondary" 
								[routerLink]="['/location','view', location.id]" (click)="$event.stopPropagation()"
								type="button">
								View <fa-icon [icon]="view" size="lg"></fa-icon>
							</button> -->
							<button class="btn btn-outline-secondary" 
								(click)="editLocation(location); $event.stopPropagation()" 
								type="button">
								Edit <fa-icon [icon]="edit" size="lg"></fa-icon>
							</button>
							<button *ngIf="location.active === 1" 
								class="btn btn-outline-secondary" 
								(click)="disableLocation(location); $event.stopPropagation()" type="button">
								Disable <fa-icon [icon]="disabled" size="lg"></fa-icon>
							</button>
							<button *ngIf="location.active === 0" 
								class="btn btn-outline-secondary" 
								(click)="reEnableLocation(location); $event.stopPropagation()" type="button">
								Re-enable <fa-icon [icon]="unlock" size="lg"></fa-icon>
							</button>
						</div>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>
