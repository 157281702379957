import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { KioskRoleDto, KioskUserDto, KioskLocationDto } from "../../dto";
import { HttpAdminService } from "../../http-admin.service";
import { AdminActionService } from "../../kiosk-admin.service";
import { faArrowLeft,faArrowRight,faHouse,faPeopleArrows, faLocation } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from "rxjs";
import { Location } from "@angular/common";
import { catchError } from "rxjs/operators";
import { AppToastService } from "src/app/components/toasts/app-toast.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ConfirmModalComponent } from "../../components/confirm-modal/confirm-modal.component";
import { HttpLocationService } from "../../http-location.service";
import { Place } from "../../components/map/map.component";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
	selector: 'app-location-add',
	templateUrl: './location-add.component.html',
	styleUrls: ['./location-add.component.scss']
})
export class LocationAddComponent implements OnInit, OnDestroy {

	location: KioskLocationDto = {};

	back=faArrowLeft;
	newLocation=faHouse;
	selectedLocation=faLocation;

	place: Place;

	constructor(
		private locationService: Location, 
		private httpService: HttpLocationService, 
		private toastService: AppToastService,
		private modalService: NgbModal,
		private spinner: NgxSpinnerService) {
	}

	ngOnInit(): void {
	}

	goBack(): void {
		this.locationService.back()
	}

	addLocation() {

		if (!this.location.name) {
			this.toastService.showSecondary({ content: 'Please enter the name of the location' });
			return;
		}

		if (!this.location.address) {
			this.toastService.showSecondary({ content: 'Please enter the address of the location' });
			return;
		}

		if (!this.location.city) {
			this.toastService.showSecondary({ content: 'Please enter the city of the location' });
			return;
		}

		if (!this.location.region) {
			this.toastService.showSecondary({ content: 'Please enter the region of the location' });
			return;
		}

		if (!this.location.latitude) {
			this.toastService.showSecondary({ content: 'Please enter the latitude of the location' });
			return;
		}

		if (!this.location.longitude) {
			this.toastService.showSecondary({ content: 'Please enter the longitude of the location' });
			return;
		}

		const modalRef = this.modalService.open(ConfirmModalComponent, { windowClass: "modal-confirm" });
		modalRef.componentInstance.title = 'Add Location';
		modalRef.componentInstance.text = "Please confirm you wish to add the location.";

		modalRef.result.then((result) => {
			if (result === true) {
				this.doAddLocation();
			}
		});
	}
	private doAddLocation() {
		this.spinner.show();
		this.httpService.addLocation(this.location)
		.pipe(
			catchError(error => {
				this.spinner.hide();
				console.log('Error adding location', error);
				this.toastService.showDanger({ content: 'Failed to add location: ' + (error.error ? error.error.error : error.message) });
				throw error;
			})
		)
		.subscribe(data => {
			console.log('data', data);
			this.spinner.hide();
			if (!data.responseStatus) {
				this.toastService.showDanger({ content: 'Failed to add location: ' + data.responseStatusMessage });
				return;
			}

			this.toastService.showSuccess({ content: 'Location saved successfully'});

			this.location = {};
			this.place = null;
		});
	}

	get disableSave(): boolean {
		return !this.place || !this.location.name;
	}

	addressSelected(place: Place) {
		this.place = place;
		console.log('Place: ', place);
		if (place != null){
			this.location.address = place.address;
			this.location.city = place.city;
			this.location.region = place.province;
			this.location.latitude = ''+place.latitude;
			this.location.longitude = ''+place.longitude;
		}		
	}

	ngOnDestroy(): void {
		
	}
}