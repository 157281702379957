import { Injectable, Output, EventEmitter } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { catchError } from "rxjs/operators";
import { AppToastService } from "src/app/components/toasts/app-toast.service";
import { ConfirmModalComponent } from "./components/confirm-modal/confirm-modal.component";
import { KioskDto, KioskLocationDto } from "./dto";
import { HttpKioskService } from "./http-kiosk.service";
import { HttpLocationService } from "./http-location.service";
import { EditKioskModalComponent } from "./pages/kiosk-list/edit-kiosk-modal/edit-kiosk-modal.component";
import { VerifyKioskModalComponent } from "./pages/kiosk-list/verify-kiosk-modal/verify-kiosk-modal.component";

export class LocationAction {
	action: 'Edit' | 'Disable' | 'ReEnable';
}

@Injectable({ providedIn: 'root' })
export class LocationActionService {

	@Output() onActionSuccess: EventEmitter<LocationAction> = new EventEmitter(); 

	constructor(
		private httpService: HttpLocationService,
		private modalService: NgbModal,
		private toastService: AppToastService,){}

	editLocation(location: KioskLocationDto) {
		// const modalRef = this.modalService.open(EditKioskModalComponent, { windowClass: "modal-edit-kiosk" });
		// modalRef.componentInstance.kiosk = kiosk;

		// modalRef.result.then((result) => {
		// 	if (result === true) {
		// 		this.onActionSuccess.emit({action: 'Edit'});
		// 	}
		// });
	}

	disableLocation(location: KioskLocationDto) {
		const modalRef = this.modalService.open(ConfirmModalComponent, { windowClass: "modal-confirm" });
		modalRef.componentInstance.title = 'Disable Location';
		modalRef.componentInstance.text = 'Please confirm you wish to disable the selected location.';

		modalRef.result.then((result) => {
			if (result === true) {
				this.changeLocationStatus(location, 'Disable');
			}
		});
	}

	reEnableLocation(location: KioskLocationDto) {
		const modalRef = this.modalService.open(ConfirmModalComponent, { windowClass: "modal-confirm" });
		modalRef.componentInstance.title = 'Re-enable Location';
		modalRef.componentInstance.text = 'Please confirm you wish to re-enable the selected location.';

		modalRef.result.then((result) => {
			if (result === true) {
				this.changeLocationStatus(location, 'ReEnable');
			}
		});
	}

	private changeLocationStatus(location: KioskLocationDto, action: 'Disable' | 'ReEnable') {
		this.httpService.changeLocationStatus(location.id, action)
		.pipe(
			catchError(error => {
				console.log('Error changing location status', error);
				this.toastService.showDanger({ content: 'Failed to ' + action + ' location: ' + (error.error ? error.error.error : error.message) });
				throw error;
			})
		)
		.subscribe(data => {
			if (!data.responseStatus) {
				this.toastService.showDanger({ content: 'Failed to ' + action + ' location: ' + data.responseStatusMessage });
				return;
			}

			this.toastService.showSuccess({ content: 'Action performed successfully'});
			this.onActionSuccess.emit({action: action});
		});
	}
}