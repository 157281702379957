<div class="row d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
	<div class="col">	
		<div class="btn-group" role="group">
			<button class="btn btn-outline-secondary" 
				(click)="addVideo()" 
				type="button">
				Add <fa-icon [icon]="add" size="lg"></fa-icon>
			</button>
		</div>			
	</div>
</div>
<div class="pt-3 pb-2 mb-3">
	<div class="shadow-lg p-3 mb-5 bg-white rounded table-responsive">
		<table *ngIf="videos" class="table table-hover">
			<thead>
				<tr>
					<th>ID</th>
					<th>Created At</th>
					<th>Run Date</th>
					<th>Stop Date</th>
					<th>Title</th>
					<th>Description</th>
					<th>Target Age</th>
					<th>Target Gender</th>
					<th>Size</th>
					<th>Format</th>
					<th>Duration</th>
					<th>Thumbnail</th>
					<th></th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let video of videos">
					<th scope="row">{{video.id}}</th>
					<td>{{video.createdAt | date:'yyyy-MM-dd HH:mm:ss'}}</td>
					<td>{{video.enabledAt | date:'yyyy-MM-dd'}}</td>
					<td>{{video.disabledAt | date:'yyyy-MM-dd'}}</td>
					<td>{{video.title}}</td>
					<td>{{video.description}}</td>
					<td>{{video.targetAge}}</td>
					<td>{{video.targetGender}}</td>
					<td>{{getSize(video.videoSize)}}</td>
					<td>{{video.videoFormat}}</td>
					<td>{{getDuration(video.videoDuration)}}</td>
					<td><img [src]="video.thumbnail" class="video-thumbnail"/></td>
					<td>
						<div class="btn-group" role="group">
							<button class="btn btn-outline-secondary" 
								(click)="editVideo(video); $event.stopPropagation()" 
								type="button">
								Edit <fa-icon [icon]="edit" size="lg"></fa-icon>
							</button>
							<button class="btn btn-outline-secondary" 
								(click)="watchVideo(video); $event.stopPropagation()" 
								type="button">
								Watch <fa-icon [icon]="watch" size="lg"></fa-icon>
							</button>
							<button class="btn btn-outline-secondary" 
								(click)="linkVideo(video); $event.stopPropagation()" 
								type="button">
								Link <fa-icon [icon]="link" size="lg"></fa-icon>
							</button>
							<button class="btn btn-outline-secondary" 
								(click)="deleteVideo(video); $event.stopPropagation()" 
								type="button">
								Delete <fa-icon [icon]="disabled" size="lg"></fa-icon>
							</button>
						</div>
					</td>
				</tr>
			</tbody>
		</table>
	</div>	
</div>

