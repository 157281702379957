import { Injectable, Output, EventEmitter } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { catchError } from "rxjs/operators";
import { AppToastService } from "src/app/components/toasts/app-toast.service";
import { ConfirmModalComponent } from "./components/confirm-modal/confirm-modal.component";
import { KioskDto } from "./dto";
import { HttpKioskService } from "./http-kiosk.service";
import { EditKioskModalComponent } from "./pages/kiosk-list/edit-kiosk-modal/edit-kiosk-modal.component";
import { VerifyKioskModalComponent } from "./pages/kiosk-list/verify-kiosk-modal/verify-kiosk-modal.component";
import { NgxSpinnerService } from "ngx-spinner";
import { AuthService } from "src/app/service/auth.service";

export class KioskAction {
	action: 'Invalidate' | 'Edit' | 'Verify' | 'Disable' | 'Unblock' | 'ReEnable';
}

@Injectable({ providedIn: 'root' })
export class KioskActionService {

	@Output() onActionSuccess: EventEmitter<KioskAction> = new EventEmitter(); 

	constructor(
		private httpService: HttpKioskService,
		private modalService: NgbModal,
		private toastService: AppToastService,
		private authService: AuthService,
		private spinner: NgxSpinnerService){}

	editKiosk(kiosk: KioskDto) {
		const modalRef = this.modalService.open(EditKioskModalComponent, { windowClass: "modal-edit-kiosk" });
		modalRef.componentInstance.kiosk = kiosk;

		modalRef.result.then((result) => {
			if (result === true) {
				this.onActionSuccess.emit({action: 'Edit'});
			}
		});
	}

	disableKiosk(kiosk: KioskDto) {
		const modalRef = this.modalService.open(ConfirmModalComponent, { windowClass: "modal-confirm" });
		modalRef.componentInstance.title = 'Disable Kiosk';
		modalRef.componentInstance.text = 'Please confirm you wish to disable the selected kiosk.';

		modalRef.result.then((result) => {
			if (result === true) {
				this.changeKioskStatus(kiosk, 'Disable');
			}
		});
	}

	unblockKiosk(kiosk: KioskDto) {
		const modalRef = this.modalService.open(ConfirmModalComponent, { windowClass: "modal-confirm" });
		modalRef.componentInstance.title = 'Unblock Kiosk';
		modalRef.componentInstance.text = 'Please confirm you wish to unblock the selected kiosk.';

		modalRef.result.then((result) => {
			if (result === true) {
				this.changeKioskStatus(kiosk, 'Unblock');
			}
		});
	}

	reEnableKiosk(kiosk: KioskDto) {
		const modalRef = this.modalService.open(ConfirmModalComponent, { windowClass: "modal-confirm" });
		modalRef.componentInstance.title = 'Re-enable Kiosk';
		modalRef.componentInstance.text = 'Please confirm you wish to re-enable the selected kiosk.';

		modalRef.result.then((result) => {
			if (result === true) {
				this.changeKioskStatus(kiosk, 'ReEnable');
			}
		});
	}

	private changeKioskStatus(kiosk: KioskDto, action: 'Disable' | 'Unblock' | 'ReEnable') {
		this.spinner.show();
		this.httpService.changeKioskStatus(kiosk.deviceRef, action)
		.pipe(
			catchError(error => {
				this.spinner.hide();
				console.log('Error changing kiosk status', error);
				this.toastService.showDanger({ content: 'Failed to ' + action + ' kiosk: ' + (error.error ? error.error.error : error.message) });
				throw error;
			})
		)
		.subscribe(data => {
			this.spinner.hide();
			if (!data.responseStatus) {
				this.toastService.showDanger({ content: 'Failed to ' + action + ' kiosk: ' + data.responseStatusMessage });
				return;
			}

			this.toastService.showSuccess({ content: 'Action performed successfully'});
			this.onActionSuccess.emit({action: action});
		});
	}

	verifyKiosk(kiosk: KioskDto) {
		const modalRef = this.modalService.open(VerifyKioskModalComponent, { windowClass: "modal-verify-kiosk" });
		modalRef.componentInstance.kiosk = kiosk;

		modalRef.result.then((result) => {
			if (result === true) {
				this.onActionSuccess.emit({action: 'Verify'});
			}
		});
	}

	invalidateSecret(kiosk: KioskDto) {

		const modalRef = this.modalService.open(ConfirmModalComponent, { windowClass: "modal-confirm" });
		modalRef.componentInstance.title = 'Invalidate Kiosk';
		modalRef.componentInstance.text = "Please confirm you wish to invalidate the selected kiosk's authentication credentials.";
		modalRef.componentInstance.additionalText = "This action will prevent the kiosk from authenticating with the system, thereby blocking all actions. Only do this if the kiosk's credentials have become compromised or a reset is required.";

		modalRef.result.then((result) => {
			if (result === true) {
				this.doInvalidateKiosk(kiosk);
			}
		});
	}

	private doInvalidateKiosk(kiosk: KioskDto) {
		this.spinner.show();
		this.httpService.invalidateKiosk(kiosk.deviceRef)
		.pipe(
			catchError(error => {
				this.spinner.hide();
				console.log('Error invalidating kiosk secret', error);
				this.toastService.showDanger({ content: 'Failed to invalidate kiosk credentials: ' + (error.error ? error.error.error : error.message) });
				throw error;
			})
		)
		.subscribe(data => {
			this.spinner.hide();
			if (!data.responseStatus) {
				this.toastService.showDanger({ content: 'Failed to invalidate kiosk credentials: ' + data.responseStatusMessage });
				return;
			}

			this.toastService.showSuccess({ content: 'Kiosk credentials successfully invalidated'});
			this.onActionSuccess.emit({action: 'Invalidate'});
		});
	}

	isSecretInvalid(kiosk: KioskDto): boolean {
		if (kiosk.secretInvalidAt === null) {
			return false;
		}
		var date = new Date();
		var invalid = new Date(kiosk.secretInvalidAt);		
		return invalid.getTime() <= date.getTime();
	}

	canVerify(kiosk: KioskDto): boolean {
		if (kiosk.kioskStatus === 'Disabled') {
			return false;
		}
		if (this.isSecretInvalid(kiosk)) {
			return true;
		}
		if (kiosk.kioskStatus === 'Verified' || kiosk.kioskStatus === 'Blocked') {
			return false;
		}
		return true;
	}
}