import { HttpParams, HttpEvent } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractHttpService } from '../../service/http-abstract-service';
import { AdminKioskResponseDto, KioskMaintenanceLogDto, KioskMonitorStatusDto, PeripheralEnum } from './dto';


@Injectable({
	providedIn: 'root'
})
export class HttpAlarmService extends AbstractHttpService {

	constructor(http: HttpClient) {
		super(http, '/alarms');
	}

	getAll(): Observable<KioskMonitorStatusDto[]> {
		return super.get(`/get`);
	}

	getKioskAlarms(kioskId: number): Observable<KioskMonitorStatusDto[]> {
		return super.get(`/${kioskId}/get-by-kiosk`);
	}

	addLog(log: KioskMaintenanceLogDto): Observable<AdminKioskResponseDto> {
		return super.post(`/add-log`, log);
	}

	getMaintenanceLogs(kioskId: number, peripheral: PeripheralEnum, alarmId: number): Observable<KioskMaintenanceLogDto[]> {
		return super.get(`/${kioskId}/${PeripheralEnum[peripheral]}/${alarmId}/get-maintenance-logs`);
	}
}
