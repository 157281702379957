import { HttpParams, HttpEvent } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractHttpService } from '../../service/http-abstract-service';
import { AdminKioskResponseDto, KioskCardDispenserStatusDto, KioskDto, KioskPrinterStatusDto } from './dto';


@Injectable({
	providedIn: 'root'
})
export class HttpKioskService extends AbstractHttpService {

	constructor(http: HttpClient) {
		super(http, '/kiosk');
	}

	getAll(): Observable<KioskDto[]> {
		return super.get(`/get`);
	}
	getAllVerified(): Observable<KioskDto[]> {
		return super.get(`/get-verified`);
	}

	getProblematicKiosksCardDispenser(): Observable<KioskCardDispenserStatusDto[]> {
		return super.get(`/get-problematic/card-dispenser`);
	}

	getProblematicKiosksPrinter(): Observable<KioskPrinterStatusDto[]> {
		return super.get(`/get-problematic/printer`);
	}

	getKiosk(kioskId: number): Observable<KioskDto> {
		return super.get(`/${kioskId}/get`);
	}

	changeKioskStatus(kioskDeviceRef: string, status: 'Unblock' | 'Disable' | 'ReEnable'): Observable<AdminKioskResponseDto> {
		return super.get(`/${kioskDeviceRef}/${status}/action`);
	}
	 
	verifyKiosk(kioskDeviceRef: string, code: number): Observable<AdminKioskResponseDto> {
		return super.get(`/${kioskDeviceRef}/${code}/verify`);
	}

	editKiosk(kiosk: KioskDto): Observable<AdminKioskResponseDto> {
		return super.post(`/${kiosk.deviceRef}/edit`, kiosk);
	}

	invalidateKiosk(kioskDeviceRef: string): Observable<AdminKioskResponseDto> {
		return super.get(`/${kioskDeviceRef}/invalidate-kiosk-secret`);
	}

	getAllAwaitingVerification(): Observable<KioskDto[]> {
		return super.get(`/get-awaiting-verification`);
	}
}
