<div class="row">
	<div class="col col-md-10 col-lg-6 mx-auto">
		<div class="card m-3 shadow-lg bg-white rounded">
			<div class="card-header">
				<div class="row">
					<div class="col pt-1"><fa-icon [icon]="newVideo" class="text-secondary"></fa-icon>&nbsp;{{videoId !== 0 ? 'Edit Video' : 'New Video'}}</div>
					<div class="col text-end">
						<button class="float-end btn btn-outline-secondary" (click)="goBack()"><fa-icon [icon]="back" size="sm" ></fa-icon></button>
					</div>
				</div>
			</div>
			<div class="card-body">
				<div class="row">
					<div class="col-lg-6 mb-3 mx-auto input-group">
						<span class="input-group-text">Title</span>
						<input type="text" class="form-control" id="title" aria-describedby="title" name="title" [(ngModel)]="kioskVideo.title" [maxlength]="100">
					</div>
				</div>
				<div class="row">
					<div class="col-lg-6 mb-3 mx-auto input-group">
						<span class="input-group-text">Description</span>
						<textarea class="form-control" id="description" aria-describedby="description" name="description" [(ngModel)]="kioskVideo.description" [maxlength]="500" rows="5"></textarea>
					</div>
				</div>
				<div class="row">
					<div class="col-lg-6 mb-3 mx-auto input-group">
						<span class="input-group-text">Target Age</span>
						<select name="age" class="form-control" id="age" [(ngModel)]="kioskVideo.targetAge">
							<option *ngFor="let targetAge of targetAgeList" [ngValue]="targetAge">{{targetAge}}</option>
						</select>
					</div>
				</div>
				<div class="row">
					<div class="col-lg-6 mb-3 mx-auto input-group">
						<span class="input-group-text">Target Gender</span>
						<select name="gender" class="form-control" id="gender" [(ngModel)]="kioskVideo.targetGender">
							<option *ngFor="let targetGender of targetGenderList" [ngValue]="targetGender">{{targetGender}}</option>
						</select>
					</div>
				</div>
				<div class="row">
					<div class="col-lg-6 mb-3 mx-auto input-group">
						<span class="input-group-text">Target Race</span>
						<select name="race" class="form-control" id="race" [(ngModel)]="kioskVideo.targetRace">
							<option *ngFor="let targetRace of targetRaceList" [ngValue]="targetRace">{{targetRace}}</option>
						</select>
					</div>
				</div>
				<div class="row ">
					<div class="col-lg-6 mb-3 mx-auto input-group">
						<div class="input-group">
							<span class="input-group-text">Run Date</span>
							<input
								class="form-control"
								placeholder="yyyy-mm-dd"
								name="dp"
								[(ngModel)]="enabledAt"
								ngbDatepicker
								#d="ngbDatepicker"
							/>
							<button class="btn btn-outline-secondary" (click)="d.toggle()" type="button"><fa-icon [icon]="date" class="float-end text-secondary"></fa-icon></button>
							<div class="input-group-append" data-toggle="tooltip" data-placement="right" title="Set the run date if you want to schedule the video to display in the future">
								<span class="input-group-text"><fa-icon [icon]="tooltip" class="text-secondary" size="md"></fa-icon></span>
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-lg-6 mb-3 mx-auto input-group">
						<div class="input-group">
							<span class="input-group-text">Stop Date</span>
							<input
								class="form-control"
								placeholder="yyyy-mm-dd"
								name="dp"
								[(ngModel)]="disabledAt"
								ngbDatepicker
								#stopdate="ngbDatepicker"
							/>
							<button class="btn btn-outline-secondary" (click)="stopdate.toggle()" type="button"><fa-icon [icon]="date" class="float-end text-secondary"></fa-icon></button>
							<div class="input-group-append" data-toggle="tooltip" data-placement="right" title="Set the stop date if you want to stop the video from displaying at a certain date">
								<span class="input-group-text"><fa-icon [icon]="tooltip" class="text-secondary" size="md"></fa-icon></span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="card-footer" *ngIf="mode === 'edit'">
				<button type="button" class="btn btn-success" (click)="doEditVideo()" [disabled]="disableEditSave">Save</button>
			</div>
		</div>
	</div>
</div>
<div class="row" *ngIf="mode === 'add'">
	<div class="col col-md-10 col-lg-6 mx-auto">
		<div class="card m-3 shadow-lg bg-white rounded">
			<div class="card-header p-3">
				Upload video
			</div>
			<div class="card-body" file-dropzone (onFileDropped)="onFileSelected($event)">
				<!-- <div class="row file-upload-drag">
					<div class="col text-center">
						Drag and drop your file here
					</div>
				</div>	
				<div class="row">
					<div class="col text-center">or</div>
				</div> -->
				<div class="row file-upload">
					<div class="col text-center">
						<div class="row">
							<div class="col text-center">Drag and drop your file here</div>
						</div>
						<div class="row">
							<div class="col text-center m-2">or</div>
						</div>
						<div class="row">
							<div class="col text-center">
								<input type="file" (change)="onFileUpload($event)" />
							</div>
						</div>
					</div>
				</div>
				<div class="row" *ngIf="file">
					<div class="col table-responsive">
						<table class="table table-hover">
							<thead>
								<tr>
									<th>Name</th>
									<th>Type</th>
									<th>Size</th>
									<th>Duration</th>
									<th>Thumbnail</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>{{file.name}}</td>
									<td>{{file.type}}</td>
									<td>{{size}}</td>
									<td>{{durationFriendly}}</td>
									<td><img *ngIf="thumbnail" [src]="thumbnailUrl" width="96" height="84"/></td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>	
				<div class="row" *ngIf="fileUrl">
					<div class="col">
						<video #video id="video" [src]="fileUrl" controls autoplay (loadedmetadata)="onVideoLoaded($event)"></video>
					</div>
				</div>	
			</div>
			<div class="card-footer">
				<button type="button" class="btn btn-success" (click)="doNewVideo()" [disabled]="disableNewSave">Save</button>
			</div>
		</div>
	</div>
</div>
