<div class="modal-header">
	<h4 class="modal-title" id="modal-title">Verify Kiosk</h4>
	<button type="button" class="btn-close" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
	<p>Please enter the 5 digit code that is displayed on the Kiosk screen</p>
	<div class="row">
		<div class="col-lg-6 mb-3 mx-auto">
			<label for="kioskCode" class="form-label">Kiosk Code</label>
			<input type="number" 
				class="form-control" 
				id="kioskCode" 
				aria-describedby="kioskCode" 
				name="kioskCode" 
				[(ngModel)]="kioskCode" 
				[maxlength]="5"
				pattern="/^-?\d+\.?\d*$/" 
				onKeyPress="if(this.value.length==5) return false;">
		</div>
	</div>
	<!-- <div class="form-group">
		<div class="input-group">
			<label class="sr-only" for="kioskCode">Kiosk Code</label>
			<div class="input-group mb-2 input-group-sm">
				<div class="input-group-prepend">
					<div class="input-group-text">Kiosk Code</div>
				</div>
				<input type="number" class="form-control" id="kioskCode" placeholder="Kiosk Code" [(ngModel)]="kioskCode" name="kioskCode">
			</div>
		</div>
	</div> -->
</div>
<div class="modal-footer">
	<button type="button" class="btn btn-outline-secondary" (click)="cancel()">Cancel</button>
	<button type="button" class="btn btn-success" (click)="verify()">Verify</button>
</div>
