<ng-container *ngIf="technician">
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Edit Technician</h4>
		<button type="button" class="btn-close" aria-describedby="modal-title" (click)="cancel()">
		</button>
	</div>
	<div class="modal-body">
		<div class="row">
			<div class="col-lg-6 mb-3 mx-auto input-group">
				<span class="input-group-text">Name</span>
				<input type="text" class="form-control" id="name" aria-describedby="name" name="name" [(ngModel)]="edit.name" [maxlength]="50">
			</div>
		</div>
		<div class="row">
			<div class="col-lg-6 mb-3 mx-auto input-group">
				<span class="input-group-text">Surname</span>
				<input type="text" class="form-control" id="surname" aria-describedby="surname" name="surname" [(ngModel)]="edit.surname" [maxlength]="50">
			</div>
		</div>
		<div class="row">
			<div class="col-lg-6 mb-3 mx-auto input-group">
				<span class="input-group-text">MSISDN</span>
				<input type="text" class="form-control" id="msisdn" aria-describedby="msisdn" name="msisdn" [(ngModel)]="edit.msisdn" [maxlength]="50">
			</div>
		</div>
		<div class="row">
			<div class="col-lg-6 mb-3 mx-auto input-group">
				<span class="input-group-text">Email</span>
				<input type="text" class="form-control" id="email" aria-describedby="email" name="email" [(ngModel)]="edit.email" [maxlength]="50">
			</div>
		</div>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-outline-secondary" (click)="cancel()">Cancel</button>
		<button type="button" class="btn btn-success" (click)="save()">Save</button>
	</div>
</ng-container>