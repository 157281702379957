import { Component, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';

@Component({
	selector: 'app-camera',
	templateUrl: './camera.component.html',
	styleUrls: ['./camera.component.scss']
})
export class CameraComponent implements OnInit {
	private trigger: Subject<any> = new Subject();
	public webcamImage!: WebcamImage;
	private nextWebcam: Subject<any> = new Subject();
	sysImage = '';
	ngOnInit() { }
	public getSnapshot(): void {
		this.trigger.next(void 0);
	}
	public captureImg(webcamImage: WebcamImage): void {
		this.webcamImage = webcamImage;
		this.sysImage = webcamImage!.imageAsDataUrl;
		console.info('got webcam image', this.sysImage);
	}
	public get invokeObservable(): Observable<any> {
		return this.trigger.asObservable();
	}
	public get nextWebcamObservable(): Observable<any> {
		return this.nextWebcam.asObservable();
	}

	get pictureTaken(): boolean {
		if (!this.webcamImage) {
			return false;
		}

		return this.webcamImage.imageAsDataUrl !== undefined;
	}
}