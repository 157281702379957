import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AppToastService } from "src/app/components/toasts/app-toast.service";
import { KioskDto, KioskLocationDto } from "../../dto";
import { HttpKioskService } from "../../http-kiosk.service";
import { faCheck, faHourglassStart, faLock, faUnlock, faBan, faEdit, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { UtilService } from "../../util.service";
import { KioskActionService } from "../../kiosk-action.service";
import { Subject, Subscription } from "rxjs";
import { HttpLocationService } from "../../http-location.service";
import { catchError, debounceTime } from "rxjs/operators";
import { NgxSpinnerService } from "ngx-spinner";
import { AuthService } from "src/app/service/auth.service";
import { environment } from "src/environments/environment";


@Component({
	selector: 'app-kiosk-verify',
	templateUrl: './kiosk-verify.component.html',
	styleUrls: ['./kiosk-verify.component.scss']
})
export class KioskVerifyComponent implements OnInit, OnDestroy {

	kiosks: KioskDto[];
	view = faSearch;
	new = faHourglassStart;
	disabled = faBan;
	verified = faCheck;
	clearSearch=faTimes;
	util = UtilService;

	private modelChanged: Subject<string> = new Subject<string>();
  	private modelSubscription: Subscription;
  	debounceTime = 500;
	filter: string;
	region: KioskLocationDto;
	city: KioskLocationDto;

	subscription = new Subscription();

	regions: KioskLocationDto[];
	cities: KioskLocationDto[];

	constructor(
		private httpService: HttpKioskService, 
		private router: Router,
		private kioskActionService: KioskActionService,
		private httpLocationService: HttpLocationService,
		private spinner: NgxSpinnerService,
		private toastService: AppToastService,
		private authService: AuthService) {

			this.subscription = this.kioskActionService.onActionSuccess.subscribe (item => {
				switch (item.action) {
					case "Verify":
						this.fetchAllKiosksAwaitingVerification();
						break;
				}
			});
	}

	ngOnInit(): void {
		this.fetchAllKiosksAwaitingVerification();

		this.modelSubscription = this.modelChanged
		.pipe(
			debounceTime(this.debounceTime),
		)
		.subscribe(() => {
			this.applyFilter();
		});
	}

	private fetchAllKiosksAwaitingVerification() {
		this.spinner.show();
		this.httpService.getAllAwaitingVerification()
		.pipe(
			catchError(error => {
				this.spinner.hide();
				console.log('Error fetching kiosks awaiting verification', error);
				this.toastService.showDanger({ content: 'Failed to fetch data: ' + (error.error ? error.error.error : error.message) });
				throw error;
			})
		)
		.subscribe(items => {
			this.kiosks = items;
			this.kiosks.forEach(item => item.visible = true);
			this.spinner.hide();
		});

		this.httpLocationService.getAllRegions().subscribe(items => this.regions = items);
	}

	verifyKiosk(kiosk: KioskDto) {
		this.kioskActionService.verifyKiosk(kiosk);
	}

	viewKiosk(kiosk: KioskDto) {
		this.router.navigate(['/kiosk','view', kiosk.id]);
	}

	isSecretInvalid(kiosk: KioskDto): boolean {
		return this.kioskActionService.isSecretInvalid(kiosk);
	}

	filterChanged() {
		this.modelChanged.next(this.filter);
	}

	get visibleKiosks(): KioskDto[] {
		return this.kiosks.filter(item => item.visible);
	}

	private applyFilter() {
		this.resetFilter();

		if (this.filter) {
			var filteredByName = this.kiosks
				.filter(item => item.name !== null)
				.filter(item => item.name.toLowerCase().includes(this.filter.toLowerCase()));
			var filteredByDeviceRef = this.kiosks.filter(item => item.deviceRef.toLowerCase().includes(this.filter.toLowerCase()));

			this.kiosks.forEach(item => {
				item.visible = false;
				
				if (filteredByName) {
					filteredByName.filter(filter => filter.id === item.id)
						.forEach(item => item.visible = true);
				}
				
				if (filteredByDeviceRef) {
					filteredByDeviceRef.filter(filter => filter.id === item.id)
						.forEach(item => item.visible = true);
				}
			});
		}

		var filteredByRegion = this.region 
			? this.kiosks.filter(item => item.location !== null)
				.filter(item => item.location.region === this.region.region) 
			: null;
		if (filteredByRegion) {
			this.kiosks.forEach(item => {
				if (item.visible === false) {
					return;
				}
				item.visible = false;

				filteredByRegion.filter(filter => filter.id === item.id)
					.forEach(item => item.visible = true);
			});
		}

		var filteredByCity = this.city 
			? this.kiosks.filter(item => item.location !== null)
				.filter(item => item.location.city === this.city.city)
			: null;
		if (filteredByCity) {
			this.kiosks.forEach(item => {
				if (item.visible === false) {
					return;
				}
				item.visible = false;

				filteredByCity.filter(filter => filter.id === item.id)
						.forEach(item => item.visible = true);
			});
		}
	}

	resetFilter() {
		this.kiosks.forEach(item => {
			item.visible = true;
		});	
	}

	clearFilter() {
		this.filter = null;
		this.applyFilter();
	}

	clearRegion() {
		this.region = null;
		this.city = null;
		this.applyFilter();
	}

	clearCity() {
		this.city = null;
		this.applyFilter();
	}

	onRegionChange($event) {
		this.region = $event;
		this.city = null;
		this.httpLocationService.getCitiesByRegion(this.region).subscribe(item => this.cities = item);
		this.applyFilter();
	}

	onCityChange($event) {
		this.city = $event;
		this.applyFilter();
	}

	getButtonVisible(action: string) {
		return this.authService.hasAnyRole(environment.allowedActions.filter(item => item.action === action)[0].role);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
		if (this.modelSubscription) {
			this.modelSubscription.unsubscribe();
		}
	}
}