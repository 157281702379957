import { Injectable, TemplateRef } from '@angular/core';


export interface Toast {
	content: string | TemplateRef<any>;

	unique?: string;
	reference?: number;
	classname?: string;
	autohide?: boolean;
	delay?: number;
	closeOnClick?: boolean;
}

@Injectable({ providedIn: 'root' })
export class AppToastService {
	toasts: Toast[] = [];
	counter = 0;

	showPrimary(toast: Toast): number {
		toast.classname = 'bg-primary text-light';
		return this.show(toast);
	}
	showSecondary(toast: Toast): number {
		toast.classname = 'bg-secondary text-light';
		return this.show(toast);
	}
	showSuccess(toast: Toast): number {
		toast.classname = 'bg-success text-light';
		return this.show(toast);
	}
	showDanger(toast: Toast): number {
		toast.classname = 'bg-danger text-light';
		return this.show(toast);
	}
	showWarning(toast: Toast): number {
		toast.classname = 'bg-warning text-light';
		return this.show(toast);
	}
	showInfo(toast: Toast): number {
		toast.classname = 'bg-info text-light';
		return this.show(toast);
	}
	private show(toast: Toast): number {
		toast = Object.assign({ autohide: true, closeOnClick: true, delay: 5000}, toast);
		toast.reference = this.counter++;

		if (toast.unique && toast.unique !== null) {
			this.toasts
				.filter(item => item.unique && item.unique !== null && item.unique === toast.unique)
				.forEach(item => {
					this.remove(item);
				});
		}
		this.toasts.push(toast);
		return toast.reference;
	}


	private remove(toast) {
		this.toasts = this.toasts.filter(t => t !== toast);
	}
	removeWithReference(reference: number) {
		this.toasts
			.filter(item => item.reference === reference)
			.forEach(item => {
				this.remove(item);
			});
	}
	clicked(toast) {
		if (toast.closeOnClick && toast.closeOnClick === true) {
			this.remove(toast);
		}
	}
	hide(toast) {
		this.remove(toast);
	}
}
