import { HttpParams, HttpEvent } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractHttpService } from '../../service/http-abstract-service';
import { AdminKioskResponseDto, KioskCardDispenserStatusDto, KioskComponentDto, KioskComponentInitDto, KioskDto, KioskPrinterStatusDto } from './dto';


@Injectable({
	providedIn: 'root'
})
export class HttpComponentsService extends AbstractHttpService {

	constructor(http: HttpClient) {
		super(http, '/components');
	}

	getKioskComponents(kioskId: number): Observable<KioskComponentDto[]> {
		return super.get(`/${kioskId}/get`);
	}

	getKioskComponentInit(kioskId: number): Observable<KioskComponentInitDto> {
		return super.get(`/${kioskId}/get-component-init`);
	}
}
