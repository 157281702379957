import { Routes, RouterModule } from '@angular/router';
import { AuthService } from './service/auth.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ToastsComponent } from './components/toasts/toasts/toasts.component';
import { SignInComponent } from './pages/sign-in/sign-in.component';
import { JwtModule } from "@auth0/angular-jwt";
import { SessionTimeoutNotificationComponent } from './components/session-timeout-notification/session-timeout-notification.component';
import { HttpConfigService } from './service/http-config.service';
import { MainPageComponent } from './pages/main-page/main-page.component';
import { KioskListComponent } from './modules/kiosk/pages/kiosk-list/kiosk-list.component';
import { UserSetPasswordComponent } from './pages/user-set-password/user-set-password.component';
import { TopBarComponent } from './modules/kiosk/components/top-bar/top-bar.component';
import { UserProfileComponent } from './pages/user-profile/user-profile.component';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgxSpinnerModule } from "ngx-spinner";
import { WebcamModule } from 'ngx-webcam';
import { ZXingScannerModule } from '@zxing/ngx-scanner';

const routes: Routes = [
	// { path: 'home', component: HomeComponent },
	// { path: 'report', loadChildren: () => import('./modules/reporting/reporting.module').then(m => m.ReportingModule) },
	// { path: 'release-management', loadChildren: () => import('./modules/release-management/release-management.module').then(m => m.ReleaseManagementModule) },
	{ path: 'kiosk', loadChildren: () => import('./modules/kiosk/kiosk.module').then(m => m.KioskModule) },
	
	// { path: 'ekyc', loadChildren: () => import('./modules/ekyc/ekyc.module').then(m => m.EkycModule) },
	// { path: '**', redirectTo: '/kiosk/list', pathMatch: 'full' },
	{ path: '**', component: KioskListComponent },
];

@NgModule({
	declarations: [
		AppComponent,
		ToastsComponent,
		SignInComponent,
		SessionTimeoutNotificationComponent,
  		MainPageComponent,
		UserSetPasswordComponent,
		UserProfileComponent,
		TopBarComponent
	],
	imports: [
		BrowserModule,
		NgbModule,
		WebcamModule,
		ZXingScannerModule,
		FontAwesomeModule,
		FormsModule,
		HttpClientModule,
		JwtModule.forRoot({ config: { tokenGetter: () => localStorage.getItem("auth_token") } }),
		RouterModule.forRoot(routes, { useHash: true }),
		BrowserAnimationsModule,
		NgxSpinnerModule
	],
	exports: [RouterModule,NgxSpinnerModule],
	bootstrap: [AppComponent],
	providers: [
		AuthService,
		HttpConfigService
	],
})
export class AppModule { }
