import { Component, OnInit } from "@angular/core";
import { CustomerDto, CustomerPayloadDto, CustomerSimDto, KioskDto } from "../../dto";
import { NgxSpinnerService } from "ngx-spinner";
import { HttpCustomerService } from "../../http-customer.service";
import { catchError } from "rxjs/operators";
import { AppToastService } from "src/app/components/toasts/app-toast.service";
import { faCalendar, faIdCard, faIdCardAlt, faIdCardClip, faMoneyBills, faSearch, faSimCard, faTimes } from "@fortawesome/free-solid-svg-icons";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbDateStruct, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CustomerIdCardModalComponent } from "./customer-id-modal/customer-id-modal.component";
import { CustomerMomoAccountModalComponent } from "./customer-momo-account-modal/customer-momo-account-modal.component";
import { HttpKioskService } from "../../http-kiosk.service";
import { CustomerSimsModalComponent } from "./customer-sims-modal/customer-sims-modal.component";
import { UrlUtil } from "src/app/utils/url-util";

@Component({
	selector: 'app-customer-list',
	templateUrl: './customer-list.component.html',
	styleUrls: ['./customer-list.component.scss']
})
export class CustomerListComponent implements OnInit {

	payload: CustomerPayloadDto[];
	fromModel: NgbDateStruct;
	toModel: NgbDateStruct;
	iccidModel: string;
	kioskModel: KioskDto;

	kiosks: KioskDto[];

	date=faCalendar;
	search=faSearch;
	clearSearch=faTimes;

	constructor(
		private httpService: HttpCustomerService,
		private httpKioskService: HttpKioskService,
		private spinner: NgxSpinnerService,
		private toastService: AppToastService,
		private router: Router,
		private modalService: NgbModal,
		private activatedRoute: ActivatedRoute) {}

	ngOnInit(): void {
		this.fromModel = UrlUtil.getParamAsNgbDate(this.activatedRoute, 'from');
		this.toModel = UrlUtil.getParamAsNgbDate(this.activatedRoute, 'to');	
		this.iccidModel = UrlUtil.getParam(this.activatedRoute, 'iccid');		
		const kioskId = UrlUtil.getParamAsNumber(this.activatedRoute, 'kiosk');
		if (kioskId !== undefined) {
			this.kioskModel = {
				id: kioskId
			};
		}

		this.spinner.show();
		this.httpKioskService.getAll()
		.pipe(
			catchError(error => {
				this.spinner.hide();
				console.log('Error fetching kiosks', error);
				this.toastService.showDanger({ content: 'Failed to fetch data: ' + (error.error ? error.error.error : error.message) });
				throw error;
			})
		)
		.subscribe(items => {
			this.kiosks = items;
			this.kioskModel = this.kiosks.filter(item => item.id === kioskId)[0];
			this.spinner.hide();
			this.fetchAllCustomers();
		});
	}

	private fetchAllCustomers() {
		let from = new Date();
		if (this.fromModel !== undefined) {
			from.setFullYear(this.fromModel.year);
			from.setMonth(this.fromModel.month - 1);
			from.setDate(this.fromModel.day);
		} else {
			from.setDate(from.getDate() - 2);
		}

		let to = new Date();
		if (this.toModel !== undefined) {
			to.setFullYear(this.toModel.year);
			to.setMonth(this.toModel.month - 1);
			to.setDate(this.toModel.day);
		}

		UrlUtil.setParams(this.router, {from: from, to: to, iccid: this.iccidModel, kiosk: this.kioskModel?.id});

		this.spinner.show();
		this.httpService.getAll(from, to, this.iccidModel, this.kioskModel?.id)
		.pipe(
			catchError(error => {
				this.spinner.hide();
				console.log('Error fetching customers', error);
				this.toastService.showDanger({ content: 'Failed to fetch data: ' + (error.error ? error.error.error : error.message) });
				throw error;
			})
		)
		.subscribe(items => {
			this.payload = items;
			this.spinner.hide();
		});
	}

	

	doSearch() {
		if (this.fromModel !== undefined && this.toModel === undefined) {
			this.toastService.showSecondary({ content: 'Please provide to date' });
			return;
		}
		if (this.toModel !== undefined && this.fromModel === undefined) {
			this.toastService.showSecondary({ content: 'Please provide from date' });
			return;
		}
		if (this.fromModel === undefined && this.toModel === undefined && this.iccidModel === undefined && this.kioskModel === undefined) {
			this.toastService.showSecondary({ content: 'Please capture at least one search field' });
			return;
		}
		
		this.fetchAllCustomers();
	}
}