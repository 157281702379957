import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AppToastService } from "src/app/components/toasts/app-toast.service";
import { KioskDto, KioskLocationDto, KioskMaintenanceLogDto, KioskMonitorStatusDto, PeripheralEnum } from "../../../dto";
import { faCheck, faHourglassStart, faLock, faUnlock, faBan, faEdit, faSearch, faTimes, faWrench, faClipboard, faCashRegister, faPrint, faSimCard, faBarcode, faBell, faBellConcierge, faBellSlash, faPlusCircle, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { UtilService } from "../../../util.service";
import { catchError, debounceTime } from "rxjs/operators";
import { NgxSpinnerService } from "ngx-spinner";
import { AuthService } from "src/app/service/auth.service";
import { HttpAlarmService } from "../../../http-alarm.service";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { MaintenanceAddLogModalComponent } from "./maintenance-add-log-modal/maintenance-add-log-modal.component";
import { Location } from "@angular/common";

export class MonitorStatus {
	private name: string;
	private peripheral: PeripheralEnum;
	private icon: IconDefinition;
	private status: string;
	private css: 'text-success' | 'text-danger' | 'text-warning' = 'text-success';

	constructor(name: string, peripheral: PeripheralEnum, icon: IconDefinition, status: string) {
		this.name = name;
		this.peripheral = peripheral;
		this.icon = icon;
		this.status = status;
		this.setCss();
	}
	private setCss() {
		switch (this.status) {
			case 'Severe':
				this.css = 'text-danger';
				break;
			case 'Problem':
				this.css = 'text-warning';
				break;
			case 'Ok':
				this.css = 'text-success';
				break;
		}
	}

	getName() {
		return this.name;
	}
	getPeripheral() {
		return this.peripheral;
	}
	getPeripheralString() {
		if (this.peripheral !== undefined) {
			return PeripheralEnum[this.peripheral];
		}
	}
	getIcon() {
		return this.icon;
	}
	getStatus() {
		return this.status;
	}
	
	setStatus(status: string) {
		this.status = status;
		this.setCss();
	}
}
@Component({
	selector: 'app-maintenance',
	templateUrl: './maintenance.component.html',
	styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent {
	
	@Input() kioskId: number;
	kiosk: KioskDto;

	alarms: KioskMonitorStatusDto[];
	currentAlarmLogs: KioskMonitorStatusDto[];
	monitorStatus: MonitorStatus[];

	add = faPlusCircle;
	maintenance = faWrench;
	details = faClipboard;
	new = faHourglassStart;
	blocked = faLock;
	verified = faCheck;
	disabled = faBan;
	kioskIcon = faCashRegister;
	printer = faPrint;
	dispenser = faSimCard;
	cash = faCashRegister;
	barcode = faBarcode;
	alarmIcon = faBell;
	back = faArrowLeft;

	peripheral = PeripheralEnum;

	util = UtilService;

	newPrinterStatusLog: KioskMaintenanceLogDto;
	displayType: 'SubComponent' | 'OwnComponent' = 'SubComponent';

	constructor(
		private location: Location,
		private httpService: HttpAlarmService, 
		private activeRoute: ActivatedRoute, 
		private spinner: NgxSpinnerService,
		private toastService: AppToastService,
		private modalService: NgbModal) {
	}

	ngOnInit(): void {
		this.fetchAlarm();
	}

	private fetchAlarm() {
		this.alarms = undefined;
		if (!this.kioskId) {
			this.displayType = 'OwnComponent';
			this.activeRoute.paramMap.subscribe(map => this.kioskId = +map.get('kioskId'));
		}
		this.spinner.show();
		this.httpService.getKioskAlarms(this.kioskId)
		.pipe(
			catchError(error => {
				this.spinner.hide();
				console.log('Error fetching alarm for kiosk', error);
				this.toastService.showDanger({ content: 'Failed to fetch data: ' + (error.error ? error.error.error : error.message) });
				this.spinner.hide();
				throw error;
			})
		)
		.subscribe(items => {
			if (items.length > 0) {
				this.alarms = items;
				this.alarms.forEach(item => item.showCurrentLogsOnly = true);
				this.kiosk = this.alarms[0].kiosk;

				this.configureMonitorStatus();

				this.alarms.forEach(item => {
					var monitor = this.monitorStatus.filter(monitor => monitor.getName() === item.monitor)[0];
					if (monitor) {
						monitor.setStatus(item.status);
						item.peripheral = monitor.getPeripheral();
					}
				});

				this.fetchMaintenanceLogs();
			}
			this.spinner.hide();
		});
	}

	private fetchMaintenanceLogs() {
		this.alarms.forEach(alarm =>{
			this.fetchMaintenanceLogForAlarm(alarm);
		});
	}

	private fetchMaintenanceLogForAlarm(alarm: KioskMonitorStatusDto) {
		this.spinner.hide();
		this.spinner.show();
		this.httpService.getMaintenanceLogs(this.kioskId, alarm.peripheral, (alarm.showCurrentLogsOnly ? alarm.id : 0))
		.pipe(
			catchError(error => {
				this.spinner.hide();
				console.log('Error fetching maintenance logs for kiosk', error);
				this.toastService.showDanger({ content: 'Failed to fetch data: ' + (error.error ? error.error.error : error.message) });
				this.spinner.hide();
				throw error;
			})
		)
		.subscribe(items => {
			alarm.logs = items;
			this.spinner.hide();
		});
	}

	private configureMonitorStatus() {
		this.monitorStatus = [];
		this.monitorStatus.push(new MonitorStatus('PrinterMonitor', PeripheralEnum.Printer, this.printer, 'Ok'));
		this.monitorStatus.push(new MonitorStatus('CardDispenserMonitor', PeripheralEnum.CardDispenser, this.dispenser, 'Ok'));
		this.monitorStatus.push(new MonitorStatus('CashboxMonitor', PeripheralEnum.Cashbox, this.cash, 'Ok'));
		this.monitorStatus.push(new MonitorStatus('BarcodeMonitor', PeripheralEnum.Barcode, this.barcode, 'Ok'));
	}

	getMonitorStatus(monitor: string) {
		var alarmMonitor = this.alarms.filter(item => item.monitor === monitor)[0];
		// console.log('alarmMonitor', alarmMonitor);
		if (!alarmMonitor) {
			return 'Ok';
		}

		return alarmMonitor.status;
	}

	getCardDispenserMonitorStatus() {
		var alarmMonitor = this.alarms.filter(item => item.monitor === 'CardDispenserMonitor')[0];
		if (!alarmMonitor) {
			return;
		}
		return alarmMonitor.status;
	}

	addLogEntry(peripheral: PeripheralEnum) {
		const modalRef = this.modalService.open(MaintenanceAddLogModalComponent, { windowClass: "modal-add-log" });
		modalRef.componentInstance.kiosk = this.kiosk;
		modalRef.componentInstance.peripheral = peripheral;

		modalRef.result.then((result) => {
			if (result === true) {
				this.fetchAlarm();
			}
		});
	}

	setAlarmLogVisibility(alarmId: number) {
		console.log('Switch log visibility for ', alarmId);
		var alarm = this.alarms.filter(item => item.id === alarmId)[0];
		this.fetchMaintenanceLogForAlarm(alarm);

	}

	goBack(): void {
		this.location.back();
	}

}