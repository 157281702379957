import { Component, OnInit } from "@angular/core";
import { CustomerPayloadDto, FingerprintTypeEnum, ImageDataDto, ImageTypeEnum } from "../../../dto";
import { faAddressCard, faArrowLeft, faCheck, faDriversLicense, faFingerprint, faImage, faImages, faMoneyBills, faPen, faPerson, faPortrait, faSign, faSignature, faSimCard, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Location } from "@angular/common";
import { ActivatedRoute } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { HttpCustomerService } from "../../../http-customer.service";
import { catchError } from "rxjs/operators";
import { AppToastService } from "src/app/components/toasts/app-toast.service";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";

export class ImageInfo {
	imageType: ImageTypeEnum;
	imageData: ImageDataDto;
}

@Component({
	selector: 'app-customer-view',
	templateUrl: './customer-view.component.html',
	styleUrls: ['./customer-view.component.scss']
})
export class CustomerViewComponent implements OnInit {
	customerId: number;
	payload: CustomerPayloadDto;
	
	back=faArrowLeft;
	person=faPerson;
	details=faPen;
	portraitIcon=faPortrait;
	idCard=faAddressCard;
	idFront=faDriversLicense;
	momo=faMoneyBills;
	simcard=faSimCard;
	biometrics=faFingerprint;
	additionalImages=faImages;
	signature=faSignature;
	tick=faCheck;
	cross=faTimes;

	ImageTypes = ImageTypeEnum;
	FingerprintTypes = FingerprintTypeEnum;

	constructor(
		private location: Location, 
		private activeRoute: ActivatedRoute,
		private spinner: NgxSpinnerService,
		private httpService: HttpCustomerService,
		private toastService: AppToastService,
		private sanitizer: DomSanitizer,
		) {}

	ngOnInit(): void {
		this.fetchCustomer();
	}

	private fetchCustomer() {
		this.activeRoute.paramMap.subscribe(map => this.customerId = +map.get('customerId'));
		this.spinner.show();
		this.httpService.getCustomer(this.customerId)
		.pipe(
			catchError(error => {
				this.spinner.hide();
				console.log('Error fetching customer', error);
				this.toastService.showDanger({ content: 'Failed to fetch customer: ' + (error.error ? error.error.error : error.message) });
				throw error;
			})
		)
		.subscribe(data => {
			this.spinner.hide();
			if (!data) {
				this.toastService.showDanger({ content: 'Failed to fetch customer' });
				return;
			}
			this.payload = data;
			// this.loadImages();
		});
	}
	getImage(ordinal: number): ImageDataDto[] {
		var type = Object.values(ImageTypeEnum)[ordinal];
		return this.payload.images.filter(item => item.imageType === type)[0]?.images;
	}

	hasAdditionalImages() {
		return this.getImage(ImageTypeEnum.DocumentGhostPortrait) !== undefined 
			|| this.getImage(ImageTypeEnum.DocumentPortrait) !== undefined 
			|| this.getImage(ImageTypeEnum.DocumentBarcode) !== undefined 
			|| this.getImage(ImageTypeEnum.DocumentSignature) !== undefined 
	}
	getAdditionalImages(): ImageInfo[] {
		const images = new Array<ImageInfo>();
		this.getImage(ImageTypeEnum.DocumentGhostPortrait)?.forEach(item => {
			images.push({imageType: ImageTypeEnum.DocumentGhostPortrait, imageData: item});
		});
		this.getImage(ImageTypeEnum.DocumentPortrait)?.forEach(item => {
			images.push({imageType: ImageTypeEnum.DocumentPortrait, imageData: item});
		});
		this.getImage(ImageTypeEnum.DocumentBarcode)?.forEach(item => {
			images.push({imageType: ImageTypeEnum.DocumentBarcode, imageData: item});
		});
		this.getImage(ImageTypeEnum.DocumentSignature)?.forEach(item => {
			images.push({imageType: ImageTypeEnum.DocumentSignature, imageData: item});
		});
		return images;
	}

	getImageType(ordinal: number): string {
		// console.log('getImageType - input:', input);
		// var ordinal = +ImageTypeEnum[input];
		// console.log('getImageType - ordinal:', ordinal);
		switch (ordinal) {
			case ImageTypeEnum.Document:
				return 'Document';
			case ImageTypeEnum.DocumentBarcode:
				return 'Barcode';
			case ImageTypeEnum.DocumentGhostPortrait:
				return 'Ghost Portrait';
			case ImageTypeEnum.DocumentPortrait:
				return 'Document Portrait';
			case ImageTypeEnum.DocumentSignature:
				return 'Document Signature';
			case ImageTypeEnum.Portrait:
				return 'Portrait';
			default:
				return 'Unknown';
		}
	}

	getFingerprintType(input: FingerprintTypeEnum): string {
		// console.log('getFingerprintType - input:', input);
		var ordinal = +FingerprintTypeEnum[input];
		// console.log('getFingerprintType - ordinal:', ordinal);
		switch (ordinal) {
			case FingerprintTypeEnum.Lt:
				return 'Left thumb';
			case FingerprintTypeEnum.Li:
				return 'Left index';
			case FingerprintTypeEnum.Lm:
				return 'Left middle';
			case FingerprintTypeEnum.Lr:
				return 'Left ring';
			case FingerprintTypeEnum.Lp:
				return 'Left pinky';
			case FingerprintTypeEnum.Rt:
				return 'Right thumb';
			case FingerprintTypeEnum.Ri:
				return 'Right index';
			case FingerprintTypeEnum.Rm:
				return 'Right middle';
			case FingerprintTypeEnum.Rr:
				return 'Right ring';
			case FingerprintTypeEnum.Rp:
				return 'Right pinky';
		}
	}

	goBack(): void {
		this.location.back()
	}
	
}