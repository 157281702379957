<ng-container *ngIf="kiosk && componentInit">
	<div class="row d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center m-2 mb-0 pb-0">
		<div class="col">
			{{util.getKioskDetails(kiosk)}}
		</div>
	</div>
	<div class="row">
		<div class="col">
			<div class="card m-3 shadow-lg bg-white rounded stats">
				<div class="card-header"><fa-icon [icon]="gears" class="text-secondary"></fa-icon>&nbsp;Component Summary</div>
				<div class="card-body">
					<table class="table table-borderless text-center">
						<thead>
							<tr>
								<th><fa-icon [icon]="serialPorts" class="text-primary fs-4"></fa-icon><br/>Ports</th>
								<th><fa-icon [icon]="serialDevices" class="text-primary fs-4"></fa-icon><br/>Serial</th>
								<th><fa-icon [icon]="usbDevices" class="text-primary fs-4"></fa-icon><br/>USB</th>
								<th><fa-icon [icon]="usbToSerialDevices" class="text-primary fs-4"></fa-icon><br/>USB - Serial</th>
								<th><fa-icon [icon]="total" class="text-primary fs-4"></fa-icon><br/>Total</th>
								<!-- <th>Avg Seen</th> -->
								<!-- <th>Latest Seen</th> -->
								<th><fa-icon [icon]="lastSeen" class="text-primary fs-4"></fa-icon><br/>Last Seen</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>{{serialPortComponents?.length}}</td>
								<td>{{serialDeviceComponents?.length}}</td>
								<td>{{usbDeviceComponents?.length}}</td>
								<td>{{usbToSerialDeviceComponents?.length}}</td>
								<td>{{totalComponentsCount}}</td>
								<!-- <td *ngIf="avg" class="small">{{avg | timeago:true}}</td> -->
								<td *ngIf="maxDate" class="small">{{maxDate | timeago:true}}</td>
								<!-- <td *ngIf="minDate" class="small">{{minDate | timeago:true}}</td> -->
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col">
			<app-kiosk-component-init [kioskData]="kiosk"></app-kiosk-component-init>
		</div>
	</div>
	<div class="row">
		<div class="col">
			<div class="card m-3 shadow-lg bg-white rounded">
				<div class="card-header p-3">
					<fa-icon [icon]="serialPorts" class="text-secondary"></fa-icon>&nbsp;Serial Ports
				</div>
				<div class="card-body table-responsive">
					<table *ngIf="serialPortComponents" class="table table-hover">
						<thead>
							<tr>
								<th>Port</th>
								<th>Last Seen</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let comp of serialPortComponents">
								<td>{{comp.component}}</td>
								<td>{{comp.updatedAt | timeago:true}}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
		<div class="col">
			<div class="card m-3 shadow-lg bg-white rounded">
				<div class="card-header p-3">
					<fa-icon [icon]="serialDevices" class="text-secondary"></fa-icon>&nbsp;Serial Devices
				</div>
				<div class="card-body table-responsive">
					<table *ngIf="serialDeviceComponents" class="table table-hover">
						<thead>
							<tr>
								<th>Device</th>
								<th>Last Seen</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let comp of serialDeviceComponents">
								<td>{{comp.component}}</td>
								<td>{{comp.updatedAt | timeago:true}}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col">
			<div class="card m-3 shadow-lg bg-white rounded">
				<div class="card-header p-3">
					<fa-icon [icon]="usbDevices" class="text-secondary"></fa-icon>&nbsp;USB Devices
				</div>
				<div class="card-body table-responsive">
					<table *ngIf="usbDeviceComponents" class="table table-hover">
						<thead>
							<tr>
								<th>Device</th>
								<th>Last Seen</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let comp of usbDeviceComponents">
								<td>{{comp.component}}</td>
								<td>{{comp.updatedAt | timeago:true}}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
		<div class="col">
			<div class="card m-3 shadow-lg bg-white rounded">
				<div class="card-header p-3">
					<fa-icon [icon]="usbToSerialDevices" class="text-secondary"></fa-icon>&nbsp;USB to Serial Devices
				</div>
				<div class="card-body table-responsive">
					<table *ngIf="usbToSerialDeviceComponents" class="table table-hover">
						<thead>
							<tr>
								<th>Device</th>
								<th>Last Seen</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let comp of usbToSerialDeviceComponents">
								<td>{{comp.component}}</td>
								<td>{{comp.updatedAt | timeago:true}}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</ng-container>