import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { HttpVideoService } from "../../../http-video.service";
import { AppToastService } from "src/app/components/toasts/app-toast.service";
import { NgxSpinnerService } from "ngx-spinner";
import { KioskDto, KioskVideoDto, KioskVideoLinkDto } from "../../../dto";
import { catchError } from "rxjs/operators";
import { faArrowLeft, faCheck, faCross, faLink, faPlusCircle, faRefresh, faTimes, faVideo } from "@fortawesome/free-solid-svg-icons";
import { UtilService } from "../../../util.service";
import { Location } from "@angular/common";
import { HttpKioskService } from "../../../http-kiosk.service";
import { forkJoin } from "rxjs";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { VideoPlayerComponent } from "../video-player/video-player.component";
import { AuthService } from "src/app/service/auth.service";
import { ConfirmModalComponent } from "../../../components/confirm-modal/confirm-modal.component";

@Component({
	selector: 'app-link-video',
	templateUrl: './link-video.component.html',
	styleUrls: ['./link-video.component.scss']
})
export class LinkVideoComponent implements OnInit {

	add = faPlusCircle;
	link=faLink;
	back=faArrowLeft;
	videoIcon=faVideo;
	all=faCheck;
	none=faTimes;
	resetKiosk=faRefresh;

	videoId: number;
	videoLinks: KioskVideoLinkDto[];
	kiosks: KioskDto[];
	kiosksOriginal: KioskDto[];
	video: KioskVideoDto;

	util = UtilService;

	constructor(
		private location: Location, 
		private activeRoute: ActivatedRoute,
		private httpService: HttpVideoService,
		private httpKioskService: HttpKioskService,
		private toastService: AppToastService,
		private spinner: NgxSpinnerService,
		private modalService: NgbModal
	){}

	ngOnInit(): void {
		this.activeRoute.paramMap.subscribe(map => {
			this.videoId = +map.get('videoId');
			if (this.videoId) {
				this.fetchData();
			}
		});
	}

	private fetchData() {
		this.spinner.show();
		forkJoin(
			[
				this.httpService.getLinks(this.videoId),
				this.httpKioskService.getAllVerified(),
				this.httpService.getKioskVideo(this.videoId),
				this.httpService.getThumbnail(this.videoId),
			]
		)
		.pipe(
			catchError(error => {
				this.spinner.hide();
				console.log('Error fetching video mapping', error);
				this.toastService.showDanger({ content: 'Failed to fetch video mapping: ' + (error.error ? error.error.error : error.message) });
				throw error;
			})
		)
		.subscribe(data => {
			this.videoLinks = data[0];
			this.kiosks = data[1];
			this.video = data[2];
			this.createImageFromBlob(data[3], this.video);

			this.kiosks.forEach(kiosk => {
				kiosk.linkedToVideo = this.videoLinks.filter(item => item.kiosk.id === kiosk.id)[0] !== undefined;
			});
			this.kiosksOriginal = this.kiosks.map(item => {
				return Object.assign({}, item)
			});
			this.spinner.hide();
		});
	}

	goBack(): void {
		this.location.back()
	}

	createImageFromBlob(image: Blob, video: KioskVideoDto) {
		var reader = new FileReader();
		reader.addEventListener("load", () => {
			video.thumbnail = reader.result;
		}, false);
	 
		if (image) {
		   reader.readAsDataURL(image);
		}
	}

	getSize(videoSize: number) {
		return UtilService.getVideoSize(videoSize);
	}
	getDuration(videoDuration: number) {
		return UtilService.getVideoDuration(videoDuration);
	}

	watchVideo(video: KioskVideoDto) {
		const modalRef = this.modalService.open(VideoPlayerComponent, { windowClass: "modal-video-player", size: 'lg'  });
		modalRef.componentInstance.videoData = video;

		modalRef.result.then((result) => {
			if (result === true) {
				// this.onActionSuccess.emit({action: 'Edit'});
			}
		});
	}

	saveLinks() {
		const modalRef = this.modalService.open(ConfirmModalComponent, { windowClass: "modal-confirm" });
		modalRef.componentInstance.title = 'Link Video to Kiosks';
		modalRef.componentInstance.text = 'Please confirm you wish to add / delete the video from the selected kiosk(s).';

		modalRef.result.then((result) => {
			if (result === true) {
				this.doSaveLinks();
			}
		});
	}

	private doSaveLinks() {
		var links: KioskVideoLinkDto[] = [];
		this.kiosks.forEach(kiosk => {
			if (kiosk.linkedToVideo === true) {
				links.push({
					kiosk: kiosk
				});
			}
		});

		this.httpService.saveLinks(this.video.id, links)
		.pipe(
			catchError(error => {
				this.spinner.hide();
				console.log('Error saving video link', error);
				this.toastService.showDanger({ content: 'Failed to save video link: ' + (error.error ? error.error.error : error.message) });
				throw error;
			})
		)
		.subscribe(data => {
			this.spinner.hide();
			if (!data.responseStatus) {
				this.toastService.showDanger({ content: 'Failed to save video links: ' + data.responseStatusMessage });
				return;
			}

			this.toastService.showSuccess({ content: 'Video linked successfully'});
			this.fetchData();
		});
	}

	selectAll() {
		this.kiosks.forEach(kiosk => {
			kiosk.linkedToVideo = true;
		});
	}
	selectNone() {
		this.kiosks.forEach(kiosk => {
			kiosk.linkedToVideo = false;
		});
	}
	reset() {
		this.kiosks = this.kiosksOriginal.map(item => {
			return Object.assign({}, item);
		});
		
	}
}