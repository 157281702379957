<div class="row d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
	<div class="col">	
		<div class="btn-group" role="group">
			<button class="btn btn-outline-secondary" 
				(click)="addAdmin()" 
				type="button">
				Add <fa-icon [icon]="add" size="lg"></fa-icon>
			</button>
		</div>			
	</div>
</div>
<div class="pt-3 pb-2 mb-3">
	<div class="shadow-lg p-3 mb-5 bg-white rounded table-responsive">
		<table *ngIf="admins" class="table table-hover">
			<thead>
				<tr>
					<th>ID</th>
					<th>Created At</th>
					<th>Username</th>
					<th>Status</th>
					<th>Name</th>
					<th>Surname</th>
					<th>MSISDN</th>
					<th>Email</th>
					<th>Roles</th>
					<th>Action</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let admin of admins">
					<td scope="row">{{admin.id}}</td>
					<td>{{admin.createdAt | date: 'yyyy-MM-dd HH:mm:ss'}}</td> 
					<td>{{admin.username}}</td>
					<td>
						<div [ngSwitch]="admin.userStatus">
							<fa-icon [icon]="verified" *ngSwitchCase="'Verified'" class="text-primary" title="Verified"></fa-icon>
							<fa-icon [icon]="new" *ngSwitchCase="'New'" class="text-success" title="New"></fa-icon>
							<fa-icon [icon]="blocked" *ngSwitchCase="'Blocked'" class="text-danger" title="Blocked"></fa-icon>
							<fa-icon [icon]="disabled" *ngSwitchCase="'Disabled'" class="text-danger" title="Disabled"></fa-icon>	
						</div>
					</td>
					<td>{{admin.name}}</td>
					<td>{{admin.surname}}</td>
					<td>{{admin.msisdn}}</td>
					<td>{{admin.email}}</td>
					<td><ng-container [ngTemplateOutlet]="roles" 
						[ngTemplateOutletContext]="{roles:admin.roles}"></ng-container></td>
					<td>
						<div class="btn-group" role="group">
							<button class="btn btn-outline-secondary" 
								(click)="editAdmin(admin); $event.stopPropagation()" 
								type="button">
								Edit <fa-icon [icon]="edit" size="lg"></fa-icon>
							</button>
							<button class="btn btn-outline-secondary" 
								(click)="editAdminRoles(admin); $event.stopPropagation()" 
								type="button">
								Roles <fa-icon [icon]="editRoles" size="lg"></fa-icon>
							</button>
							<button *ngIf="admin.userStatus !== 'Disabled'" 
								class="btn btn-outline-secondary" 
								(click)="disableAdmin(admin); $event.stopPropagation()" type="button">
								Disable <fa-icon [icon]="disabled" size="lg"></fa-icon>
							</button>
							<button *ngIf="admin.userStatus === 'Blocked'" 
								class="btn btn-outline-secondary" 
								(click)="unblockAdmin(admin); $event.stopPropagation()" 
								type="button">
								Unblock <fa-icon [icon]="unlock" size="lg"></fa-icon>
							</button>
							<button *ngIf="admin.userStatus === 'Disabled'" 
								class="btn btn-outline-secondary" 
								(click)="reEnableAdmin(admin); $event.stopPropagation()" type="button">
								Re-enable <fa-icon [icon]="unlock" size="lg"></fa-icon>
							</button>
						</div>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
	
</div>

<ng-template #roles let-roles='roles'>
	<table class="table">
		<tr *ngFor="let role of roles"><td>{{role.roleName}}</td></tr>
	</table>
</ng-template>
