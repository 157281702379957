import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { KioskLocationDto } from "../../dto";
import { faCheck, faPerson, faHourglassStart, faUnlock, faBan, faEdit, faSearch, faPlusCircle, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { HttpLocationService } from "../../http-location.service";
import { LocationActionService } from "../../location-action.service";
import { Subscription } from "rxjs";
import { catchError } from "rxjs/operators";
import { AppToastService } from "src/app/components/toasts/app-toast.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Location } from "@angular/common";

@Component({
	selector: 'app-location-list',
	templateUrl: './location-list.component.html',
	styleUrls: ['./location-list.component.scss']
})
export class LocationListComponent implements OnInit, OnDestroy {

	locations: KioskLocationDto[];

	add = faPlusCircle;
	edit = faEdit;
	verified = faCheck;
	disabled = faBan;
	unlock = faUnlock;
	view = faSearch;

	subscription = new Subscription();

	constructor(
		private httpService: HttpLocationService, 
		private locationAction: LocationActionService,
		private router: Router,
		private toastService: AppToastService,
		private spinner: NgxSpinnerService) {
			this.subscription = this.locationAction.onActionSuccess.subscribe (item => {
				switch (item.action) {
					case "Edit":
					case "Disable":
					case "ReEnable":
						this.fetchAllLocations();
						break;
				}
			});
	}

	ngOnInit(): void {
		this.fetchAllLocations();
	}

	private fetchAllLocations() {
		this.spinner.show();
		this.httpService.getAllLocations()
		.pipe(
			catchError(error => {
				this.spinner.hide();
				console.log('Error fetching locations', error);
				this.toastService.showDanger({ content: 'Failed to fetch data: ' + (error.error ? error.error.error : error.message) });
				throw error;
			})
		)
		.subscribe(items => {
			this.locations = items;
			this.spinner.hide();
		});
	}

	addLocation() {
		this.router.navigate(['/kiosk','add-location']);
	}

	viewLocation(location: KioskLocationDto) {
		this.router.navigate(['/kiosk','view-location', location.id]);
	}

	editLocation(location: KioskLocationDto) {
		this.router.navigate(['/kiosk','edit-location', location.id]);
	}

	disableLocation(location: KioskLocationDto) {
		this.locationAction.disableLocation(location);
	}

	reEnableLocation(location: KioskLocationDto) {
		this.locationAction.reEnableLocation(location);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

}