import { Component, Input } from "@angular/core";
import { CustomerDto, CustomerPayloadDto, CustomerSimDto } from "../../dto";
import { faIdCard, faMoneyBills, faSearch, faSimCard } from "@fortawesome/free-solid-svg-icons";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ActivatedRoute } from "@angular/router";
import { CustomerSimsModalComponent } from "./customer-sims-modal/customer-sims-modal.component";
import { CustomerIdCardModalComponent } from "./customer-id-modal/customer-id-modal.component";
import { CustomerMomoAccountModalComponent } from "./customer-momo-account-modal/customer-momo-account-modal.component";

@Component({
	selector: 'app-customer-table-data',
	templateUrl: './customer-table-data.component.html',
	styleUrls: ['./customer-table-data.component.scss']
})
export class CustomerTableDataComponent {

	@Input() payload: CustomerPayloadDto[];

	constructor(
		private modalService: NgbModal,
		private activatedRoute: ActivatedRoute) {}

	view = faSearch;
	idCard = faIdCard;
	momo = faMoneyBills;
	simCard=faSimCard;

	viewSims(sims: CustomerSimDto) {
		const modalRef = this.modalService.open(CustomerSimsModalComponent, { windowClass: "modal-customer-sims" });
		modalRef.componentInstance.sims = sims;
	}
	viewCustomerId(customer: CustomerDto) {
		const modalRef = this.modalService.open(CustomerIdCardModalComponent, { windowClass: "modal-customer-id-card" });
		modalRef.componentInstance.customer = customer;
	}
	viewMomoAccount(customer: CustomerDto) {
		const modalRef = this.modalService.open(CustomerMomoAccountModalComponent, { windowClass: "modal-customer-momo-account" });
		modalRef.componentInstance.customer = customer;
	}
}