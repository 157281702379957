import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AppToastService } from "src/app/components/toasts/app-toast.service";
import { HttpKioskService } from "../../../../http-kiosk.service";
import { KioskComponentDto, KioskComponentInitDto, KioskDto } from "../../../../dto";
import { ActivatedRoute } from "@angular/router";
import { catchError } from "rxjs/operators";
import { faArrowLeft, faVectorSquare, faBezierCurve, faSitemap, faWaveSquare, faCalculator, faStopwatch, faCheck, faCross, faCancel, faGear, faGears } from '@fortawesome/free-solid-svg-icons';
import { UtilService } from "../../../../util.service";
import { HttpComponentsService } from "src/app/modules/kiosk/http-components.service";
import { NgxSpinnerService } from "ngx-spinner";
import { forkJoin } from "rxjs";

@Component({
	selector: 'app-kiosk-components',
	templateUrl: './kiosk-components.component.html',
	styleUrls: ['./kiosk-components.component.scss']
})
export class KioskComponentsComponent {
	kiosk: KioskDto;
	@Output() reloadKiosk: EventEmitter<any> = new EventEmitter(); 

	components: KioskComponentDto[];
	componentInit: KioskComponentInitDto;

	gears = faGears;
	back = faArrowLeft;
	serialPorts=faVectorSquare;
	serialDevices=faSitemap;
	usbDevices=faBezierCurve;
	usbToSerialDevices=faWaveSquare;
	total=faCalculator;
	lastSeen=faStopwatch;
	ok=faCheck;
	notOk=faCancel;

	avg: number;
	maxDate: number;
	minDate: number;

	util = UtilService;

	constructor(
		private httpService: HttpComponentsService,
		private toastService: AppToastService,
		private spinner: NgxSpinnerService,) {
	}

	@Input() 
	set kioskData(kiosk: KioskDto) {
		if (!kiosk) {
			return;
		}
		this.kiosk = kiosk;
		this.fetchKioskComponents();
	}

	private fetchKioskComponents() {
		this.spinner.show();
		forkJoin([this.httpService.getKioskComponents(this.kiosk.id), this.httpService.getKioskComponentInit(this.kiosk.id)])
		.pipe(
			catchError(error => {
				this.spinner.hide();
				console.log('Error fetching data', error);
				this.toastService.showDanger({ content: 'Failed to fetch data: ' + (error.error ? error.error.error : error.message) });
				throw error;
			})
		)
		.subscribe(item => {
			this.spinner.hide();
			this.components = item[0];
			this.componentInit = item[1];

			var total = 0;
			const dates = [];
			this.components.forEach(item => {
				const d = new Date(item.updatedAt);
				dates.push(d.getTime());
				total += d.getTime();
			});
			
			this.avg = total / dates.length;
			
			this.maxDate=Math.max(...dates);
			this.minDate=Math.min(...dates);
		});
	}

	get serialPortComponents() {
		if (!this.components) {
			return;
		}
		return this.components.filter(item => item.type === 'SerialPort');
	}

	get serialDeviceComponents() {
		if (!this.components) {
			return;
		}
		return this.components.filter(item => item.type === 'SerialDevice');
	}

	get usbDeviceComponents() {
		if (!this.components) {
			return;
		}
		return this.components.filter(item => item.type === 'UsbDevice');
	}

	get usbToSerialDeviceComponents() {
		if (!this.components) {
			return;
		}
		return this.components.filter(item => item.type === 'UsbToSerialDevice');
	}

	get totalComponentsCount() {
		return this.serialPortComponents?.length + this.serialDeviceComponents?.length + 
			this.usbDeviceComponents?.length + this.usbToSerialDeviceComponents?.length;
	}

	getIcon(init: boolean) {
		return init === true ? this.ok : this.notOk;
	}

	getIconClass(init: boolean) {
		return init === true ? 'text-info' : 'text-danger';
	}
	
}