import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { KioskVideoDto } from "../../dto";
import { faBan, faEdit, faSearch, faPlusCircle, faVideo, faLink } from '@fortawesome/free-solid-svg-icons';
import { catchError } from "rxjs/operators";
import { AppToastService } from "src/app/components/toasts/app-toast.service";
import { NgxSpinnerService } from "ngx-spinner";
import { HttpVideoService } from "../../http-video.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { VideoPlayerComponent } from "./video-player/video-player.component";
import { ConfirmModalComponent } from "../../components/confirm-modal/confirm-modal.component";
import { UtilService } from "../../util.service";

@Component({
	selector: 'app-video-management',
	templateUrl: './video-management.component.html',
	styleUrls: ['./video-management.component.scss']
})
export class VideoManagementComponent implements OnInit {

	videos: KioskVideoDto[];

	add = faPlusCircle;
	disabled = faBan;
	edit = faEdit;
	link = faLink;
	watch = faVideo;
	view = faSearch;
	
	constructor(
		private httpService: HttpVideoService, 
		private router: Router,
		private spinner: NgxSpinnerService,
		private toastService: AppToastService,
		private modalService: NgbModal,) {}

	ngOnInit(): void {
		this.fetchAllVideos();
	}

	private fetchAllVideos() {
		this.spinner.show();
		this.httpService.getAll()
		.pipe(
			catchError(error => {
				this.spinner.hide();
				console.log('Error fetching admins', error);
				this.toastService.showDanger({ content: 'Failed to fetch data: ' + (error.error ? error.error.error : error.message) });
				throw error;
			})
		)
		.subscribe(items => {
			this.videos = items;
			this.videos.forEach(item =>{
				this.httpService.getThumbnail(item.id)
				.subscribe(data => {
					this.createImageFromBlob(data, item);
				});
			});
			this.spinner.hide();
		});
	}

	createImageFromBlob(image: Blob, video: KioskVideoDto) {
		var reader = new FileReader();
		reader.addEventListener("load", () => {
			video.thumbnail = reader.result;
		}, false);
	 
		if (image) {
		   reader.readAsDataURL(image);
		}
	}

	getSize(videoSize: number) {
		return UtilService.getVideoSize(videoSize);
	}

	getDuration(videoDuration: number) {
		return UtilService.getVideoDuration(videoDuration);
	}

	addVideo() {
		this.router.navigate(['/kiosk','upload-video']);
	}

	editVideo(video: KioskVideoDto) {
		this.router.navigate(['/kiosk','edit-video', video.id]);
	}

	watchVideo(video: KioskVideoDto) {
		const modalRef = this.modalService.open(VideoPlayerComponent, { windowClass: "modal-video-player", size: 'lg'  });
		modalRef.componentInstance.videoData = video;

		modalRef.result.then((result) => {
			if (result === true) {
				// this.onActionSuccess.emit({action: 'Edit'});
			}
		});
	}

	deleteVideo(video: KioskVideoDto) {
		const modalRef = this.modalService.open(ConfirmModalComponent, { windowClass: "modal-confirm" });
		modalRef.componentInstance.title = 'Delete Video';
		modalRef.componentInstance.text = 'Please confirm you wish to delete the selected video.';
		modalRef.componentInstance.additionalText = 'NOTE: Any kiosks that have the video downloaded, will delete it when they next sync with the system';

		modalRef.result.then((result) => {
			if (result === true) {
				this.doDeleteVideo(video);
			}
		});
	}
	private doDeleteVideo(video: KioskVideoDto) {
		this.spinner.show();
		this.httpService.deleteVideo(video.id)
		.pipe(
			catchError(error => {
				this.spinner.hide();
				console.log('Error deleting video', error);
				this.toastService.showDanger({ content: 'Failed to delete video: ' + (error.error ? error.error.error : error.message) });
				throw error;
			})
		)
		.subscribe(resp => {
			this.spinner.hide();
			if (!resp.responseStatus) {
				this.toastService.showDanger({ content: 'Failed to delete video: ' + resp.responseStatusMessage });
				return;
			}
			this.toastService.showSuccess({ content: 'Video deleted successfully' });
			this.fetchAllVideos();
		});
	}


	linkVideo(video: KioskVideoDto) {
		this.router.navigate(['/kiosk','link-video', video.id]);
	}
}