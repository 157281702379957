import { Subscription } from 'rxjs';
import { AppToastService } from './../toasts/app-toast.service';
import { AuthService } from './../../service/auth.service';
import { Component, OnInit, ViewChild, TemplateRef, OnDestroy } from '@angular/core';
import { faHourglassEnd, faHourglassHalf, faHourglassStart } from '@fortawesome/free-solid-svg-icons';

@Component({
	selector: 'app-session-timeout-notification',
	templateUrl: './session-timeout-notification.component.html',
	styleUrls: ['./session-timeout-notification.component.scss']
})
export class SessionTimeoutNotificationComponent implements OnInit, OnDestroy {
	faHourglassEnd = faHourglassEnd;
	faHourglassHalf = faHourglassHalf;
	faHourglassStart = faHourglassStart;

	@ViewChild('extendModal') extendModal: TemplateRef<any>;
	timeRemaining = 1000000;
	totalTime = 60;
	toastReference: number;
	toastState: 'info' | 'warn' | 'danger';
	subscriptions = new Subscription();

	constructor(public authService: AuthService, private toastService: AppToastService) { }

	ngOnInit(): void {
		this.subscriptions.add(this.authService.timeToExpire$.subscribe(item => {
			if (item === 'reset') {
				if (this.toastReference) {
					this.toastService.removeWithReference(this.toastReference);
				}
				return;
			}
			const toast = {
				content: this.extendModal,
				delay: item * 1000,
				unique: 'session-timeout'
			};

			if (this.timeRemaining >= this.totalTime && item < this.totalTime) {
				this.toastState = 'info';
				this.toastReference = this.toastService.showInfo(toast);
			}
			if (this.timeRemaining >= Math.round(this.totalTime / 2) && item < Math.round(this.totalTime / 2)) {
				this.toastState = 'warn';
				this.toastReference = this.toastService.showWarning(toast);
			}
			if (this.timeRemaining >= 10 && item < 10) {
				this.toastState = 'danger';
				this.toastReference = this.toastService.showDanger(toast);
			}
			this.timeRemaining = item;
		}));
	}
	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}
	extendSession() {
		this.authService.refreshToken().subscribe();
		this.toastService.removeWithReference(this.toastReference);
	}
}
