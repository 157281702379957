<ng-template #extendModal>
	<div class="btn " [ngClass]="{'btn-info': toastState == 'info', 'btn-warning': toastState == 'warn', 'btn-danger': toastState == 'danger'}"    (click)="extendSession()">
		<ngb-progressbar [value]="totalTime-timeRemaining" [max]="totalTime" type="dark" height="0.2em"></ngb-progressbar>
		<div class="row">
			<h3 class="col-1" style="padding-top:0.1em">
				<fa-icon [icon]="faHourglassStart"	*ngIf="timeRemaining % 3 == 0" class="text-light" size="lg"></fa-icon>
				<fa-icon [icon]="faHourglassHalf"	*ngIf="timeRemaining % 3 == 1" class="text-light" size="lg"></fa-icon>
				<fa-icon [icon]="faHourglassEnd"	*ngIf="timeRemaining % 3 == 2" class="text-light" size="lg"></fa-icon>
			</h3>
			<div class="col">
				Your session will expire in {{timeRemaining}}, click here to extend session...
			</div>
		</div>
	</div>
</ng-template>
