import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { faArrowLeft, faCalendar, faQuestionCircle, faUpload, faVideo } from "@fortawesome/free-solid-svg-icons";
import { NgxSpinnerService } from "ngx-spinner";
import { AppToastService } from "src/app/components/toasts/app-toast.service";
import { KioskVideoDto } from "../../../dto";
import { HttpConfigService } from "src/app/service/http-config.service";
import { HttpVideoService } from "../../../http-video.service";
import { catchError } from "rxjs/operators";
import { NgbDate, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { ActivatedRoute } from "@angular/router";
import { UtilService } from "../../../util.service";
import { Location } from "@angular/common";

@Component({
	selector: 'app-upload-video',
	templateUrl: './upload-video.component.html',
	styleUrls: ['./upload-video.component.scss']
})
export class UploadVideoComponent implements OnInit {

	back=faArrowLeft;
	uploadIcon=faUpload;
	date=faCalendar;
	tooltip=faQuestionCircle;
	newVideo=faVideo;

	file: File;
	fileUrl: SafeUrl;
	duration: number;
	durationFriendly: string;
	thumbnail: File;
	thumbnailUrl: SafeUrl;

	enabledAt: NgbDateStruct;
	disabledAt: NgbDateStruct;

	videoId: number;
	kioskVideo: KioskVideoDto = {};

	targetAgeList: string[];
	targetGenderList: string[];
	targetRaceList: string[];

	mode: 'add' | 'edit' = 'add';

	constructor(
		private location: Location, 
		private activeRoute: ActivatedRoute,
		private httpService: HttpVideoService,
		private configService: HttpConfigService,
		private toastService: AppToastService,
		private spinner: NgxSpinnerService,
		private sanitizer: DomSanitizer) {}

	ngOnInit(): void {
		this.targetAgeList = this.configService.getVideoTargetAge();	
		this.targetGenderList = this.configService.getVideoTargetGender();	
		this.targetRaceList = this.configService.getVideoTargetRace();	
		this.activeRoute.paramMap.subscribe(map => {
			this.videoId = +map.get('videoId');
			if (this.videoId) {
				this.fetchVideo();
			}
		});
	}

	goBack(): void {
		this.location.back()
	}

	private fetchVideo() {
		this.spinner.show();
		this.httpService.getKioskVideo(this.videoId)
		.pipe(
			catchError(error => {
				this.spinner.hide();
				console.log('Error fetching video', error);
				this.toastService.showDanger({ content: 'Failed to fetch video: ' + (error.error ? error.error.error : error.message) });
				throw error;
			})
		)
		.subscribe(data => {
			this.mode='edit';
			this.kioskVideo = data;
			
			if (this.kioskVideo.enabledAt) {
				var enabledAt = new Date(this.kioskVideo.enabledAt);
				this.enabledAt = {
					year: enabledAt.getFullYear(), 
					month: enabledAt.getMonth()+1,
					day: enabledAt.getDate()
				}
			}
			if (this.kioskVideo.disabledAt) {
				var disabledAt = new Date(this.kioskVideo.disabledAt);
				this.disabledAt = {
					year: disabledAt.getFullYear(), 
					month: disabledAt.getMonth()+1,
					day: disabledAt.getDate()
				}
			}
			this.spinner.hide();
		});
	}

	onFileUpload(event: any) {
		this.onFileSelected([event.target.files[0]]);
	}

	onFileSelected(files: File[]) {
		console.log('file:', files);
		if (files.length > 1) {
			this.toastService.showSecondary({ content: 'Please upload only 1 video at a time' });
			return;
		}
		var file = files[0];
		if (!file.type.toLowerCase().startsWith('video/')) {
			this.toastService.showSecondary({ content: 'Please upload file in video format' });
			return;
		}
		this.resetFile();

		this.kioskVideo.videoName = file.name;
		this.kioskVideo.videoFormat = file.type;
		this.kioskVideo.videoSize = file.size;

		this.file = file;
		this.fileUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(this.file));
    }

	private reset() {
		this.duration = undefined;
		this.durationFriendly = undefined;
		this.enabledAt = undefined;
		this.disabledAt = undefined;
		this.kioskVideo = {};
		this.resetFile();
	}

	private resetFile() {
		this.thumbnailUrl = undefined;
		this.thumbnail = undefined;
		this.fileUrl = undefined;
		this.file = undefined;
	}

	get size() {
		if (!this.kioskVideo.videoSize) {
			return;
		}

		return UtilService.getVideoSize(this.kioskVideo.videoSize);
	}

	onVideoLoaded(e) {
		console.log('loaded event:', e);
		this.duration = e.target.duration;
		
		this.durationFriendly = UtilService.getVideoDuration(this.duration);
		
		var video = (document.getElementById('video') as HTMLVideoElement);
		var canvas: HTMLCanvasElement = document.createElement('canvas'); 
		var thisComp = this;
		video.addEventListener('seeked', function(){
			if (thisComp.thumbnailUrl) {
				return;
			}
			canvas.width = 640;
			canvas.height = 480;
		
			var ctx = canvas.getContext('2d');
			ctx.drawImage( video, 0, 0, canvas.width, canvas.height );
		
			thisComp.thumbnailUrl = thisComp.sanitizer.bypassSecurityTrustUrl(canvas.toDataURL('image/jpeg'));
			canvas.toBlob((blob) =>{
				thisComp.kioskVideo.thumbnailName = "thumbnail.jpg";
				thisComp.thumbnail = new File([blob], thisComp.kioskVideo.thumbnailName);				
			});
			//reset the video to 0 seconds
			video.currentTime = 0;
		});
		video.currentTime = 3;
	}

	doNewVideo() {
		if (this.runValidation() === false) {
			return;
		}
		
		this.kioskVideo.videoDuration = this.duration;

		this.spinner.show();
		this.httpService.newVideo(this.file, this.thumbnail, this.kioskVideo)
		.pipe(
			catchError(error => {
				this.spinner.hide();
				console.log('Error saving video', error);
				this.toastService.showDanger({ content: 'Failed to save video: ' + (error.error ? error.error.error : error.message) });
				throw error;
			})
		)
		.subscribe(data => {
			this.spinner.hide();
			if (!data.responseStatus) {
				this.toastService.showDanger({ content: 'Failed to save video: ' + data.responseStatusMessage });
				return;
			}

			this.toastService.showSuccess({ content: 'Video saved successfully'});
			this.reset();
		});
	}

	doEditVideo() {
		if (this.runValidation() === false) {
			return;
		}

		this.spinner.show();
		this.httpService.editVideo(this.kioskVideo)
		.pipe(
			catchError(error => {
				this.spinner.hide();
				console.log('Error editing video', error);
				this.toastService.showDanger({ content: 'Failed to edit video: ' + (error.error ? error.error.error : error.message) });
				throw error;
			})
		)
		.subscribe(data => {
			this.spinner.hide();
			if (!data.responseStatus) {
				this.toastService.showDanger({ content: 'Failed to edit video: ' + data.responseStatusMessage });
				return;
			}

			this.toastService.showSuccess({ content: 'Video updated successfully'});
			this.fetchVideo();
		});
	}

	private runValidation(): boolean {
		if (!this.kioskVideo.title) {
			this.toastService.showSecondary({ content: 'Please enter the video title' });
			return false;
		}
		if (!this.kioskVideo.targetAge) {
			this.toastService.showSecondary({ content: 'Please enter the video target age' });
			return false;
		}
		if (!this.kioskVideo.targetGender) {
			this.toastService.showSecondary({ content: 'Please enter the video target gender' });
			return false;
		}

		if (this.enabledAt) {
			var enabledAt = new Date();
			enabledAt.setFullYear(this.enabledAt.year);
			enabledAt.setMonth(this.enabledAt.month-1);
			enabledAt.setDate(this.enabledAt.day);
			this.kioskVideo.enabledAt = enabledAt;
		}
		if (this.disabledAt) {
			var disabledAt = new Date();
			disabledAt.setFullYear(this.disabledAt.year);
			disabledAt.setMonth(this.disabledAt.month-1);
			disabledAt.setDate(this.disabledAt.day);
			this.kioskVideo.disabledAt = disabledAt;
		}
		if (this.kioskVideo.enabledAt && this.kioskVideo.disabledAt) {
			if (this.kioskVideo.disabledAt.getTime() < this.kioskVideo.enabledAt.getTime()) {
				this.toastService.showSecondary({ content: "Please ensure the video's stop date is after the run date" });
				return false;
			}
		}

		return true;
	}

	get disableEditSave() {
		return this.kioskVideo.title === undefined
			|| this.kioskVideo.targetAge === undefined
			|| this.kioskVideo.targetGender === undefined;
	}

	get disableNewSave() {
		return this.kioskVideo.title === undefined
			|| this.kioskVideo.targetAge === undefined
			|| this.kioskVideo.targetGender === undefined
			|| this.file === undefined
			|| this.thumbnail === undefined;
	}
}