
<div class="row">
	<div class="col">
		<webcam [trigger]="invokeObservable" (imageCapture)="captureImg($event)"></webcam>
	</div>
</div>
<div class="row">
	<div class="col">
		<button class="btn btn-secondary" (click)="getSnapshot()">
			Capture Image
		</button>
	</div>
</div>
<div class="row">
	<div class="col">
		<img [src]="webcamImage?.imageAsDataUrl" height="400px" />
	</div>
</div>