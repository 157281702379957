import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input, OnInit} from '@angular/core';
import { KioskRoleDto, KioskUserDto, KioskDto } from '../../../dto';
import { HttpKioskService } from '../../../http-kiosk.service';
import { AppToastService } from 'src/app/components/toasts/app-toast.service';
import { catchError } from 'rxjs/operators';
import { HttpAdminService } from '../../../http-admin.service';
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { NgxSpinnerService } from "ngx-spinner";
import { HttpTechnicianService } from '../../../http-technician.service';

@Component({
	selector: 'app-edit-technician-modal',
	templateUrl: './edit-technician-modal.component.html',
	styleUrls: ['./edit-technician-modal.component.scss']
})
export class EditTechnicianModalComponent implements OnInit {
	@Input() technician: KioskUserDto;

	edit: KioskUserDto;

	add = faArrowRight;
	remove = faArrowLeft;

	constructor(
		public modal: NgbActiveModal, 
		private toastService: AppToastService, 
		private httpService: HttpTechnicianService,
		private spinner: NgxSpinnerService) { }

	ngOnInit(): void {
		this.edit = Object.assign({}, this.technician);
	}

	save() {
		if (!this.edit.name) {
			this.toastService.showSecondary({ content: 'Please enter the name of the technician' });
			return;
		}

		if (!this.edit.surname) {
			this.toastService.showSecondary({ content: 'Please enter the surname of the technician' });
			return;
		}

		if (!this.edit.msisdn) {
			this.toastService.showSecondary({ content: 'Please enter the MSISDN of the technician' });
			return;
		}

		if (!this.edit.email) {
			this.toastService.showSecondary({ content: 'Please enter the email of the technician' });
			return;
		}

		this.spinner.show();
		this.httpService.editTechnician(this.edit)
		.pipe(
			catchError(error => {
				this.spinner.hide();
				console.log('Error editing technician', error);
				this.toastService.showDanger({ content: 'Failed to edit technician: ' + (error.error ? error.error.error : error.message) });
				throw error;
			})
		)
		.subscribe(data => {
			this.spinner.hide();
			if (!data.responseStatus) {
				this.toastService.showDanger({ content: 'Failed to edit technician: ' + data.responseStatusMessage });
				return;
			}

			this.toastService.showSuccess({ content: 'Technician saved successfully'});
			this.modal.close(true);
		});
	}

	cancel() {
		this.modal.close(false);
	}
}