import { Component, Input, OnInit } from "@angular/core";
import { ImageDataDto } from "../../../dto";
import { HttpCustomerService } from "../../../http-customer.service";
import { catchError } from "rxjs/operators";

@Component({
	selector: 'app-customer-image',
	templateUrl: './customer-image.component.html',
	styleUrls: ['./customer-image.component.scss']
})
export class CustomerImageComponent implements OnInit {
	@Input() image: ImageDataDto;

	constructor(private httpService: HttpCustomerService) {}

	ngOnInit(): void {
		if (this.image.image) {
			return;
		}
		this.httpService.getCustomerImage(this.image.id)
			.pipe(
				catchError(error => {
					console.log('Error fetching image', error);
					throw error;
				})
			)
			.subscribe(data => {
				var reader = new FileReader();
				reader.addEventListener("load", () => {
					this.image.image = reader.result;
				}, false);
			
				if (data) {
					reader.readAsDataURL(data);
				}
			});
	}

	viewOriginal() {
		const tab = window.open("", "_blank");
		tab.document.write("<iframe width='100%' height='100%' src='" + this.image.image + "'></iframe>");
	}

}