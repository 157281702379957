<div class="card m-3 shadow-lg bg-white rounded">
	<div class="card-header p-3">
		<fa-icon [icon]="info" class="text-secondary"></fa-icon>&nbsp;Info
		<div class="col small">
			Make a withdrawal of cash or SIM cards from a Kiosk. Select the kiosk, and scan the QR code displayed on the kiosk to authenticate the withdrawal.
		</div>
	</div>
</div>

<div class="card m-3 shadow-lg bg-white rounded">
	<div class="card-header p-3">
		<fa-icon [icon]="view" class="text-secondary"></fa-icon>&nbsp;Select Kiosk
	</div>
	<div class="card-body table-responsive">
		<div class="row">
			<div class="col input-group input-group-sm">
				<span class="input-group-text">Kiosk</span>
				<select name="kiosk" class="form-control" id="kiosk" [(ngModel)]="kiosk">
					<option *ngFor="let kiosk of kiosks" [ngValue]="kiosk">{{util.getKioskDetails(kiosk)}}</option>
				</select>
			</div>
		</div>
	</div>
</div>

<!-- <div *ngIf="kiosk" class="card m-3 shadow-lg bg-white rounded"> -->
<div class="card m-3 shadow-lg bg-white rounded">
	<div class="card-header p-3">
		<fa-icon [icon]="withdrawal" class="text-secondary"></fa-icon>&nbsp;Withdrawal
	</div>
	<div class="card-body table-responsive">
		<div class="row">
			<div class="col col-lg-2">
				Withdrawal type
			</div>
			<div class="col col-lg-1 form-check">
				<input class="form-check-input" type="checkbox" [(ngModel)]="cash" id="cashCheck" (change)="withdrawalTypeChange()">
				<label class="form-check-label" for="cashCheck">
					Cash
				</label>
			</div>
			<div class="col col-lg-1 form-check">
				<input class="form-check-input" type="checkbox" [(ngModel)]="cards" id="cardCheck" (change)="withdrawalTypeChange()">
				<label class="form-check-label" for="cardCheck">
					SIM Card(s)
				</label>
			</div>
		</div>
		<div *ngIf="showCapture" class="row mt-3">
			<div class="col">
				<div class="row"><div class="col">You are making a withdrawal of {{withdrawalDetails}}</div></div>
				<div class="row  mt-4">
					<div class="col">
						<button *ngIf="!scannerVisible" type="button" class="btn btn-secondary" (click)="showScanner()">Scan QR Code</button>
					</div>
				</div>	
				<div class="row">
					<div *ngIf="scannerVisible" class="col">
						{{qrAuthStatusMsg}}
						<zxing-scanner #scanner (scanSuccess)="onCodeResult($event)"></zxing-scanner>
					</div>
				</div>
				<div class="row mt-3"><div class="col" *ngIf="qrValue">QR Data: {{qrValue}}</div></div>
				<div class="row mt-3">
					<div class="col">
						<button *ngIf="canSubmitWithdrawal" type="button" class="btn btn-success" (click)="submitWithdrawal()">Submit Withdrawal</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>