<ng-container *ngIf="admin">
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Edit Admin Roles</h4>
		<button type="button" class="btn-close" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')">
		</button>
	</div>
	<div class="modal-body">
		<div class="row">
			<div class="col mb-3 mx-auto card">
				<div class="card-body">
					<div class="row">
						<div class="col role-col-left">
							<label for="roles" class="form-label">Available Roles</label>
							<ul id="roles" class="list-group">
								<li *ngFor="let role of availableRoles" class="list-group-item">{{role.roleName}} <fa-icon [icon]="add" class="text-primary float-end clickable" title="Add" (click)="addRole(role)"></fa-icon></li>
							  </ul>
							<!-- <input type="text" class="form-control" id="surname" aria-describedby="surname" name="surname" [(ngModel)]="edit.surname" [maxlength]="50"> -->
						</div>
						<div class="col">
							<label for="roles" class="form-label">Roles</label>
							<ul id="roles" class="list-group">
								<li *ngFor="let role of roles" class="list-group-item"><fa-icon [icon]="remove" class="text-primary float-start clickable" title="Remove" (click)="removeRole(role)"></fa-icon>&nbsp;{{role.roleName}}</li>
							  </ul>
							<!-- <input type="text" class="form-control" id="surname" aria-describedby="surname" name="surname" [(ngModel)]="edit.surname" [maxlength]="50"> -->
						</div>
					</div>
				</div>
			</div>			
		</div>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-outline-secondary" (click)="cancel()">Cancel</button>
		<button type="button" class="btn btn-success" (click)="save()">Save</button>
	</div>
</ng-container>