<div class="row">
	<div class="col">
		<div class="card m-3 shadow-lg bg-white rounded">
			<div class="card-header">
				<div class="row">
					<div class="col pt-1"><fa-icon [icon]="newLocation" class="text-secondary"></fa-icon>&nbsp;New Location</div>
					<div class="col text-end">
						<button class="float-end btn btn-outline-secondary" (click)="goBack()"><fa-icon [icon]="back" size="sm" ></fa-icon></button>
					</div>
				</div>
			</div>
			<div class="card-body">
				<form>
					<div class="row">
						<div class="col">
							<div class="row">
								<div class="col-lg-6 mb-3 mx-auto input-group">
									<span class="input-group-text">Name</span>
									<input type="text" class="form-control" id="name" aria-describedby="name" name="name" [(ngModel)]="location.name" [maxlength]="50">
								</div>
							</div>
							<div class="row">
								<div class="col">
									<div class="row">
										<div class="col mb-3 mx-auto">
											<app-map (onAddressSelected)="addressSelected($event)"></app-map>
										</div>
										<div class="col">
											<div class="card">
												<div class="card-header"><fa-icon [icon]="selectedLocation" class="text-secondary"></fa-icon>&nbsp;Selected Location</div>
												<div class="card-body table-responsive">
													<small *ngIf="!place">Please enter an address</small>
													<table *ngIf="place" class="table">
														<tr><th>Address</th><td>{{place.address}}</td></tr>
														<tr><th>City</th><td>{{place.city}}</td></tr>
														<tr><th>State</th><td>{{place.province}}</td></tr>
														<tr><th>Country</th><td>{{place.country}}</td></tr>
														<tr><th>Latitude</th><td>{{place.latitude}}</td></tr>
														<tr><th>Longitude</th><td>{{place.longitude}}</td></tr>
													</table>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<!-- <div class="row">
								<div class="col-lg-6 mb-3 mx-auto input-group">
									<span class="input-group-text">Latitude</span>
									<input type="text" class="form-control" id="latitude" aria-describedby="latitude" name="latitude" [(ngModel)]="location.latitude" [maxlength]="50">
								</div>
							</div>
							<div class="row">
								<div class="col-lg-6 mb-3 mx-auto input-group">
									<span class="input-group-text">Longitude</span>
									<input type="msisdn" class="form-control" id="longitude" aria-describedby="longitude" name="longitude" [(ngModel)]="location.longitude" [maxlength]="50">
								</div>
							</div> -->
						</div>
					</div>
					
				</form>
			</div>
			<div class="card-footer">
				<button type="button" class="btn btn-success" (click)="addLocation()" [disabled]="disableSave">Save</button>
			</div>
		</div>
	</div>	
</div>