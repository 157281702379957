
<div class="row mt-2 vertical-on-small">
	<div class="col input-group input-group-sm">
		<span class="input-group-text">Filter</span>
		<input type="text" size="10" class="form-control" id="filter" aria-describedby="filter" name="filter" [(ngModel)]="filter" [maxlength]="30" (keydown)="filterChanged()">
		<div class="input-group-append input-group-sm" *ngIf="filter">
			<button type="button" class="btn bg-outline-secondary" (click)="clearFilter()">
				<fa-icon [icon]="clearSearch"></fa-icon>
			</button>
		</div>
		
	</div>
	<div class="col filter-spacer"></div>
	<div class="col input-group input-group-sm">
		<span class="input-group-text">Region</span>
		<select name="region" class="form-control" id="region" [(ngModel)]="region" (ngModelChange)="onRegionChange($event)">
			<option *ngFor="let region of regions" [ngValue]="region">{{region.region}}</option>
		</select>
		<div class="input-group-append input-group-sm" *ngIf="region">
			<button type="button" class="btn bg-outline-secondary" (click)="clearRegion()">
				<fa-icon [icon]="clearSearch"></fa-icon>
			</button>
		</div>
	</div>
	<div class="col input-group input-group-sm">
		<span class="input-group-text">Cities</span>
		<select name="city" class="form-control" id="city" [(ngModel)]="city" (ngModelChange)="onCityChange($event)">
			<option *ngFor="let city of cities" [ngValue]="city">{{city.city}}</option>
		</select>
		<div class="input-group-append input-group-sm" *ngIf="city">
			<button type="button" class="btn bg-outline-secondary" (click)="clearCity()">
				<fa-icon [icon]="clearSearch"></fa-icon>
			</button>
		</div>
	</div>
	
</div>

<div class="pt-3 pb-2 mb-3">
	<div class="shadow-lg p-3 mb-5 bg-white rounded table-responsive">
		<table *ngIf="kiosks" class="table table-hover">
			<thead>
				<tr>
					<th>ID</th>
					<th>Created At</th>
					<th>Device Ref</th>
					<th>Name</th>
					<th>Location</th>
					<th>Status</th>
					<th>Action</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let kiosk of visibleKiosks" (click)="viewKiosk(kiosk)">
					<th scope="row">{{kiosk.id}}</th>
					<td>{{kiosk.createdAt | date:'yyyy-MM-dd HH:mm:ss'}}</td>
					<td>{{kiosk.deviceRef}}</td>
					<td>{{kiosk.name}}</td>
					<td>{{util.getLocation(kiosk)}}</td>
					<td>
						<fa-icon *ngIf="kiosk.kioskStatus === 'New'" [icon]="new" class="text-success" title="New"></fa-icon>
						<fa-icon *ngIf="kiosk.secretInvalidAt !== null" [icon]="disabled" class="text-danger" title="Invalidated"></fa-icon>
					</td>
					<td>
						<div class="btn-group" role="group">
							<button class="btn btn-outline-secondary" 
								[routerLink]="['/kiosk','view', kiosk.id]" (click)="$event.stopPropagation()"
								type="button">
								View <fa-icon [icon]="view" size="lg"></fa-icon>
							</button>
							<button *ngIf="getButtonVisible('kiosk-verify')" 
								class="btn btn-outline-secondary" 
								(click)="verifyKiosk(kiosk); $event.stopPropagation()" 
								type="button">
								Verify <fa-icon [icon]="verified" size="lg"></fa-icon>
							</button>
						</div>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>
