<div class="modal-header">
	<h4 class="modal-title" id="modal-title">Customer MoMo Account Details</h4>
	<button type="button" class="btn-close" aria-describedby="modal-title" (click)="close()">
	</button>
</div>
<div class="modal-body table-responsive">
	<table class="table table-borderless">
		<tbody>
			<tr>
				<th>Account</th>
				<td>{{customer.momoAccount}}</td>
			</tr>
			<tr>
				<th>Balance</th>
				<td>{{customer.momoBalance}}</td>
			</tr>
			<tr>
				<th>Last Tranfer</th>
				<td>{{customer.momoLastTransfer}}</td>
			</tr>
			<tr>
				<th>Last Recharge</th>
				<td>{{customer.momoLastRecharge}}</td>
			</tr>
			<tr>
				<th>Last 3 Dialed</th>
				<td>{{customer.momoLastThreeDialed}}</td>
			</tr>
		</tbody>
	</table>
</div>
<div class="modal-footer">
	<button type="button" class="btn btn-outline-secondary" (click)="close()">Close</button>
</div>