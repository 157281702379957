
<div *ngIf="kiosk && componentInit" class="card m-3 shadow-lg bg-white rounded stats">
	<div class="card-header">
		<div class="col" [ngClass]="getStatusDescription().class">{{getStatusDescription().text}}</div>
	</div>
	<div class="card-body">
		<div class="row">
			<div class="col text-center">
				<div><fa-icon [icon]="dispenser" class="text-primary fs-4"></fa-icon><br/>Dispenser</div>
				<div><fa-icon [icon]="getIcon(componentInit.dispenser)" class="text-primary fs-4" [ngClass]="getIconClass(componentInit.dispenser)"></fa-icon></div>
			</div>
			<div class="col text-center">
				<div><fa-icon [icon]="barcode" class="text-primary fs-4"></fa-icon><br/>Barcode</div>
				<div><fa-icon [icon]="getIcon(componentInit.barcode)" class="text-primary fs-4" [ngClass]="getIconClass(componentInit.barcode)"></fa-icon></div>
			</div>
			<div class="col text-center">
				<div><fa-icon [icon]="printer" class="text-primary fs-4"></fa-icon><br/>Printer</div>
				<div><fa-icon [icon]="getIcon(componentInit.printer)" class="text-primary fs-4" [ngClass]="getIconClass(componentInit.printer)"></fa-icon></div>
			</div>
			<div class="col text-center">
				<div><fa-icon [icon]="coinValidator" class="text-primary fs-4"></fa-icon><br/>Coin Validator</div>
				<div><fa-icon [icon]="getIcon(componentInit.coinValidator)" class="text-primary fs-4" [ngClass]="getIconClass(componentInit.coinValidator)"></fa-icon></div>
			</div>
			<div class="col text-center">
				<div><fa-icon [icon]="hopperCoins" class="text-primary fs-4"></fa-icon><br/>Hopper Coins</div>
				<div><fa-icon [icon]="getIcon(componentInit.hopperCoins)" class="text-primary fs-4" [ngClass]="getIconClass(componentInit.hopperCoins)"></fa-icon></div>
			</div>
			<div class="col text-center">
				<div><fa-icon [icon]="hopperNotes" class="text-primary fs-4"></fa-icon><br/>Hopper Notes</div>
				<div><fa-icon [icon]="getIcon(componentInit.hopperNotes)" class="text-primary fs-4" [ngClass]="getIconClass(componentInit.hopperNotes)"></fa-icon></div>
			</div>
			<div class="col text-center">
				<div><fa-icon [icon]="kioskIcon" class="text-primary fs-4"></fa-icon><br/>Kiosk</div>
				<div><fa-icon [icon]="getIcon(componentInit.kioskStatus)" class="text-primary fs-4" [ngClass]="getIconClass(componentInit.kioskStatus)"></fa-icon></div>
			</div>
		</div>
		<!-- <table class="table table-borderless text-center">
			<thead>
				<tr>
					<th><fa-icon [icon]="serialPorts" class="text-primary fs-4"></fa-icon><br/>Dispenser</th>
					<th><fa-icon [icon]="serialDevices" class="text-primary fs-4"></fa-icon><br/>Barcode</th>
					<th><fa-icon [icon]="usbDevices" class="text-primary fs-4"></fa-icon><br/>Printer</th>
					<th><fa-icon [icon]="usbToSerialDevices" class="text-primary fs-4"></fa-icon><br/>Coin Validator</th>
					<th><fa-icon [icon]="total" class="text-primary fs-4"></fa-icon><br/>Hopper Coins</th>
					<th><fa-icon [icon]="total" class="text-primary fs-4"></fa-icon><br/>Hopper Notes</th>
					<th><fa-icon [icon]="total" class="text-primary fs-4"></fa-icon><br/>Kiosk Status</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td><fa-icon [icon]="getIcon(componentInit.dispenser)" class="text-primary fs-4" [ngClass]="getIconClass(componentInit.dispenser)"></fa-icon></td>
					<td><fa-icon [icon]="getIcon(componentInit.barcode)" class="text-primary fs-4" [ngClass]="getIconClass(componentInit.barcode)"></fa-icon></td>
					<td><fa-icon [icon]="getIcon(componentInit.printer)" class="text-primary fs-4" [ngClass]="getIconClass(componentInit.printer)"></fa-icon></td>
					<td><fa-icon [icon]="getIcon(componentInit.coinValidator)" class="text-primary fs-4" [ngClass]="getIconClass(componentInit.coinValidator)"></fa-icon></td>
					<td><fa-icon [icon]="getIcon(componentInit.hopperCoins)" class="text-primary fs-4" [ngClass]="getIconClass(componentInit.hopperCoins)"></fa-icon></td>
					<td><fa-icon [icon]="getIcon(componentInit.hopperNotes)" class="text-primary fs-4" [ngClass]="getIconClass(componentInit.hopperNotes)"></fa-icon></td>
					<td><fa-icon [icon]="getIcon(componentInit.kioskStatus)" class="text-primary fs-4" [ngClass]="getIconClass(componentInit.kioskStatus)"></fa-icon></td>
				</tr>
			</tbody>
		</table> -->
	</div>
</div>