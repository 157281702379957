import { Component, Input } from "@angular/core";
import { CustomerDto, CustomerSimDto } from "../../../dto";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
	selector: 'app-customer-sims-modal',
	templateUrl: './customer-sims-modal.component.html',
	styleUrls: ['./customer-sims-modal.component.scss']
})
export class CustomerSimsModalComponent {
	@Input() sims: CustomerSimDto[];

	constructor(
		public modal: NgbActiveModal,
	) {}

	close() {
		this.modal.close(false);
	}
}