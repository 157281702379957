<div class="modal-header">
	<h4 class="modal-title" id="modal-title">SIM(s)</h4>
	<button type="button" class="btn-close" aria-describedby="modal-title" (click)="close()">
	</button>
</div>
<div class="modal-body table-responsive">
	<table class="table table-borderless">
		<thead>
			<tr><th>ICCID</th></tr>
		</thead>
		<tbody>
			<tr *ngFor="let sim of sims">
				<td>{{sim.iccid}}</td>
			</tr>
		</tbody>
	</table>
</div>
<div class="modal-footer">
	<button type="button" class="btn btn-outline-secondary" (click)="close()">Close</button>
</div>