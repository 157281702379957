<div class="row">
	<div class="col col-md-10 col-lg-6 mx-auto">
		<div class="card m-3 shadow-lg bg-white rounded">
			<div class="card-header">
				<div class="row">
					<div class="col pt-1"><fa-icon [icon]="newTech" class="text-secondary"></fa-icon>&nbsp;New Technician</div>
					<div class="col text-end">
						<button class="float-end btn btn-outline-secondary" (click)="goBack()"><fa-icon [icon]="back" size="sm" ></fa-icon></button>
					</div>
				</div>
			</div>
			<div class="card-body">
				<form>
					<div class="row">
						<div class="col-lg-6 mb-3 mx-auto input-group">
							<span class="input-group-text">Name</span>
							<input type="text" class="form-control" id="name" aria-describedby="name" name="name" [(ngModel)]="technician.name" [maxlength]="50">
						</div>
					</div>
					<div class="row">
						<div class="col-lg-6 mb-3 mx-auto input-group">
							<span class="input-group-text">Surname</span>
							<input type="text" class="form-control" id="surname" aria-describedby="surname" name="surname" [(ngModel)]="technician.surname" [maxlength]="50">
						</div>
					</div>
					<div class="row">
						<div class="col-lg-6 mb-3 mx-auto input-group">
							<span class="input-group-text">Username</span>
							<input type="text" class="form-control" id="username" aria-describedby="username" name="username" [(ngModel)]="technician.username" [maxlength]="50" (focus)="generateUsername()">
						</div>
					</div>
					<div class="row">
						<div class="col-lg-6 mb-3 mx-auto input-group">
							<span class="input-group-text">MSISDN</span>
							<input type="msisdn" class="form-control" id="msisdn" aria-describedby="msisdn" name="msisdn" [(ngModel)]="technician.msisdn" [maxlength]="50">
						</div>
					</div>
					<div class="row">
						<div class="col-lg-6 mb-3 mx-auto input-group">
							<span class="input-group-text">Email</span>
							<input type="email" class="form-control" id="email" aria-describedby="email" name="email" [(ngModel)]="technician.email" [maxlength]="50">
						</div>
					</div>
				</form>
			</div>
			<div class="card-footer">
				<button type="button" class="btn btn-success" (click)="addTechnician()">Save</button>
			</div>
		</div>
	</div>	
</div>