<div *ngIf="displayType === 'OwnComponent'" class="row mt-1">
	<div class="col text-end"><button class="btn btn-outline-secondary" (click)="goBack()"><fa-icon [icon]="back" size="sm" ></fa-icon></button></div>
</div>

<div class="pt-2 pb-2 mb-3" *ngIf="kiosk === undefined && alarms === undefined">
	<div class="row">
		<div class="col">
			<div class="card m-3 shadow-lg bg-white rounded">
				<div class="card-header"><fa-icon [icon]="alarmIcon" class="text-secondary"></fa-icon>&nbsp;Alarms</div>
				<div class="card-body">No alarms</div>
			</div>
		</div>
	</div>
</div>

<div *ngIf="kiosk" class="card m-2 shadow-lg bg-white rounded stats">
	<div class="card-body">
		<table class="table table-borderless text-center">
			<thead>
				<tr>
					
					<th><fa-icon [icon]="kioskIcon" class="text-primary fs-4"></fa-icon><br/>Kiosk</th>
					<th *ngFor="let monitor of monitorStatus">
						<fa-icon [icon]="monitor.getIcon()" class="text-primary fs-4"></fa-icon><br/>{{monitor.getPeripheralString()}}
					</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>{{util.getKioskDetails(kiosk)}}</td>
					<td *ngFor="let monitor of monitorStatus" 
						[class]="util.getHighLevelStatusCss(getMonitorStatus(monitor.getName()))">
						{{monitor.getStatus()}}
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>

<div class="pt-3 pb-2 mb-3" *ngIf="kiosk">
	<div class="row" *ngFor="let alarm of alarms">
		<div class="col">
			<div class="card shadow-lg p-3 m-3 bg-white rounded">
				<div class="card-header">
					<div class="row">
						<div class="col-11">
							<div class="row">
								<div class="col text-primary"><strong>{{alarm.monitor}}</strong></div>
								<div class="col"><strong>Issue started:</strong> {{alarm.updatedAt | timeago:true}}</div>
								<div class="col"><strong>Last Escalated:</strong> {{alarm.escalatedAt ? (alarm.escalatedAt | timeago:true) : 'N/A'}}</div>
								<div class="col"><strong>Status:&nbsp;<span [class]="util.getHighLevelStatusCss(alarm.status)">{{alarm.status}}</span></strong></div>
							</div>
						</div>
						<div class="col"><fa-icon [icon]="alarmIcon" class="fs-6 float-end" [class]="util.getHighLevelStatusCss(alarm.status)"></fa-icon></div>
					</div>
				</div>
				<div class="card-body">					
					<div class="row">
						<div class="col">
							<button class="btn btn-outline-secondary" 
								(click)="addLogEntry(alarm.peripheral)" 
								type="button">
								Add Maintenance Log <fa-icon [icon]="add" size="lg"></fa-icon>
							</button>
						</div>
						<div class="col">
							<div class="form-check form-switch float-end">
								<input class="form-check-input" type="checkbox" role="switch" id="showCurrentLogsOnly" 
									[value]="alarm.showCurrentLogsOnly" [(ngModel)]="alarm.showCurrentLogsOnly"
									(ngModelChange)="setAlarmLogVisibility(alarm.id)">
								<label class="form-check-label" for="showCurrentLogsOnly">Current logs only</label>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-lg-3 mr-3 mb-3 mt-3">
							<div class="row">
								<div class="card">
									<div class="card-header">Alert details</div>
									<div class="card-body alert-message">{{alarm.alert}}</div>
								</div>
							</div>
						</div>
						<div class="col mr-3 mb-3 mt-3 table-responsive maintenance-logs">
							<table class="table table-hover ">
								<thead>
									<tr>
										<th>Date</th>
										<th>Maintenance Log</th>
										<th>Technician</th>
									</tr>
								</thead>
								<tbody *ngIf="alarm.logs">
									<tr *ngFor="let log of alarm.logs">
										<td>{{log.createdAt | date:'yyyy-MM-dd HH:mm:ss'}}</td>
										<td>{{log.log}}</td>
										<td>{{log.username}}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
		
		<!-- <table *ngIf="alarms" class="table table-hover">
			<thead>
				<tr>
					<th>ID</th>
					<th>Created At</th>
					<th>Updated At</th>
					<th>Escalated At</th>
					<th>Kiosk</th>
					<th>Monitor</th>
					<th>Status</th>
					<th>Action</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let alarm of alarms">
					<th scope="row">{{alarm.id}}</th>
					<td>{{alarm.createdAt | date:'yyyy-MM-dd HH:mm:ss'}}</td>
					<td>{{alarm.verifiedAt | date:'yyyy-MM-dd HH:mm:ss'}}</td>
					<td>{{alarm.escalatedAt | date:'yyyy-MM-dd HH:mm:ss'}}</td>
					<td>{{util.getKioskDetails(alarm.kiosk)}}</td>
					<td>{{alarm.monitor}}</td>
					<td>{{alarm.status}}</td>
					<td>
						<div class="btn-group" role="group">
							<button class="btn btn-outline-secondary" 
								[routerLink]="['/kiosk','maintenance', alarm.kiosk.id]" (click)="$event.stopPropagation()"
								type="button">
								Maintenance <fa-icon [icon]="maintenance" size="lg"></fa-icon>
							</button>
						</div>
					</td>
				</tr>
			</tbody>
		</table> -->
</div>
