<ng-container *ngIf="video">
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">{{video.title}}</h4>
		<button type="button" class="btn-close" aria-describedby="modal-title" (click)="cancel()">
		</button>
	</div>
	<div class="modal-body">
		<video #videoPlayer id="videoPlayer" [src]="videoUrl" controls autoplay></video>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-outline-secondary" (click)="cancel()">Close</button>
	</div>
</ng-container>