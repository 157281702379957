import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AppToastService } from "src/app/components/toasts/app-toast.service";
import { KioskDto, KioskLocationDto } from "../../dto";
import { HttpKioskService } from "../../http-kiosk.service";
import { faCheck, faHourglassStart, faLock, faUnlock, faBan, faEdit, faSearch, faTimes, faQrcode, faInfo, faInfoCircle, faArrowsSplitUpAndLeft, faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { UtilService } from "../../util.service";
import { KioskActionService } from "../../kiosk-action.service";
import { Subject, Subscription } from "rxjs";
import { HttpLocationService } from "../../http-location.service";
import { catchError, debounceTime } from "rxjs/operators";
import { NgxSpinnerService } from "ngx-spinner";
import { AuthService } from "src/app/service/auth.service";
import { environment } from "src/environments/environment";


@Component({
	selector: 'app-kiosk-withdrawal',
	templateUrl: './kiosk-withdrawal.component.html',
	styleUrls: ['./kiosk-withdrawal.component.scss']
})
export class KioskWithdrawalComponent implements OnInit, OnDestroy {

	kiosks: KioskDto[];
	kiosk: KioskDto;
	info = faInfoCircle;
	view = faSearch;
	withdrawal = faArrowCircleLeft;
	qrCode = faQrcode;
	util = UtilService;

	cash: boolean;
	cards: boolean;

	scannerVisible = false;
	qrValue: string;
	qrAuthStatus: 'start' | 'complete' | 'failed' | 'processing' = 'start';

	showCapture = false;

	constructor(
		private httpService: HttpKioskService, 
		private spinner: NgxSpinnerService,
		private toastService: AppToastService) {}

	ngOnInit(): void {
		this.fetchAllKiosks();
	}

	private fetchAllKiosks() {
		this.spinner.show();
		this.httpService.getAll()
		.pipe(
			catchError(error => {
				this.spinner.hide();
				console.log('Error fetching kiosks', error);
				this.toastService.showDanger({ content: 'Failed to fetch data: ' + (error.error ? error.error.error : error.message) });
				throw error;
			})
		)
		.subscribe(items => {
			this.kiosks = items;
			this.kiosks.forEach(item => item.visible = true);
			this.spinner.hide();
		});

	}

	withdrawalTypeChange() {
		console.log("Selected cash [" + this.cash + "] - cards [" + this.cards + "]");

		if (true === this.cash || true == this.cards) {
			this.showCapture = true;
			return;
		}

		this.showCapture = false;
	}

	get withdrawalDetails(): string {
		if (true == this.cash && true == this.cards) {
			return 'cash and SIM card(s)';
		}	

		if (true == this.cash) {
			return 'cash';
		}	

		if (true == this.cards) {
			return 'SIM card(s)';
		}	
	}

	showScanner() {
		this.scannerVisible = true;
		this.qrValue = undefined;
	}

	onCodeResult(resultString: string) {
		this.qrValue = resultString;
		this.scannerVisible = false;
		this.qrAuthStatus = 'processing';

		//send request to server to verify the QR code is linked to the kiosk
		this.spinner.show();
		setTimeout(() => {
			this.qrAuthStatus = 'complete';
			this.spinner.hide();
		}, 5000);
	}

	get qrAuthStatusMsg(): string {

		switch (this.qrAuthStatus) {
			case "start":
				return "Please scan the QR code from the kiosk";
			case "complete":
				return "Your withdrawal has been successfully authorised. Click submit to finalise the withdrawal.";
			case "failed":
				return "Your withdrawal has been denied. Please ensure you scan the QR code as presented on " + this.util.getKioskDetails(this.kiosk);
			case "processing":
				return "Please wait... authorising your withdrawal";
		}
	}

	get canSubmitWithdrawal(): boolean {
		return this.qrAuthStatus === "complete";
	}

	submitWithdrawal() {
		//submit the withdrawal, with the QR code, server will save it, update some table that kiosk polls, to let kiosk know to proceed
		
		this.spinner.show();
		setTimeout(() => {
			this.spinner.hide();

			this.qrAuthStatus = 'start';
			this.qrValue = undefined;
			this.showCapture = false;
			this.cash = false;
			this.cards = false;
			this.kiosk = undefined;
			this.toastService.showSuccess({ content: 'Withdrawal processed successfully'});
		}, 5000);
	}

	ngOnDestroy(): void {
		
	}
}