<app-top-bar></app-top-bar>
<ng-container *ngIf="user">
	<div *ngIf="isOtpScreen" class="card m-3 shadow-lg bg-white rounded">
		<div class="card-header p-3">
			Verify your account <fa-icon [icon]="newAdmin" class="float-end text-secondary"></fa-icon>
		</div>
		<div class="card-body">
			<div class="row">
				<div class="col-lg-6 mb-3 mx-auto text-center">
					An OTP has been sent to your mobile number: {{user.msisdn}}
				</div>
			</div>
			<div class="row">
				<div class="col-lg-6 mb-3 mx-auto text-center">
					<small>Please capture the OTP below</small>
				</div>
			</div>
			<form>
				<div class="row">
					<div class="col-lg-6 mb-3 mx-auto">
						<div class="row">
							<div class="col input-group mb-3">
								<span class="input-group-text">OTP</span>
								<input type="number" 
									class="form-control" 
									id="otp" 
									aria-describedby="otp" 
									name="otp" 
									[(ngModel)]="otpModel" 
									[maxlength]="5"
									pattern="/^-?\d+\.?\d*$/" 
									onKeyPress="if(this.value.length>=5) return false;">
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
		<div class="card-footer">
			<button type="button" class="btn btn-success spacer" (click)="verifyOtp()" [disabled]="disableOtp">Verify</button>
			<button type="button" class="btn btn-outline-secondary" (click)="resendOtp()" [disabled]="disableResend">Resend</button>
		</div>
	</div>
	<div *ngIf="isPasswordScreen" class="card m-3 shadow-lg bg-white rounded">
		<div class="card-header p-3">
			Account Setup <fa-icon [icon]="newAdmin" class="float-end text-secondary"></fa-icon>
		</div>
		<div class="card-body">
			<div class="row">
				<div class="col-lg-6 mb-3 mx-auto text-center">
					Welcome {{user.name}} {{user.surname}}.<br/>Your username is: <span class="username">{{user.username}}</span><br/><br/>
					Please enter a new password below to complete the setup of your account.
				</div>
			</div>
			<form>
				<div class="row">
					<div class="col-lg-6 mb-3 mx-auto">
						<div class="row">
							<div class="col input-group mb-3">
								<span class="input-group-text">New Password</span>
								<input [type]="passwordField" class="form-control" id="password" aria-describedby="password" name="password" [(ngModel)]="user.password" [maxlength]="20">
								<span class="input-group-text">
									<fa-icon class="clickable" [icon]="passwordFieldIcon" (click)="togglePassword()"></fa-icon>
								</span>
							</div>
						</div>
						<div class="row">
							<div class="col input-group">
								<span class="input-group-text">Confirm Password</span>
								<input [type]="confirmPasswordField" class="form-control" id="confirmPassword" aria-describedby="confirmPassword" name="confirmPassword" [(ngModel)]="confirmPasswordModel" [maxlength]="20">
								<span class="input-group-text">
									<fa-icon class="clickable" [icon]="confirmPasswordFieldIcon" (click)="toggleConfirmPassword()"></fa-icon>
								</span>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
		<div class="card-footer">
			<button type="button" class="btn btn-success" (click)="save()" [disabled]="disableSave">Save</button>
		</div>
	</div>
</ng-container>
