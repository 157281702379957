<div class="pt-3 pb-2 mb-3">
	<div class="shadow-lg p-3 mb-5 bg-white rounded table-responsive">
		<table *ngIf="alarms" class="table table-hover">
			<thead>
				<tr>
					<th>ID</th>
					<th>Created At</th>
					<th>Duration</th>
					<th>Escalated At</th>
					<th>Escalated</th>
					<th>Kiosk</th>
					<th>Monitor</th>
					<th>Status</th>
					<th>Details</th>
					<th>Action</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let alarm of alarms" (click)="openMaintenance(alarm)">
					<th scope="row">{{alarm.id}}</th>
					<td>{{alarm.updatedAt | date:'yyyy-MM-dd HH:mm:ss'}}</td>
					<td>{{alarm.updatedAt | timeago: true}}</td>
					<td>{{alarm.escalatedAt | date:'yyyy-MM-dd HH:mm:ss'}}</td>
					<td>{{alarm.escalatedAt ? (alarm.escalatedAt | timeago: true) : ''}}</td>
					<td>{{util.getKioskDetails(alarm.kiosk)}}</td>
					<td>{{alarm.monitor}}</td>
					<td [class]="util.getHighLevelStatusCss(alarm.status)">{{alarm.status}}</td>
					<td class="alert-message">{{alarm.alert}}</td>
					<td>
						<div class="btn-group" role="group">
							<button class="btn btn-outline-secondary" 
								[routerLink]="['/kiosk','maintenance', alarm.kiosk.id]" (click)="$event.stopPropagation()"
								type="button">
								Maintenance <fa-icon [icon]="maintenance" size="lg"></fa-icon>
							</button>
						</div>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>
