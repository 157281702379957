<ng-container *ngIf="user">
	<div class="row d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
		<div class="col text-end"><button class="btn btn-outline-secondary" (click)="goBack()"><fa-icon [icon]="back" size="sm" ></fa-icon></button></div>
	</div>
	<div class="row">
		<div class="col text-center">
			<fa-icon [icon]="userIcon" class="lightgray fa-3x"></fa-icon>
		</div>
	</div>
	<div class="row">
		<div class="col text-primary text-center fs-3">{{username}}</div>
	</div>
	<div class="row align-items-center mb-3 text-center">
		<div class="col details">
			<fa-icon [icon]="userIcon"></fa-icon>&nbsp;{{user.name + ' ' + user.surname}}&nbsp;&nbsp;&nbsp; 
			<fa-icon [icon]="tel"></fa-icon>&nbsp;{{user.msisdn}}&nbsp;&nbsp;&nbsp;
			<fa-icon [icon]="email"></fa-icon>&nbsp;{{user.email}}
		</div>
	</div>
	<div class="row">
		<div class="col">
			<div class="card m-3 shadow-lg bg-white rounded">
				<div class="card-header p-3">
					<fa-icon [icon]="newAdmin" class="text-secondary"></fa-icon>&nbsp;Change Password
				</div>
				<div class="card-body">
					<form>
						<div class="row">
							<div class="col-lg-6 mb-3 mx-auto">
								<div class="row">
									<div class="col input-group mb-3">
										<span class="input-group-text">Password</span>
										<input [type]="currentPasswordField" class="form-control" id="currentPassword" aria-describedby="currentPassword" name="currentPassword" [(ngModel)]="currentPasswordModel" [maxlength]="20">
										<span class="input-group-text">
											<fa-icon class="clickable" [icon]="currentPasswordFieldIcon" (click)="toggleCurrentPassword()"></fa-icon>
										</span>
									</div>
								</div>
								<div class="row">
									<div class="col input-group mb-3">
										<span class="input-group-text">New Password</span>
										<input [type]="passwordField" class="form-control" id="password" aria-describedby="password" name="password" [(ngModel)]="newPasswordModel" [maxlength]="20">
										<span class="input-group-text">
											<fa-icon class="clickable" [icon]="passwordFieldIcon" (click)="togglePassword()"></fa-icon>
										</span>
									</div>
								</div>
								<div class="row">
									<div class="col input-group">
										<span class="input-group-text">Confirm</span>
										<input [type]="confirmPasswordField" class="form-control" id="confirmPassword" aria-describedby="confirmPassword" name="confirmPassword" [(ngModel)]="confirmPasswordModel" [maxlength]="20">
										<span class="input-group-text">
											<fa-icon class="clickable" [icon]="confirmPasswordFieldIcon" (click)="toggleConfirmPassword()"></fa-icon>
										</span>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
				<div class="card-footer">
					<button type="button" class="btn btn-success" (click)="changePassword()" [disabled]="disableChangePassword">Save</button>
				</div>
			</div>
		</div>	
	</div>
	<div class="row">
		<div class="col">
			<div class="card m-3 shadow-lg bg-white rounded">
				<div class="card-header p-3">
					<fa-icon [icon]="changeProfile" class="text-secondary"></fa-icon>&nbsp;Change Details
				</div>
				<div class="card-body">
					<form>
						<div class="row">
							<div class="col-lg-6 mb-3 mx-auto">
								<div class="row">
									<div class="col input-group mb-3">
										<span class="input-group-text">Name</span>
										<input disabled="true" type="text" class="form-control" id="name" aria-describedby="name" name="name" [(ngModel)]="edit.name" [maxlength]="50">
									</div>
								</div>
								<div class="row">
									<div class="col input-group mb-3">
										<span class="input-group-text">Surname</span>
										<input disabled="true" type="text" class="form-control" id="surname" aria-describedby="surname" name="surname" [(ngModel)]="edit.surname" [maxlength]="50">
									</div>
								</div>
								<div class="row">
									<div class="col input-group mb-3">
										<span class="input-group-text">MSISDN</span>
										<input type="text" class="form-control" id="msisdn" aria-describedby="msisdn" name="msisdn" [(ngModel)]="edit.msisdn" [maxlength]="50">
									</div>
								</div>
								<div class="row">
									<div class="col input-group mb-3">
										<span class="input-group-text">Email</span>
										<input type="text" class="form-control" id="email" aria-describedby="email" name="email" [(ngModel)]="edit.email" [maxlength]="50">
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
				<div class="card-footer">
					<button type="button" class="btn btn-success" (click)="changeDetails()" [disabled]="disableChangeDetails">Save</button>
				</div>
			</div>
		</div>	
	</div>
</ng-container>