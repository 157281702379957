import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractHttpService } from '../../service/http-abstract-service';
import { AdminKioskResponseDto, KioskLocationDto } from './dto';


@Injectable({
	providedIn: 'root'
})
export class HttpLocationService extends AbstractHttpService {

	constructor(http: HttpClient) {
		super(http, '/location');
	}

	getLocation(locationId: number): Observable<AdminKioskResponseDto> {
		return super.get(`/${locationId}/get-location`);
	}

	getAllLocations(): Observable<KioskLocationDto[]> {
		return super.get(`/get-locations`);
	}

	getAllRegions(): Observable<KioskLocationDto[]> {
		return super.get(`/get-regions`);
	}

	getCitiesByRegion(region: KioskLocationDto): Observable<KioskLocationDto[]> {
		return super.post(`/get-cities-by-region`, region);
	}

	getActiveLocations(): Observable<KioskLocationDto[]> {
		return super.get(`/get-active-locations`);
	}

	addLocation(location: KioskLocationDto): Observable<AdminKioskResponseDto> {
		return super.post(`/add`, location);
	}

	editLocation(location: KioskLocationDto): Observable<AdminKioskResponseDto> {
		return super.post(`/edit`, location);
	}

	changeLocationStatus(locationId: number, status: 'Disable' | 'ReEnable'): Observable<AdminKioskResponseDto> {
		return super.get(`/${locationId}/${status}/action`);
	}
}
