import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AppToastService } from "src/app/components/toasts/app-toast.service";
import { KioskDto, KioskLocationDto, KioskMonitorStatusDto } from "../../dto";
import { HttpKioskService } from "../../http-kiosk.service";
import { faCheck, faHourglassStart, faLock, faUnlock, faBan, faEdit, faSearch, faTimes, faWrench } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { UtilService } from "../../util.service";
import { catchError, debounceTime } from "rxjs/operators";
import { NgxSpinnerService } from "ngx-spinner";
import { AuthService } from "src/app/service/auth.service";
import { HttpAlarmService } from "../../http-alarm.service";

@Component({
	selector: 'app-alarm-list',
	templateUrl: './alarm-list.component.html',
	styleUrls: ['./alarm-list.component.scss']
})
export class AlarmListComponent {

	alarms: KioskMonitorStatusDto[];
	
	maintenance = faWrench;

	util = UtilService;

	constructor(
		private httpService: HttpAlarmService, 
		private router: Router,
		private spinner: NgxSpinnerService,
		private toastService: AppToastService,
		private authService: AuthService) {
	}

	ngOnInit(): void {
		this.fetchAllAlarms();
	}

	private fetchAllAlarms() {
		this.spinner.show();
		this.httpService.getAll()
		.pipe(
			catchError(error => {
				this.spinner.hide();
				console.log('Error fetching alarms', error);
				this.toastService.showDanger({ content: 'Failed to fetch data: ' + (error.error ? error.error.error : error.message) });
				this.spinner.hide();
				throw error;
			})
		)
		.subscribe(items => {
			this.alarms = items;
			this.spinner.hide();
		});
	}

	getStatusClass(kiosk: KioskDto): string {
		switch (kiosk.kioskStatus) {
			case 'Verified':
				return 'alert alert-success';
			case 'New':
				return 'alert alert-info';
			case 'Blocked':
				return 'alert alert-warning';
			case 'Disabled':
				return 'alert alert-danger';
		}
	}

	openMaintenance(alarm: KioskMonitorStatusDto) {
		this.router.navigate(['/kiosk','maintenance', alarm.kiosk.id]);
	}
}