import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input, OnInit} from '@angular/core';
import { KioskDto, KioskMaintenanceLogDto, PeripheralEnum } from '../../../../dto';
import { AppToastService } from 'src/app/components/toasts/app-toast.service';
import { catchError } from 'rxjs/operators';
import { NgxSpinnerService } from "ngx-spinner";
import { HttpAlarmService } from 'src/app/modules/kiosk/http-alarm.service';
import { AuthService } from 'src/app/service/auth.service';

@Component({
	selector: 'app-maintenance-add-log-modal',
	templateUrl: './maintenance-add-log-modal.component.html',
	styleUrls: ['./maintenance-add-log-modal.component.scss']
})
export class MaintenanceAddLogModalComponent implements OnInit {
	@Input() peripheral: PeripheralEnum;
	@Input() kiosk: KioskDto;
	log: KioskMaintenanceLogDto;

	constructor(
		public modal: NgbActiveModal, 
		private authService: AuthService,
		private toastService: AppToastService, 
		private httpService: HttpAlarmService,
		private spinner: NgxSpinnerService) { }

	ngOnInit(): void {
		this.log = new KioskMaintenanceLogDto();
		this.log.kiosk = this.kiosk;
		this.log.createdAt = new Date();
		this.log.username = this.authService.getUsername();
		this.log.peripheral = this.peripheral;
	}

	save() {
		if (!this.log.log) {
			this.toastService.showSecondary({ content: 'Please provide the log details' });
			return;
		}

		this.spinner.show();
		this.httpService.addLog(this.log)
		.pipe(
			catchError(error => {
				this.spinner.hide();
				console.log('Error adding maintenance log', error);
				this.toastService.showDanger({ content: 'Failed to add maintenance log: ' + (error.error ? error.error.error : error.message) });
				throw error;
			})
		)
		.subscribe(data => {
			this.spinner.hide();
			if (!data.responseStatus) {
				this.toastService.showDanger({ content: 'Failed to add maintenance log: ' + data.responseStatusMessage });
				return;
			}

			this.toastService.showSuccess({ content: 'Maintenance log saved successfully'});
			this.modal.close(true);
		});
	}

	getUsername() {
		return this.log.username.toLowerCase();
	}

	getFriendlyDate() {
		return this.log.createdAt.toLocaleDateString() + ' ' + this.log.createdAt.toLocaleTimeString();
	}

	getPeripheral() {
		if (this.log.peripheral === undefined) {
			return undefined;
		}
		return PeripheralEnum[this.log.peripheral];

	}

	cancel() {
		this.modal.close(false);
	}
}