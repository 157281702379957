import { Component, Input } from "@angular/core";
import { CustomerDto } from "../../../dto";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
	selector: 'app-customer-momo-account-modal',
	templateUrl: './customer-momo-account-modal.component.html',
	styleUrls: ['./customer-momo-account-modal.component.scss']
})
export class CustomerMomoAccountModalComponent {
	@Input() customer: CustomerDto;

	constructor(
		public modal: NgbActiveModal,
	) {}

	close() {
		this.modal.close(false);
	}
}