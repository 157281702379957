<div class="row d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
	<div class="col text-end">
		<button class="btn btn-outline-secondary" (click)="goBack()"><fa-icon [icon]="back" size="sm" ></fa-icon></button>
	</div>
</div>
<div class="row" *ngIf="video">
	<div class="col">
		<div class="card m-3 shadow-lg bg-white rounded">
			<div class="card-header p-3">Video Details
				<button class="btn btn-outline-secondary float-end" 
					(click)="watchVideo(video); $event.stopPropagation()" 
					type="button">
					Watch <fa-icon [icon]="videoIcon" size="lg"></fa-icon>
				</button>
			</div>
			<div class="card-body table-responsive">
				<div class="row">
					<div class="col  float-start">
						<table class="table table-bordered">
							<tbody>
								<tr>
									<td class="bg-dark text-light">Title</td><td>{{video.title}}</td>
								</tr>
								<tr>
									<td class="bg-dark text-light">Desc</td><td>{{video.description}}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="row">
					<div class="col table-responsive">
						<table class="table table-bordered">
							<thead>
								<tr>
									<th class="bg-dark text-light">Age</th>
									<th class="bg-dark text-light">Gender</th>
									<th class="bg-dark text-light">Run</th>
									<th class="bg-dark text-light">Stop</th>
									<th class="bg-dark text-light">Size</th>
									<th class="bg-dark text-light">Format</th>
									<th class="bg-dark text-light">Duration</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>{{video.targetAge}}</td>
									<td>{{video.targetGender}}</td>
									<td>{{video.enabledAt | date:'yyyy-MM-dd'}}</td>
									<td>{{video.disabledAt | date:'yyyy-MM-dd'}}</td>
									<td>{{getSize(video.videoSize)}}</td>
									<td>{{video.videoFormat}}</td>
									<td>{{getDuration(video.videoDuration)}}</td>
								</tr>								
							</tbody>
						</table>
					</div>
				</div>
				<div class="row">
					<div class="text-center">
						<img [src]="video.thumbnail" class="thumbnail" (click)="watchVideo(video); $event.stopPropagation()"  />
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="col">
		<div class="card m-3 shadow-lg bg-white rounded">
			<div class="card-header p-3">
				Link Video to Kiosks
				<div class="btn-group float-end" role="group">
					<button class="btn btn-outline-secondary" 
						(click)="selectAll(); $event.stopPropagation()" 
						type="button">
						All <fa-icon [icon]="all" size="lg"></fa-icon>
					</button>
					<button class="btn btn-outline-secondary" 
						(click)="selectNone(); $event.stopPropagation()" 
						type="button">
						None <fa-icon [icon]="none" size="lg"></fa-icon>
					</button>
					<button class="btn btn-outline-secondary" 
						(click)="reset(); $event.stopPropagation()" 
						type="button">
						Reset <fa-icon [icon]="resetKiosk" size="lg"></fa-icon>
					</button>
				</div>
			</div>
			<div class="card-body table-responsive">
				<div class="row">
					<div class="col-lg-6 mb-3 scroll-col" >
						<div class="form-check" *ngFor="let kiosk of kiosks">
							<input class="form-check-input" [id]="kiosk.id" type="checkbox" [(ngModel)]="kiosk.linkedToVideo">
							<label class="form-check-label" [for]="kiosk.id">
								{{util.getKioskDetails(kiosk)}}
							</label>
						</div>								
					</div>
				</div>
			</div>
			<div class="card-footer">
				<button type="button" class="btn btn-success" (click)="saveLinks()">Save</button>
			</div>
		</div>
	</div>
</div>
