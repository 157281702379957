import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AppToastService } from "src/app/components/toasts/app-toast.service";
import { HttpKioskService } from "../../../../http-kiosk.service";
import { KioskDto } from "../../../../dto";
import { ActivatedRoute } from "@angular/router";
import { catchError } from "rxjs/operators";
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { UtilService } from "../../../../util.service";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
	selector: 'app-kiosk-maintenance',
	templateUrl: './kiosk-maintenance.component.html',
	styleUrls: ['./kiosk-maintenance.component.scss']
})
export class KioskMaintenanceComponent {
	kiosk: KioskDto;
	@Output() reloadKiosk: EventEmitter<any> = new EventEmitter(); 
	back = faArrowLeft;

	util = UtilService;

	constructor() {
	}

	@Input() 
	set kioskData(kiosk: KioskDto) {
		if (!kiosk) {
			return;
		}

		this.kiosk = kiosk;
	}
}