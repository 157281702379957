import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/service/auth.service';
import { HttpConfigService } from 'src/app/service/http-config.service';
import { ActivatedRoute, ActivationStart, Router } from '@angular/router';
import packageInfo from '../../../../package.json';

@Component({
	selector: 'app-main-page',
	templateUrl: './main-page.component.html',
	styleUrls: ['./main-page.component.scss']
})
export class MainPageComponent {
	public version = packageInfo.version;

	constructor(
		public authService: AuthService, 
		private configService: HttpConfigService, 
		public router: Router,
		public route: ActivatedRoute) {
		}

	public getApiVersion(): string {
		return this.configService.getVersion();
	}

}
