import { Component, OnDestroy, OnInit } from "@angular/core";
import { KioskRoleDto, KioskUserDto } from "../../dto";
import { HttpAdminService } from "../../http-admin.service";
import { faArrowLeft,faArrowRight,faPeopleArrows } from '@fortawesome/free-solid-svg-icons';
import { catchError } from "rxjs/operators";
import { AppToastService } from "src/app/components/toasts/app-toast.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ConfirmModalComponent } from "../../components/confirm-modal/confirm-modal.component";
import { NgxSpinnerService } from "ngx-spinner";
import { UtilService } from "../../util.service";
import { Location } from "@angular/common";

@Component({
	selector: 'app-admin-add',
	templateUrl: './admin-add.component.html',
	styleUrls: ['./admin-add.component.scss']
})
export class AdminAddComponent implements OnInit, OnDestroy {

	admin: KioskUserDto = {};
	availableRoles: KioskRoleDto[];

	back=faArrowLeft;
	add = faArrowRight;
	remove = faArrowLeft;
	newAdmin=faPeopleArrows;

	util = UtilService;

	constructor(
		private locationService: Location, 
		private httpService: HttpAdminService, 
		private toastService: AppToastService,
		private modalService: NgbModal,
		private spinner: NgxSpinnerService,) {
	}

	ngOnInit(): void {
		this.setRoles();
	}

	goBack(): void {
		this.locationService.back()
	}

	private setRoles() {
		this.availableRoles = [];
		this.admin.roles = [];

		this.httpService.getAllRoles().subscribe(item => {
			this.availableRoles = item;
		});		
	}

	addRole(role: KioskRoleDto) {
		this.admin.roles.push(role);

		this.availableRoles.forEach((value,index)=>{
			if(value==role) this.availableRoles.splice(index,1);
		});
	}

	removeRole(role: KioskRoleDto) {
		this.admin.roles.forEach((value,index)=>{
			if(value==role) this.admin.roles.splice(index,1);
		});

		this.availableRoles.push(role);
	}

	generateUsername() {
		if (this.admin.username) {
			return;
		}
		this.admin.username = this.util.generateUsername(this.admin);
	}

	addAdmin() {

		if (!this.admin.name) {
			this.toastService.showSecondary({ content: 'Please enter the name of the admin' });
			return;
		}

		if (!this.admin.surname) {
			this.toastService.showSecondary({ content: 'Please enter the surname of the admin' });
			return;
		}

		if (!this.admin.username) {
			this.toastService.showSecondary({ content: 'Please enter the username of the admin' });
			return;
		}

		if (!this.admin.msisdn) {
			this.toastService.showSecondary({ content: 'Please enter the MSISDN of the admin' });
			return;
		}

		if (!this.admin.email) {
			this.toastService.showSecondary({ content: 'Please enter the email of the admin' });
			return;
		}

		if (this.admin.roles.length == 0) {
			this.toastService.showSecondary({ content: 'Please select at least one role for the admin' });
			return;
		}

		const modalRef = this.modalService.open(ConfirmModalComponent, { windowClass: "modal-confirm" });
		modalRef.componentInstance.title = 'Add Admin';
		modalRef.componentInstance.text = "Please confirm you wish to add the admin.";
		modalRef.componentInstance.additionalText = "The admin will receive a notification with instructions to complete the setup of their account.";

		modalRef.result.then((result) => {
			if (result === true) {
				this.doAddAdmin();
			}
		});
	}
	private doAddAdmin() {
		this.spinner.show();
		this.httpService.addAdmin(this.admin)
		.pipe(
			catchError(error => {
				this.spinner.hide();
				console.log('Error adding admin', error);
				this.toastService.showDanger({ content: 'Failed to add admin: ' + (error.error ? error.error.error : error.message) });
				throw error;
			})
		)
		.subscribe(data => {
			console.log('data', data);
			this.spinner.hide();
			if (!data.responseStatus) {
				this.toastService.showDanger({ content: 'Failed to add admin: ' + data.responseStatusMessage });
				return;
			}

			this.toastService.showSuccess({ content: 'Admin saved successfully'});

			this.admin = {};
			this.setRoles();
		});
	}

	ngOnDestroy(): void {
		
	}
}