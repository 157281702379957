import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from "@angular/core";
import { ByCountryCodeOptions, CountyCode, GeocoderAutocomplete } from "@geoapify/geocoder-autocomplete";
import { Map, Control, DomUtil, ZoomAnimEvent, Layer, MapOptions, tileLayer, latLng, LatLng, Marker, Icon } from 'leaflet';
import { HttpConfigService } from "src/app/service/http-config.service";
import { faEdit } from '@fortawesome/free-solid-svg-icons';

export class Place {
	name?: string;
	city?: string;
	province?: string;
	country?: string;
	latitude?: number;
	longitude?: number;
	address?: string;
}

@Component({
	selector: 'app-map',
	templateUrl: './map.component.html',
	styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, AfterViewInit, OnDestroy {
	@Output() onAddressSelected: EventEmitter<Place> = new EventEmitter;
	@Output() map$: EventEmitter<Map> = new EventEmitter;
	@Output() zoom$: EventEmitter<number> = new EventEmitter;

	// @Input() address: string;
	// @Input() latitude: number;
	// @Input() longitude: number;
	@Input() maxHeight: number = 400;
	@Input() editable: boolean = true;

	@ViewChild('geocodercomponent') geocoder: TemplateRef<GeocoderAutocomplete>;
	
	center = latLng(0,0);
	place: Place;
	
	public map: Map;
	public zoom: number;

	marker: Marker;

	countryCode: ByCountryCodeOptions = [];

	icon = new Icon({
		iconSize: [ 25, 41 ],
		iconAnchor: [ 13, 0 ],
		// specify the path here
		iconUrl: 'assets/images/marker-icon.png',
		shadowUrl: 'assets/images/marker-shadow.png'
	});

	edit = faEdit;

	constructor(private configService: HttpConfigService) {
	}

	@Input()
	set location(place: Place) {
		if (!place) {
			this.removeMarker();
			return;
		}
		this.place = place;
		this.center = latLng(this.place.latitude, this.place.longitude);
		if (this.map) {
			this.addMarker();
		}
	}

	ngOnInit() {
		if (!this.place) {
			this.place = {
				latitude: this.configService.getDefaultLocationMapLatitude(),
				longitude: this.configService.getDefaultLocationMapLongitude(),
			}
		}

		this.countryCode.push("ci", "za", "mu");
	}

	ngAfterViewInit(): void {	
		const formEl = document.getElementById("axongeocoder") as HTMLFormElement;
		if (this.place && this.place.address && formEl) {	
			const inputEl = formEl.querySelector("input") as HTMLInputElement;
			inputEl.value = this.place.address;
		}
	}

	ngOnDestroy() {
		this.map.clearAllEventListeners();
	};

	onMapReady(map: Map) {
		this.map = map;
		this.map$.emit(map);
		this.zoom = map.getZoom();
		this.zoom$.emit(this.zoom);

		this.center = latLng(this.place.latitude, this.place.longitude);
		this.map.panTo(this.center);

		if (this.place.latitude && this.place.longitude) {
			this.addMarker();
		}
	}

	private addMarker() {
		this.removeMarker();
		this.marker = new Marker(this.center);
		this.marker.setIcon(this.icon);
		this.marker.addTo(this.map);
		this.map.panTo(this.center);
	}

	private removeMarker() {
		if (this.marker) {
			this.map.removeLayer(this.marker);
		}
	}

	onMapZoomEnd(e: ZoomAnimEvent) {
		this.zoom = e.target.getZoom();
		this.zoom$.emit(this.zoom);
	}

	get options(): MapOptions {
		return {
			layers: [tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
				opacity: 0.7,
				maxZoom: 19,
				detectRetina: true,
				attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
			})],
			zoom: 15,
			center: this.center
		};
	}

	placeSelected($event) {
		if ($event === null) {
			this.place = null;
			this.onAddressSelected.emit(this.place);
			this.removeMarker();
			return;
		}

		var address = '';
		if ($event.properties.address_line1) {
			address = $event.properties.address_line1;
			if ($event.properties.address_line2) {
				address += ' ' + $event.properties.address_line2;
			}
		}

		this.place = {
			name: $event.properties.name,
			city: $event.properties.city,
			province: $event.properties.state,
			country: $event.properties.country,
			address: address,
			latitude: $event.properties.lat,
			longitude: $event.properties.lon
		};
		this.onAddressSelected.emit(this.place);
		this.center = latLng(this.place.latitude, this.place.longitude);
		this.addMarker();		
	}

	suggestionsChanged($event) {
		// console.log('suggestionsChanged: ', $event);
	}

	get maxMapHeight(): string {
		return this.maxHeight + "px";
	}
}