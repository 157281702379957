<div class="modal-header">
	<h4 class="modal-title" id="modal-title">Customer ID Details</h4>
	<button type="button" class="btn-close" aria-describedby="modal-title" (click)="close()">
	</button>
</div>
<div class="modal-body table-responsive">
	<table class="table table-borderless">
		<tbody>
			<tr>
				<th>Number</th>
				<td>{{customer.idNumber}}</td>
			</tr>
			<tr>
				<th>Document Number</th>
				<td>{{customer.documentNumber}}</td>
			</tr>
			<tr>
				<th>Type</th>
				<td>{{customer.idType}}</td>
			</tr>
			<tr>
				<th>Issued</th>
				<td>{{customer.idIssuedAt | date: 'yyyy-MM-dd'}}</td>
			</tr>
			<tr>
				<th>Expiry</th>
				<td>{{customer.idExpiryAt | date: 'yyyy-MM-dd'}}</td>
			</tr>
			<tr>
				<th>Issue Place</th>
				<td>{{customer.idIssuePlace}}</td>
			</tr>
		</tbody>
	</table>
</div>
<div class="modal-footer">
	<button type="button" class="btn btn-outline-secondary" (click)="close()">Close</button>
</div>