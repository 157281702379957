<nav>
	<div class="nav nav-tabs mt-1" id="nav-tab" role="tablist">
		<button
			class="nav-link active" id="nav-info-tab" data-bs-toggle="tab" data-bs-target="#nav-info" type="button"
			role="tab" aria-controls="nav-info" name="tab-nav" (click)="activateTab($event)">Info</button>
		<button 
			class="nav-link" id="nav-components-tab" data-bs-toggle="tab" data-bs-target="#nav-components" type="button"
			role="tab" aria-controls="nav-components" name="tab-nav" (click)="activateTab($event)">Components</button>
		<button *ngIf="showTab('kiosk-transactions')"
			class="nav-link" id="nav-transactions-tab" data-bs-toggle="tab" data-bs-target="#nav-transactions" type="button"
			role="tab" aria-controls="nav-transactions" name="tab-nav" (click)="activateTab($event)">Transactions</button>
		<button 
			class="nav-link" id="nav-maintenance-tab" data-bs-toggle="tab" data-bs-target="#nav-maintenance" type="button"
			role="tab" aria-controls="nav-maintenance" name="tab-nav" (click)="activateTab($event)">Alarms / Maintenance</button>
		<div class="col text-end">
			<button class="btn btn-outline-secondary" (click)="goBack()"><fa-icon [icon]="back" size="sm" ></fa-icon></button>
		</div>
	</div>
</nav>
<div class="tab-content content-background" id="nav-tabContent">
	<div class="tab-pane fade show active" id="nav-info" role="tabpanel" aria-labelledby="nav-info-tab" name="tab-content">
		<ng-container *ngIf="isTabActive('nav-info')" [ngTemplateOutlet]="kioskInfo"></ng-container>
	</div>
	<div class="tab-pane fade" id="nav-components" role="tabpanel" aria-labelledby="nav-components-tab" name="tab-content">
		<ng-container *ngIf="isTabActive('nav-components')" [ngTemplateOutlet]="kioskComponents"></ng-container>
	</div>
	<div class="tab-pane fade" id="nav-transactions" role="tabpanel" aria-labelledby="nav-transactions-tab" name="tab-content">
		<ng-container *ngIf="isTabActive('nav-transactions')" [ngTemplateOutlet]="kioskTransactions"></ng-container>
	</div>
	<div class="tab-pane fade" id="nav-maintenance" role="tabpanel" aria-labelledby="nav-maintenance-tab" name="tab-content">
		<ng-container *ngIf="isTabActive('nav-maintenance')" [ngTemplateOutlet]="kioskMaintenance"></ng-container>
	</div>
</div>
<ng-template #kioskInfo><app-kiosk-info [kioskData]="kiosk" (reloadKiosk)="fetchKiosk()"></app-kiosk-info></ng-template>
<ng-template #kioskComponents><app-kiosk-components [kioskData]="kiosk" (reloadKiosk)="fetchKiosk()"></app-kiosk-components></ng-template>
<ng-template #kioskTransactions><app-kiosk-transactions [kioskData]="kiosk" (reloadKiosk)="fetchKiosk()"></app-kiosk-transactions></ng-template>
<ng-template #kioskMaintenance><app-kiosk-maintenance [kioskData]="kiosk" (reloadKiosk)="fetchKiosk()"></app-kiosk-maintenance></ng-template>