export abstract class AbstractDto {
	responseStatus?: boolean;
	responseStatusMessage?: string;
	hashCode?: number;
}

export class OrganizationDto extends AbstractDto {
	id: number;
	name: string;
	parentId: number;
	axonInternalId: number;
}
export class AdminAuthResponseDto {
	token: string;
	error: string;
}

export class ConfigDto extends AbstractDto {
	version: string;
	features: FeatureDto[];
	passwordSetupMode: 'Otp' | 'Password' = 'Otp';
	defaultLocationMapLatitude: number;
	defaultLocationMapLongitude: number;
	videoTargetAge: string[];
	videoTargetGender: string[];
	videoTargetRace: string[];
}
export class FeatureDto extends AbstractDto {
	name: string;
}
