<!-- <div id="autocomplete" class="autocomplete-container"> -->
<geoapify-geocoder-autocomplete *ngIf="editable"
		id="axongeocoder"
		(placeSelect)="placeSelected($event)" 
		(suggestionsChange)="suggestionsChanged($event)"
		[filterByCountryCode]="countryCode"></geoapify-geocoder-autocomplete>
<div class="row" *ngIf="!editable">
	<div class="col">
		<span>{{place.name}} - {{place.address}}</span>
	</div>
</div>
<div class="row">
	<div class="col">
		<div class="card">
			<div class="card-body">
				<div class="map-container" leaflet [ngStyle]="{'max-height': maxMapHeight}"
					[leafletOptions]="options"
					[leafletCenter]="center"
					(leafletMapReady)="onMapReady($event)"
					(leafletMapZoomEnd)="onMapZoomEnd($event)"></div>
			</div>
		</div>
	</div>
</div>

