import { HttpParams, HttpEvent } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractHttpService } from '../../service/http-abstract-service';
import { UserChangePasswordDto, AdminKioskResponseDto, KioskRoleDto, KioskUserDto, KioskCardDispenserStatusDto, KioskDto, KioskPrinterStatusDto, KioskSettingDto, KioskMonitorSettingDto, KioskMonitorGroupedDto } from './dto';


@Injectable({
	providedIn: 'root'
})
export class HttpSettingsService extends AbstractHttpService {

	constructor(http: HttpClient) {
		super(http, '/settings');
	}

	getAll(): Observable<KioskSettingDto[]> {
		return super.get(`/get`);
	}

	getAllMonitorSettings(): Observable<KioskMonitorSettingDto[]> {
		return super.get(`/get-monitor-settings`);
	}

	getAllMonitors(): Observable<KioskMonitorGroupedDto[]> {
		return super.get(`/get-monitors`);
	}

	save(settings: KioskSettingDto[]): Observable<AdminKioskResponseDto> {
		return super.post(`/save`, settings);
	}

	saveMonitor(settings: KioskMonitorSettingDto[]): Observable<AdminKioskResponseDto> {
		return super.post(`/save-monitor`, settings);
	}

}
