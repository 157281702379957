<div *ngIf="edit" class="">
	<div class="card m-3 shadow-lg bg-white rounded">
		<div class="card-header">
			<div class="row">
				<div class="col pt-1"><fa-icon [icon]="settingsIcon" class="text-secondary"></fa-icon>&nbsp;General Settings</div>
			</div>
		</div>
		<div class="card-body table-responsive">
			<table class="table table-hover">
				<thead>
					<tr>
						<th>Name</th>
						<th>Current Value</th>
						<th>Change Value</th>
						<th>Description</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let setting of edit">
						<td>{{setting.name}}</td>
						<td>{{getSettingValue(setting).value}}</td>
						<td>
							<div class="mb-3 mx-auto input-group">
								<input [type]="setting.inputType" class="form-control input-table" id="setting" aria-describedby="setting" name="setting" [(ngModel)]="setting.value" [maxlength]="50">
							</div>
						</td>
						<td>{{setting.description}}</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="card-footer">
			<button type="button" class="btn btn-success" (click)="save()" [disabled]="disableSave">Save</button>
		</div>
	</div>
</div>

<div *ngIf="monitorSettingsEdit" class="">
	<div class="card m-3 shadow-lg bg-white rounded">
		<div class="card-header">
			<div class="row">
				<div class="col pt-1"><fa-icon [icon]="settingsIcon" class="text-secondary"></fa-icon>&nbsp;Monitor Settings</div>
			</div>
		</div>
		<div class="card-body table-responsive">
			<table class="table table-hover">
				<thead>
					<tr>
						<th>Monitor</th>
						<th>Severe Threshold <fa-icon [icon]="tooltip" size="sm" title="{{monitorSettingsEdit[0].severeThresholdDesc}}"></fa-icon></th>
						<th>Problem Threshold <fa-icon [icon]="tooltip" size="sm" title="{{monitorSettingsEdit[0].problemThresholdDesc}}"></fa-icon></th>
						<th>Severe Restore <fa-icon [icon]="tooltip" size="sm" title="{{monitorSettingsEdit[0].severeRestoreDesc}}"></fa-icon></th>
						<th>Problem Restore <fa-icon [icon]="tooltip" size="sm" title="{{monitorSettingsEdit[0].problemRestoreDesc}}"></fa-icon></th>
						<th>Escalation <fa-icon [icon]="tooltip" size="sm" title="{{monitorSettingsEdit[0].escalationDesc}}"></fa-icon></th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let setting of monitorSettingsEdit">
						<td>
							{{setting.monitor}}
							<div *ngIf="setting.error" class="text-danger"><small>{{setting.error}}</small></div>
						</td>
						<td>
							<div class="mb-3 mx-auto input-group">
								<input type="number" class="form-control" 
									id="severeThreshold" aria-describedby="severeThreshold" name="severeThreshold" 
									[(ngModel)]="setting.severeThreshold"  min="0" max="10">
							</div>
						</td>
						<td>
							<div class="mb-3 mx-auto input-group">
								<input type="number" class="form-control" 
									id="problemThreshold" aria-describedby="problemThreshold" name="problemThreshold" 
									[(ngModel)]="setting.problemThreshold"  min="0" max="10">
							</div>
						</td>
						<td>
							<div class="mb-3 mx-auto input-group">
								<input type="number" class="form-control" 
									id="severeRestore" aria-describedby="severeRestore" name="severeRestore" 
									[(ngModel)]="setting.severeRestore"  min="0" max="10">
							</div>
						</td>
						<td>
							<div class="mb-3 mx-auto input-group">
								<input type="number" class="form-control" 
									id="problemRestore" aria-describedby="problemRestore" name="problemRestore" 
									[(ngModel)]="setting.problemRestore"  min="0" max="10">
							</div>
						</td>
						<td>
							<div class="mb-3 mx-auto input-group">
								<input type="number" class="form-control" 
									id="escalation" aria-describedby="escalation" name="escalation" 
									[(ngModel)]="setting.escalation"  min="0" max="1000">
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="card-footer">
			<button type="button" class="btn btn-success" (click)="saveMonitor()" [disabled]="disableSaveMonitor">Save</button>
		</div>
	</div>
</div>
