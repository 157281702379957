import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AppToastService } from "src/app/components/toasts/app-toast.service";
import { CardBoxStatusEnum, CardErrorTypeEnum, KioskCardDispenserStatusDto, KioskDto, KioskPrinterStatusDto } from "../../dto";
import { HttpKioskService } from "../../http-kiosk.service";
import { faCheck, faCancel, faTimes, faSearch, faQuestion, faPrint, faSimCard, faCircle } from '@fortawesome/free-solid-svg-icons';
import { catchError } from "rxjs/operators";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { UtilService } from "../../util.service";
import { NgxSpinnerService } from "ngx-spinner";
import { forkJoin } from "rxjs";


@Component({
	selector: 'app-kiosk-problems',
	templateUrl: './kiosk-problems.component.html',
	styleUrls: ['./kiosk-problems.component.scss']
})
export class KioskProblemsComponent implements OnInit {

	kioskCardDispenserStatus: KioskCardDispenserStatusDto[];
	printerStatus: KioskPrinterStatusDto[];

	sensorOk = faCircle;
	sensorNotOk = faCircle;
	statusOk = faCheck;
	statusNotOk = faTimes;
	statusUnknown = faQuestion;
	view = faSearch;
	printer = faPrint;
	dispenser = faSimCard;
	util = UtilService;

	constructor(
		private httpService: HttpKioskService, 
		private toastService: AppToastService, 
		private modalService: NgbModal,
		private router: Router,
		private spinner: NgxSpinnerService) {
	}

	ngOnInit(): void {
		this.fetchAllKiosks();
	}

	private fetchAllKiosks() {
		this.spinner.show();
		forkJoin([this.httpService.getProblematicKiosksCardDispenser(), this.httpService.getProblematicKiosksPrinter()])
		.pipe(
			catchError(error => {
				this.spinner.hide();
				console.log('Error fetching kiosk card dispenser and printer status', error);
				this.toastService.showDanger({ content: 'Failed to fetch data: ' + (error.error ? error.error.error : error.message) });
				throw error;
			})
		)
		.subscribe(data => {
			this.kioskCardDispenserStatus = data[0];
			this.printerStatus = data[1];
			this.spinner.hide();
		});

	}


	viewKiosk(kiosk: KioskDto) {
		this.router.navigate(['/kiosk','view', kiosk.id]);
	}
	
}