import { Component, Input, OnInit } from "@angular/core";
import { CustomerFingerprintDto, ImageDataDto } from "../../../dto";
import { HttpCustomerService } from "../../../http-customer.service";
import { catchError } from "rxjs/operators";

@Component({
	selector: 'app-customer-print',
	templateUrl: './customer-print.component.html',
	styleUrls: ['./customer-print.component.scss']
})
export class CustomerPrintComponent implements OnInit {
	@Input() print: CustomerFingerprintDto;

	constructor(private httpService: HttpCustomerService) {}

	ngOnInit(): void {
		if (this.print.jpeg) {
			return;
		}
		this.httpService.getCustomerFingerprint(this.print.id)
			.pipe(
				catchError(error => {
					console.log('Error fetching fingerprint', error);
					throw error;
				})
			)
			.subscribe(data => {
				var reader = new FileReader();
				reader.addEventListener("load", () => {
					this.print.jpeg = reader.result;
				}, false);
			
				if (data) {
					reader.readAsDataURL(data);
				}
			});
	}

	viewOriginal() {
		const tab = window.open("", "_blank");
		tab.document.write("<iframe width='100%' height='100%' src='" + this.print.jpeg + "'></iframe>");
	}

}