<div class="row">
	<div class="col col-md-10 col-lg-6 mx-auto">
		<div class="card m-3 shadow-lg bg-white rounded">
			<div class="card-header">
				<div class="row">
					<div class="col pt-1"><fa-icon [icon]="newAdmin" class="text-secondary"></fa-icon>&nbsp;New Administrator</div>
					<div class="col text-end">
						<button class="float-end btn btn-outline-secondary" (click)="goBack()"><fa-icon [icon]="back" size="sm" ></fa-icon></button>
					</div>
				</div>
			</div>
			<div class="card-body">
				<form>
					<div class="row">
						<div class="col-lg-6 mb-3 mx-auto input-group">
							<span class="input-group-text">Name</span>
							<input type="text" class="form-control" id="name" aria-describedby="name" name="name" [(ngModel)]="admin.name" [maxlength]="50">
						</div>
					</div>
					<div class="row">
						<div class="col-lg-6 mb-3 mx-auto input-group">
							<span class="input-group-text">Surname</span>
							<input type="text" class="form-control" id="surname" aria-describedby="surname" name="surname" [(ngModel)]="admin.surname" [maxlength]="50">
						</div>
					</div>
					<div class="row">
						<div class="col-lg-6 mb-3 mx-auto input-group">
							<span class="input-group-text">Username</span>
							<input type="text" class="form-control" id="username" aria-describedby="username" name="username" [(ngModel)]="admin.username" [maxlength]="50" (focus)="generateUsername()">
						</div>
					</div>
					<div class="row">
						<div class="col-lg-6 mb-3 mx-auto input-group">
							<span class="input-group-text">MSISDN</span>
							<input type="msisdn" class="form-control" id="msisdn" aria-describedby="msisdn" name="msisdn" [(ngModel)]="admin.msisdn" [maxlength]="50">
						</div>
					</div>
					<div class="row">
						<div class="col-lg-6 mb-3 mx-auto input-group">
							<span class="input-group-text">Email</span>
							<input type="email" class="form-control" id="email" aria-describedby="email" name="email" [(ngModel)]="admin.email" [maxlength]="50">
						</div>
					</div>
					<div class="row">
						<div class="col-lg-6 mb-3 mx-auto">
							<div class="card">
								<div class="card-body">
									<div class="row">
										<div class="col role-col-left">
											<label for="roles" class="form-label">Available Roles</label>
											<ul id="roles" class="list-group">
												<li *ngFor="let role of availableRoles" class="list-group-item">{{role.roleName}} <fa-icon [icon]="add" class="text-primary float-end clickable" title="Add" (click)="addRole(role)"></fa-icon></li>
											</ul>
											<!-- <input type="text" class="form-control" id="surname" aria-describedby="surname" name="surname" [(ngModel)]="edit.surname" [maxlength]="50"> -->
										</div>
										<div class="col">
											<label for="roles" class="form-label">Roles</label>
											<ul id="roles" class="list-group">
												<li *ngFor="let role of admin.roles" class="list-group-item"><fa-icon [icon]="remove" class="text-primary float-start clickable" title="Remove" (click)="removeRole(role)"></fa-icon>&nbsp;{{role.roleName}}</li>
											</ul>
											<!-- <input type="text" class="form-control" id="surname" aria-describedby="surname" name="surname" [(ngModel)]="edit.surname" [maxlength]="50"> -->
										</div>
									</div>
								</div>
								
							</div>
						</div>			
					</div>
				</form>
			</div>
			<div class="card-footer">
				<button type="button" class="btn btn-success" (click)="addAdmin()">Save</button>
			</div>
		</div>
	</div>	
</div>