import { ActivatedRoute, Params, Router, convertToParamMap } from "@angular/router";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";

export class UrlUtil {

	static getParam(activatedRoute: ActivatedRoute, key: string): string {
		let value = activatedRoute.snapshot.queryParamMap.get(key);
		if (value === null) {
			return undefined;
		}
		return value;
	}
	static getParamAsNumber(activatedRoute: ActivatedRoute, key: string): number {
		let value = UrlUtil.getParam(activatedRoute, key);
		if (value === undefined) {
			return undefined;
		}
		return +value;
	}
	static getParamAsDate(activatedRoute: ActivatedRoute, key: string): Date {
		let value = UrlUtil.getParam(activatedRoute, key);
		if (value === undefined) {
			return undefined;
		}
		let d = new Date();
		d.setFullYear(+value.split('-')[0]);
		d.setMonth(+value.split('-')[1] - 1);
		d.setDate(+value.split('-')[2]);
		return d;
	}
	static getParamAsNgbDate(activatedRoute: ActivatedRoute, key: string): NgbDateStruct {
		let value = UrlUtil.getParamAsDate(activatedRoute, key);
		if (value === undefined) {
			return undefined;
		}
		return {
			year: value.getFullYear(),
			month: value.getMonth() + 1,
			day: value.getDate()
		}
	}

	static clearParams(router: Router) {
		router.navigateByUrl(router.createUrlTree([], {queryParams: {}, queryParamsHandling: 'preserve', preserveFragment: true}));
	}

	// {key: string, value: string}[]
	static setParams(router: Router, params: Params) {
		const paramMap = convertToParamMap(params);

		paramMap.keys.forEach(key => {
			let value = paramMap.get(key) as any;
			if (value instanceof Date) {
				params[key] = UrlUtil.convertDate(value);		
			}
		});

		const urlTree = router.createUrlTree([], {queryParams: params, queryParamsHandling: 'merge', preserveFragment: true});
		router.navigateByUrl(urlTree);
	}

	private static convertDate(input: Date) {
		let newValue = input.getFullYear() + '-';
		if ((input.getMonth() + 1) < 10){ 
			newValue += '0';
		}
		newValue += (input.getMonth() + 1) + '-';

		if ((input.getDate()) < 10){ 
			newValue += '0';
		}
		newValue += (input.getDate());
		return newValue;			
	}
}