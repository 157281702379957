import { HttpParams, HttpEvent } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractHttpService } from '../../service/http-abstract-service';
import { AdminKioskResponseDto, CustomerDto, CustomerPayloadDto, KioskCardDispenserStatusDto, KioskDto, KioskPrinterStatusDto } from './dto';


@Injectable({
	providedIn: 'root'
})
export class HttpCustomerService extends AbstractHttpService {

	constructor(http: HttpClient) {
		super(http, '/customer');
	}

	getAll(from: Date, to: Date, iccid: string, kioskId: number): Observable<CustomerPayloadDto[]> {
		var search = {
			from: from, 
			to: to, 
			iccid: iccid, 
			kioskId: kioskId
		}
		return super.post(`/get`, search);
	}
	getCustomer(customerId: number): Observable<CustomerPayloadDto> {
		return super.get(`/${customerId}/get`);
	}
	getCustomerImage(imageId: number): Observable<Blob> {
		return super.getBlob(`/${imageId}/image`);
	}
	getCustomerFingerprint(printId: number): Observable<Blob> {
		return super.getBlob(`/${printId}/fingerprint`);
	}
}
