import { HttpParams, HttpEvent } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractHttpService } from '../../service/http-abstract-service';
import { UserChangePasswordDto, AdminKioskResponseDto, KioskRoleDto, KioskUserDto, KioskCardDispenserStatusDto, KioskDto, KioskPrinterStatusDto } from './dto';


@Injectable({
	providedIn: 'root'
})
export class HttpAdminService extends AbstractHttpService {

	constructor(http: HttpClient) {
		super(http, '/management');
	}

	getAll(): Observable<KioskUserDto[]> {
		return super.get(`/get`);
	}

	getAllRoles(): Observable<KioskRoleDto[]> {
		return super.get(`/get-roles`);
	}

	getAdminByUsername(username: string): Observable<KioskUserDto> {
		return super.get(`/${username}/get`);
	}

	editAdmin(admin: KioskUserDto): Observable<AdminKioskResponseDto> {
		return super.post(`/${admin.id}/edit`, admin);
	}

	editAdminRoles(admin: KioskUserDto): Observable<AdminKioskResponseDto> {
		return super.post(`/${admin.id}/edit-roles`, admin);
	}

	changeAdminStatus(adminId: number, status: 'Unblock' | 'Disable' | 'ReEnable'): Observable<AdminKioskResponseDto> {
		return super.get(`/${adminId}/${status}/action`);
	}

	addAdmin(admin: KioskUserDto): Observable<AdminKioskResponseDto> {
		return super.post(`/add`, admin);
	}
}
