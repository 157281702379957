<ng-container *ngIf="log">
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Add log</h4>
		<button type="button" class="btn-close" aria-describedby="modal-title" (click)="cancel()">
		</button>
	</div>
	<div class="modal-body">
		<div class="row">
			<div class="col-lg-6 mb-3 mx-auto input-group">
				<span class="input-group-text">Date</span>
				<input readonly="readonly" disabled type="text" class="form-control" id="date" aria-describedby="date" name="date" [value]="getFriendlyDate()">
			</div>
		</div>
		<div class="row">
			<div class="col-lg-6 mb-3 mx-auto input-group">
				<span class="input-group-text">Technician</span>
				<input readonly="readonly" disabled type="text" class="form-control" id="technician" aria-describedby="technician" name="technician" [value]="getUsername()">
			</div>
		</div>
		<div class="row" *ngIf="getPeripheral()">
			<div class="col-lg-6 mb-3 mx-auto input-group">
				<span class="input-group-text">Component</span>
				<input readonly="readonly" disabled type="text" class="form-control" id="peripheral" aria-describedby="peripheral" name="peripheral" [value]="getPeripheral()">
			</div>
		</div>
		<div class="row">
			<div class="col-lg-6 mb-3 mx-auto input-group">
				<span class="input-group-text">Log entry</span>
				<textarea type="text" rows="5" class="form-control" id="log" aria-describedby="log" name="log" [(ngModel)]="log.log" [maxlength]="1000"></textarea>
			</div>
		</div>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-outline-secondary" (click)="cancel()">Cancel</button>
		<button type="button" class="btn btn-success" (click)="save()">Save</button>
	</div>
</ng-container>