import { ConfigDto, FeatureDto } from './dto';
import { HttpClient } from '@angular/common/http';
import { Injectable, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subscription, interval, timer } from 'rxjs';
import { mergeMap, map, tap } from 'rxjs/operators';
import { AbstractHttpService } from './http-abstract-service';

@Injectable({
	providedIn: 'root'
})
export class HttpConfigService extends AbstractHttpService {
	private enabledFeatures: FeatureDto[] = null;
	private version: string = "1.0.0.0";
	private passwordSetupMode: 'Otp' | 'Password';
	private defaultLocationMapLatitude: number;
	private defaultLocationMapLongitude: number;
	private videoTargetAge: string[];
	private videoTargetGender: string[];
	private videoTargetRace: string[];

	constructor(http: HttpClient) {
		super(http, '/config');

		timer(0, 10000)
			.pipe(mergeMap(() => this.getConfig()))
			.subscribe(item => {
				this.setConfig(item);
			});
	}

	private getConfig(): Observable<ConfigDto> {
		return super.get('/');
	}
	isFeatureEnabled(feature: string): boolean {
		if(this.enabledFeatures == null){
			return null;
		}
		return this.enabledFeatures.filter(item => item.name === feature).length > 0;
	}
	forceRefresh(): Observable<string[]> {
		return this.getConfig().pipe(
			tap(item => {
				this.setConfig(item);
			}),
			map(item => item.features.map(i => i.name))
		);
	}

	private setConfig(config: ConfigDto) {
		this.enabledFeatures = config.features;
		this.version = config.version;
		this.passwordSetupMode = config.passwordSetupMode;
		this.defaultLocationMapLatitude = config.defaultLocationMapLatitude;
		this.defaultLocationMapLongitude = config.defaultLocationMapLongitude;
		this.videoTargetAge = config.videoTargetAge;
		this.videoTargetGender = config.videoTargetGender;
		this.videoTargetRace = config.videoTargetRace;
	}

	getVersion(): string {
		return this.version;
	}

	isOtpWithPassword(): boolean {
		return this.passwordSetupMode === 'Otp' ? true : false;
	}

	getDefaultLocationMapLatitude(): number {
		return this.defaultLocationMapLatitude;
	}

	getDefaultLocationMapLongitude(): number {
		return this.defaultLocationMapLongitude;
	}

	getVideoTargetAge(): string[] {
		return this.videoTargetAge;
	}
	getVideoTargetGender(): string[] {
		return this.videoTargetGender;
	}
	getVideoTargetRace(): string[] {
		return this.videoTargetRace;
	}
}
